import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  emailValidation,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

export const LoginUserTemplate = (action): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "email",
            label: {
              value: "auth.fields.email",
              needsTranslate: true,
            },
            type: "email",

            methodsYup: () => {
              return emailValidation();
            },
          },
          {
            path: "password",
            inputName: "password",
            label: {
              value: "auth.fields.password",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "#7451c2",
      customClass: "w-100 text-center",
      text: {
        value: "auth.carouselTabs.signUp.submit",
        needsTranslate: true,
      },
      action,
    },
  };
};
