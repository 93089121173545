import { get } from "lodash";
import { translateFromKey } from "../translates/translate.helper";

const getValueFromKeys = (item, keys: string[]): string | number => {
  if (keys.length === 0) return null;

  const valueTranslated = translateFromKey(item, keys[0]);
  if (valueTranslated) {
    return valueTranslated;
  }

  const value = get(item, keys[0]);
  if (value) {
    return value;
  }

  return getValueFromKeys(item, keys.slice(1));
};

export const formatOption = <T, K>(
  item: T | null,
  idKeys: string[],
  labelKeys: string[]
): K | null => {
  if (item) {
    return {
      id: getValueFromKeys(item, idKeys),
      label: getValueFromKeys(item, labelKeys),
      item,
      items: [item],
    } as K;
  }
  return null;
};
