import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { SupplierDto } from "@/store/suppliers/dtos/input/Supplier.dto";
import { CreateSupplierDto } from "@/store/suppliers/dtos/output/CreateSupplier.dto";
import { DeleteSupplierDto } from "@/store/suppliers/dtos/output/DeleteSupplier.dto";
import { UpdateSupplierDto } from "@/store/suppliers/dtos/output/UpdateSupplier.dto";
import {
  CreateSupplier,
  DeleteSupplier,
  UpdateSupplier,
} from "@/store/suppliers/services/Supplier.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useSuppliersServices() {
  const { t } = useI18n();
  const alias = "suppliers";

  const createSupplier = async (
    payload: CreateSupplierDto,
    callBackCreate: (supplier: SupplierDto) => void
  ): Promise<void> => {
    await CreateSupplier(payload)
      .then(async (supplier: SupplierDto) => {
        callBackCreate(supplier);
        useToast().successToast(t("suppliers.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateSupplier = async (
    payload: UpdateSupplierDto,
    callBackUpdate: (supplier: SupplierDto) => void
  ): Promise<void> => {
    await UpdateSupplier(payload)
      .then(async (supplier: SupplierDto) => {
        callBackUpdate(supplier);
        useToast().successToast(t("suppliers.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteSupplier = async (
    payload: DeleteSupplierDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteSupplier(payload)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("suppliers.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };
  return {
    createSupplier,
    updateSupplier,
    deleteSupplier,
  };
}
