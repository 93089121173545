import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { CustomerDto } from "@/store/customers/dtos/input/Customer.dto";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";

export const CustomersHeader = (
  runAction: (
    actionType: "edit" | "delete" | "view",
    customer: CustomerDto,
    index: number
  ) => void,
  customFields: CustomFieldDto[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const { lang } = useLanguageServices();
  return [
    {
      sortable: true,
      value: { value: "customers.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "customers.entity.name", needsTranslate: true },
      key: "user.name",
      mappedKey: "user.name",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "customers.entity.email", needsTranslate: true },
      key: "user.email",
      mappedKey: "user.email",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "customers.entity.customerType", needsTranslate: true },
      key: "customerType",
      mappedKey: "customerType",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "customers.entity.businessType",
        needsTranslate: true,
      },
      key: "businessType",
      mappedKey: "businessType",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "customers.entity.state",
        needsTranslate: true,
      },
      key: "state",
      mappedKey: "state",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "customers.entity.city",
        needsTranslate: true,
      },
      key: "city",
      mappedKey: "city",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: false,
      value: {
        value: "customers.entity.division",
        needsTranslate: true,
      },
      key: "division",
      mainKey: "division",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "100px",
      filterType: "select",
      selectOptions: {
        dataSource: "/divisions/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    {
      sortable: true,
      value: { value: "customers.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "customers.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    ...customFieldsHeaders,
    {
      sortable: false,
      value: { value: "customers.table.actions", needsTranslate: true },
      columnType: "action",
      options: (customer: CustomerDto, index: number): Menu => {
        const options = [
          {
            label: {
              value: "customers.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("edit", customer, index),
          },
          {
            label: {
              value: "customers.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("delete", customer, index),
          },
          {
            label: {
              value: "customers.table.actionList.view",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("view", customer, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
