import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import {
  UpdateCustomFieldsDto,
  UpdateStepDto,
} from "@/store/workplaces-flow/dtos/output/UpdateWorkplaceFlow.dto";
import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { get, merge } from "lodash";
import {
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateStep from "./CreateOrUpdateStep.vue";
import { StepDtoToStepForm } from "./formatters/StepDtoToStepForm.formatter";
import { StepsFormToStepsDto } from "./formatters/StepFormToStepDto.formatter";
import { CreateOrUpdateWorkplaceFlows } from "./templates/forms/CreateOrUpdateWorkplaceFlows.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateWorkplacesFlow',
  props: {
  productId: { type: Number, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("workplace_flows");

const props = __props;

const { workplaceFlow, getWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices(props.productId);

const workplaceFlowForm = ref();
const workplaceFlowFormTemplateRef = ref();
const steps: Ref<Array<WorkplaceStepForm>> = ref([]);

const emit = __emit;

function callBackEdit(product: ProductDto) {
  emit("handleSubmit", {
    product,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted }: { valuesFormatted: UpdateCustomFieldsDto } =
    workplaceFlowForm.value;
  const stepsFormatted: UpdateStepDto[] = StepsFormToStepsDto(steps.value);
  const workplaceFlow = merge({ productId: props.productId }, valuesFormatted, {
    steps: stepsFormatted,
  });
  await updateWorkplacesFlow(workplaceFlow, callBackEdit);
}

onMounted(async () => {
  await Promise.all([getModuleCustomFields(), getWorkplacesFlow()]);
  steps.value = StepDtoToStepForm(get(workplaceFlow, "value.steps"));
  watch(
    [customFields, workplaceFlow],
    async () => {
      workplaceFlowFormTemplateRef.value = CreateOrUpdateWorkplaceFlows(
        customFields.value,
        handleSubmit
      );
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms: () => workplaceFlowForm.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(GeneralForm, {
        ref_key: "workplaceFlowForm",
        ref: workplaceFlowForm,
        form: workplaceFlowFormTemplateRef.value,
        key: `${props.productId}.${JSON.stringify(_unref(workplaceFlow))}`,
        "form-name": "workplacesFlowForm",
        initialValues: _unref(workplaceFlow)
      }, {
        default: _withCtx(() => [
          _createVNode(CreateOrUpdateStep, {
            steps: steps.value,
            modelValue: steps.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((steps).value = $event))
          }, null, 8, ["steps", "modelValue"])
        ]),
        _: 1
      }, 8, ["form", "initialValues"]))
    ])
  ]))
}
}

})