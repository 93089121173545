import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-wrap"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 d-flex flex-start" }
const _hoisted_5 = { style: {"margin-left":"8px"} }
const _hoisted_6 = ["multiple", "accept"]
const _hoisted_7 = {
  key: 0,
  class: "col-12"
}
const _hoisted_8 = { class: "d-flex flex-row justify-content-start flex-wrap" }
const _hoisted_9 = {
  class: /*@__PURE__*/_normalizeClass(`card
              p-2
              m-2
              d-flex
              flex-column
              justify-content-center
              align-items-center
              preview
              `)
}
const _hoisted_10 = ["src", "width", "height"]
const _hoisted_11 = {
  key: 1,
  class: "w-100"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "col-12",
  style: {"min-height":"100px"}
}
const _hoisted_14 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_15 = { class: "fw-bold" }

import { useToast } from "@/composables/useToastServices";
import { FileOptions } from "@/shared/globals/forms/interfaces/FileOptions.interface";
import { compressImage } from "@/shared/globals/helpers/Files.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { get } from "lodash";
import {
  computed,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";

type FileType = {
  url: string;
  file?: File;
  isUrl: boolean;
};

enum FileTypes {
  image = "image/",
  excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilesMaster',
  props: {
  modelValue: { type: Array, required: true },
  fileOptions: {
    type: Object as PropType<FileOptions>,
    required: false,
  },
  initialFiles: {
    type: Array<FileType>,
    required: false,
    default: () => [],
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const initialFilesRef: Ref<FileType[]> = toRef(props, "initialFiles");
const fileInput = ref();
const srcs: Ref<{ [key in string]: string }> = ref({});
const files: Ref<{
  [key in string]: File;
}> = ref({});
const isEntering: Ref<boolean> = ref(false);

const accept = computed(() => {
  if (props.fileOptions.type === "image") {
    return "image/*";
  } else if (props.fileOptions.type === "pdf") {
    return "application/pdf";
  } else if (props.fileOptions.type === "excel") {
    return ".xlsx";
  }
  return "/*";
});

function handleDrop(event: DragEvent) {
  event.preventDefault();
  const files = get(event, "dataTransfer.files", []);
  if (!props.fileOptions.isMultiple && files.length > 1) {
    useToast().errorToast(t("global.errors.tooFiles"));
  } else {
    const tempFiles: File[] = Array.from(files);
    processFiles(tempFiles);
  }
  isEntering.value = false;
}

function onDragOver(event: DragEvent) {
  event.preventDefault();
  isEntering.value = true;
}

function onDragLeave(event: DragEvent) {
  event.preventDefault();
  isEntering.value = false;
}

function filesAreValid(tempFiles: File[]): boolean {
  for (let tempFile of tempFiles) {
    if (!tempFile.type.includes(FileTypes[props.fileOptions.type])) {
      return false;
    }
  }
  return true;
}

async function processFiles(tempFiles: File[]) {
  if (!filesAreValid(tempFiles)) {
    useToast().errorToast(t("global.errors.typesWrong"));
    return;
  }
  if (!props.fileOptions.isMultiple) {
    srcs.value = {};
    files.value = {};
  }
  await Promise.all(
    tempFiles.map(async (file) => {
      const url = await getUrlFromFile(file);
      if (props.fileOptions.type === "image") {
        try {
          files.value[file.name] = await compressImage(file);
        } catch {
          useToast().errorToast(t("global.errors.imageTooBig"));
        }
      } else {
        files.value[file.name] = file;
      }
      srcs.value[file.name] = url;
      return { url, file };
    })
  );
  emit("update:modelValue", formatValue());
}

async function handleFileChange(event) {
  const tempFiles: File[] = Array.from(event.target.files);
  processFiles(tempFiles);
  event.target.value = null;
}

function formatValue(): FileType[] {
  return Object.keys(files.value).map((key) => {
    const url = srcs.value[key];
    const file = files.value[key];
    const isUrl = !url.startsWith("data:");
    return { url, file, isUrl };
  });
}

function onRemoveFile(srcKey: string) {
  const { [`${srcKey}`]: removedSrc, ...newSrcs } = srcs.value;
  const { [`${srcKey}`]: removedFile, ...newFiles } = files.value;
  srcs.value = newSrcs;
  files.value = newFiles;
  emit("update:modelValue", formatValue());
}

function filesNotExists(): boolean {
  return initialFilesRef.value.some((item) => {
    return !item?.file;
  });
}

watch(
  initialFilesRef,
  async () => {
    if (filesNotExists()) {
      const newInitialFiles = await Promise.all(
        initialFilesRef.value.map(async ({ url, file, isUrl }) => {
          if (!file) {
            const response = await fetch(url, {
              mode: "no-cors",
            });
            const data = await response.blob();
            const fileName = url.match(/[^./]+\.[a-zA-Z0-9]+$/)?.[0];
            const tempFile = new File([data], fileName, {
              type: data.type,
            });
            return { url, file: tempFile, isUrl: true };
          }
          return { url, file, isUrl };
        })
      );
      emit("update:modelValue", newInitialFiles);
    } else {
      srcs.value = Object.fromEntries(
        initialFilesRef.value.map(({ url, file }) => {
          return [file.name, url];
        })
      );
      files.value = Object.fromEntries(
        initialFilesRef.value.map(({ file }) => {
          return [file.name, file];
        })
      );
    }
  },
  { immediate: true }
);

function isString(value): value is string {
  if (typeof value === "string") {
    return true;
  }
  return false;
}

async function getUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const url = e.target?.result;
      if (isString(url)) {
        resolve(url);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, " initialFilesRef: " + _toDisplayString(initialFilesRef.value.map(({ isUrl, file }) => {
          return { isUrl, file };
        })), 1),
          _createElementVNode("p", null, "srcs: " + _toDisplayString(srcs.value.length), 1),
          _createElementVNode("p", null, "files: " + _toDisplayString(files.value.length), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
            return (_openBlock(), _createElementBlock("p", {
              key: `size${index}`
            }, " file " + _toDisplayString(index) + " size: " + _toDisplayString(file.size), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`card
    py-2
    w-100
    ${isEntering.value ? 'entering' : ''}`),
      ref: "inputCard",
      onDragover: _withModifiers(onDragOver, ["prevent"]),
      onDragleave: onDragLeave,
      onDrop: handleDrop
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[0] || (_cache[0] = () => fileInput.value.click()),
                class: "btn btn-primary"
              }, " Cargar "),
              _createElementVNode("input", {
                type: "file",
                style: {"display":"none"},
                ref_key: "fileInput",
                ref: fileInput,
                class: "form-control",
                onChange: handleFileChange,
                multiple: __props.fileOptions.isMultiple,
                accept: accept.value
              }, null, 40, _hoisted_6)
            ])
          ]),
          (Object.keys(srcs.value).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(srcs.value), (srcKey, fileIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 
                files.value[srcKey].name + files.value[srcKey].type + files.value[srcKey].size
              ,
                      class: _normalizeClass(`${__props.fileOptions.type === 'excel' ? 'w-100' : ''}`)
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        (
                    srcs.value[srcKey] &&
                    __props.fileOptions?.imageOptions?.showPreview &&
                    __props.fileOptions.type === 'image'
                  )
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: srcs.value[srcKey] as string,
                              width: __props.fileOptions.imageOptions.width,
                              height: __props.fileOptions.imageOptions.height
                            }, null, 8, _hoisted_10))
                          : _createCommentVNode("", true),
                        (srcs.value[srcKey] && __props.fileOptions.type === 'excel')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _renderSlot(_ctx.$slots, "file-preview", { fileIndex: fileIndex })
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("i", {
                          onClick: () => onRemoveFile(srcKey),
                          class: "bi bi-x-circle icon-wrapper text-danger"
                        }, null, 8, _hoisted_12),
                        _createTextVNode(" " + _toDisplayString(files.value[srcKey].name), 1)
                      ])
                    ], 2))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("i", _hoisted_15, _toDisplayString(_ctx.$t("global.inputs.dragAndDrop")), 1)
                ])
              ]))
        ])
      ])
    ], 34)
  ], 64))
}
}

})