import { ProductFamilyDto } from "@/store/product-families/dtos/input/ProductFamily.dto";
import { ProductFamilyForm } from "@/store/product-families/interfaces/ProductFamilyForm.interface";
import { get } from "lodash";

export function ProductFamilyDtoToProductFamilyForm(
  productFamily: ProductFamilyDto
): ProductFamilyForm {
  const id = get(productFamily, "id", false);
  const name = get(productFamily, "name");
  const description = get(productFamily, "description");
  const customFields = get(productFamily, "customFields");
  return {
    productFamily: {
      ...(id === false ? {} : { id }),
      name,
      description,
      customFields,
    },
  };
}
