import axiosInstance from "@/middlewares/Routing.middleware";
import { SupplierDto } from "../dtos/input/Supplier.dto";
import { CreateSupplierDto } from "../dtos/output/CreateSupplier.dto";
import { DeleteSupplierDto } from "../dtos/output/DeleteSupplier.dto";
import { UpdateSupplierDto } from "../dtos/output/UpdateSupplier.dto";

export async function CreateSupplier(
  payload: CreateSupplierDto
): Promise<SupplierDto> {
  return (await axiosInstance.post(`suppliers`, payload)).data;
}

export async function UpdateSupplier(
  payload: UpdateSupplierDto
): Promise<SupplierDto> {
  const { id, ...supplierProps } = payload;
  return (await axiosInstance.put(`suppliers/${id}`, supplierProps)).data;
}

export async function DeleteSupplier(
  payload: DeleteSupplierDto
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`suppliers?newId=${newId}&oldId=${oldId}`);
}
