import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import { DivisionFormInterface } from "@/store/divisions/interfaces/DivisionForm.interface";
import { get } from "lodash";

export const DivisionDtoToDivisionForm = (
  division: DivisionDto
): DivisionFormInterface => {
  const id = get(division, "id", false);
  const name = get(division, "name");
  const location = get(division, "location");
  const customFields = get(division, "customFields");
  return {
    division: {
      ...(id === false ? {} : { id }),
      name,
      location,
      customFields,
    },
  };
};
