<template>
  <div class="d-flex option content" ref="optionContainer">
    <router-link
      v-if="props.header.action?.route"
      :to="{ name: props.header.action.route }"
      :class="optionStyles"
    >
      {{ $t(props.header.i18nName) }}
    </router-link>
    <div v-else class="nav-item dropdown" ref="dropdownContainer">
      <a
        href="#"
        role="button"
        @click="OpenOrCloseDropdown"
        :class="[...optionStyles, { active: isActive }]"
      >
        {{ $t(props.header.i18nName) }}
      </a>
    </div>
    <ul class="dropdown-menu" ref="dropdown">
      <li
        v-for="(element, index) of props.header.submenu ?? []"
        :key="element.i18nName + index"
        class="item"
      >
        <SubmenuOption
          :option="element"
          :parent-is-closed="isOpenDropdown"
          ref="submenus"
          @navigating="() => closeDropdown(true)"
          @openDropdown="() => closeAllDropdowns(index)"
        ></SubmenuOption>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  PropType,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import SubmenuOption from "./SubmenuOption.vue";

const props = defineProps({
  header: { type: Object as PropType<SidebarElement>, required: true },
});

const route = useRoute();
const dropdownContainer = ref();
const optionContainer = ref();
const dropdown = ref();
const submenus = ref([]);
const isOpenDropdown = ref(false);
const emit = defineEmits(["navigating"]);

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const closeAllDropdowns = (index: number) => {
  submenus.value.forEach((submenu, index2) => {
    if (submenu.isOpenDropdown && index2 !== index) {
      submenu.closeDropdown();
    }
  });
};

function OpenOrCloseDropdown() {
  if (isOpenDropdown.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

function openDropdown() {
  Dropdown.getOrCreateInstance(dropdownContainer.value)?.show();
  isOpenDropdown.value = true;
  adjustDropdownPosition();
}

function closeDropdown(emitClose = false) {
  Dropdown.getOrCreateInstance(dropdownContainer.value)?.hide();
  if (emitClose) {
    emit("navigating");
  }
  isOpenDropdown.value = false;
}

const optionStyles = [
  "text-nowrap",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
  "w-100",
  "h-100",
  "d-flex",
  "justify-content-center",
  "align-items-center",
];

function adjustDropdownPosition() {
  const parentRect = optionContainer.value.getBoundingClientRect();
  dropdown.value.style.position = "absolute";
  dropdown.value.style.left = `${parentRect.left}px`;
}

defineExpose({ isOpenDropdown, openDropdown, closeDropdown });
</script>

<style lang="scss" scoped>
$heightNav: 51px;

.custom-item-style {
  border-right: 1px solid #515365;
  font-size: 14px;
  font-weight: 500;
  color: #d4e6ed;
}

a {
  text-decoration: none;
  color: inherit;
}

.active,
.router-link-active {
  color: #3fcbd8;
}

.option {
  height: $heightNav;
}

.option:hover {
  .link-item {
    color: #3fcbd8;
    text-decoration: underline;
  }
}

.content {
  cursor: pointer;
  display: block;
}

.content::after {
  display: block;
}

.content ul {
  position: absolute;
}
</style>
