import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { OrderDto } from "@/store/orders/dtos/input/Order.dto";
import { CreateOrderDto } from "@/store/orders/dtos/output/CreateOrder.dto";
import { UpdateOrderDto } from "@/store/orders/dtos/output/UpdateOrder.dto";
import {
  CreateOrder,
  DeleteOrder,
  UpdateOrder,
} from "@/store/orders/services/Order.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useOrdersServices() {
  const { t } = useI18n();
  const alias = "orders";

  const createOrder = async (
    payload: CreateOrderDto,
    callBackCreate: (order: OrderDto) => void
  ): Promise<void> => {
    await CreateOrder(payload)
      .then(async (order: OrderDto) => {
        callBackCreate(order);
        useToast().successToast(t("orders.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateOrder = async (
    payload: UpdateOrderDto,
    callBackUpdate: (order: OrderDto) => void
  ): Promise<void> => {
    await UpdateOrder(payload)
      .then(async (order: OrderDto) => {
        callBackUpdate(order);
        useToast().successToast(t("orders.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteOrder = async (
    payload: OrderDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteOrder(payload.id)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("orders.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createOrder,
    updateOrder,
    deleteOrder,
  };
}
