<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="workplaceForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="translateForm"
            :form="formTranslateRef"
            form-name="translateForm"
            :key="translateFormId"
            :initial-values="translates"
            :is-view="action === 'view'"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesServices } from "@/composables/useWorkplacesServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { WorkplaceDto } from "@/store/workplaces/dtos/input/Workplace.dto";
import { WorkplaceTranslateFormInterface } from "@/store/workplaces/interfaces/WorkplaceTranslateForm.interface";
import { SimpleWorkplaceActionType } from "@/views/workplaces/WorkplacesView.vue";
import { merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateWorkplaceTemplate } from "./templates/forms/CreateOrUpdateWorkplace.template";

const { customFields } = useCustomFieldsServices();
const { createWorkplace, updateWorkplace } = useWorkplacesServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<WorkplaceDto>, required: false },
  action: {
    type: String as PropType<SimpleWorkplaceActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<WorkplaceDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});
const translateFormId = ref();

const emit = defineEmits(["handleSubmit"]);

async function handleTranslateSubmit() {
  const { values }: { values: WorkplaceTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(workplace: WorkplaceDto) {
  emit("handleSubmit", { workplace, isCreating: true });
}

function callBackEdit(workplace: WorkplaceDto) {
  emit("handleSubmit", {
    workplace,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const workplace = merge({}, valuesFormatted, translates.value);
  if ("id" in workplace) {
    await updateWorkplace(workplace, callBackEdit);
  } else {
    await createWorkplace(workplace, callBackCreate);
  }
}

onMounted(async () => {
  await getAllCatalogs();
  watch(
    [initialValuesRef, catalogs],
    async () => {
      formTemplateRef.value = CreateOrUpdateWorkplaceTemplate(
        customFields.value,
        catalogs.value,
        handleSubmit,
        !initialValuesRef.value,
        handleTranslateButton
      );
    },
    { deep: true, immediate: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
