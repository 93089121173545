import { MeasureDto } from "@/store/measures/dtos/input/Measure.dto";
import { CreateMeasureDto } from "@/store/measures/dtos/output/CreateMeasure.dto";
import { DeleteMeasureDto } from "@/store/measures/dtos/output/DeleteMeasure.dto";
import { UpdateMeasureDto } from "@/store/measures/dtos/output/UpdateMeasure.dto";
import {
  CreateMeasure,
  DeleteMeasure,
  UpdateMeasure,
} from "@/store/measures/services/Measure.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useMeasuresServices() {
  const { t } = useI18n();
  const alias = "measures";

  const createMeasure = async (
    payload: CreateMeasureDto,
    callBackCreate: (measure: MeasureDto) => void
  ): Promise<void> => {
    await CreateMeasure(payload)
      .then(async (measure: MeasureDto) => {
        callBackCreate(measure);
        useToast().successToast(t("measures.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const updateMeasure = async (
    payload: UpdateMeasureDto,
    callBackUpdate: (measure: MeasureDto) => void
  ): Promise<void> => {
    await UpdateMeasure(payload)
      .then(async (measure: MeasureDto) => {
        callBackUpdate(measure);
        useToast().successToast(t("measures.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const deleteMeasure = async (
    payload: DeleteMeasureDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteMeasure(payload)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("measures.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    createMeasure,
    updateMeasure,
    deleteMeasure,
  };
}
