<template>
  <GeneralAcordion
    :unique-expanded="true"
    @closed="onAcordionClosed"
    :key="acordionId"
  >
    <template #header-button-0> {{ $t("customFields.create") }} </template>
    <template #body-0
      ><CreateOrUpdateCustomFields
        :initialModuleName="initialModuleName"
        ref="createForm"
        :isCreating="true"
        @handleSubmit="handleCustomFieldSubmit"
      ></CreateOrUpdateCustomFields
    ></template>
    <template
      v-for="(customField, index) in customFields"
      :key="`header-${index + 1}`"
      #[`header-${index+1}`]="{ toggleAcordion }"
    >
      <div class="w-100 d-flex align-items-center justify-content-between p-2">
        <div>
          <span class="fs-5">{{ customField.name }}</span>
        </div>
        <div>
          <ButtonField
            :button-field="
              editButton(() => {
                toggleAcordion();
              })
            "
          ></ButtonField>
          <ButtonField
            :button-field="
              deleteButton(() => openDeleteCustomFieldModal(index))
            "
          ></ButtonField>
        </div>
      </div>
    </template>
    <template
      v-for="(customField, index) in customFields"
      :key="`body-${index + 1}`"
      #[`body-${index+1}`]
    >
      <div>
        <CreateOrUpdateCustomFields
          :initialModuleName="initialModuleName"
          :initial-values="customField"
          :isCreating="false"
          :key="formsId[index]"
          @handleSubmit="handleCustomFieldSubmit"
        ></CreateOrUpdateCustomFields>
      </div>
    </template>
  </GeneralAcordion>
  <ConfirmModal
    v-if="get(customFields, `${currentCustomFieldIndex}`, false)"
    :title="$t('global.warnings.delete')"
    ref="modal"
    :message="messageDeletion()"
    :confirmAction="
      async () => {
        await handleDeleteCustomField();
      }
    "
  ></ConfirmModal>
</template>

<script lang="ts" setup>
import CreateOrUpdateCustomFields from "@/components/customFields/CreateOrUpdateCustomFields.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { defineProps, nextTick, onMounted, Ref, ref } from "vue";
import GeneralAcordion from "../globals/acordions/GeneralAcordion.vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import ConfirmModal from "../globals/modals/ConfirmModal.vue";

const props = defineProps({
  initialModuleName: { type: String, required: false },
});

const { getModuleCustomFields, customFields, deleteCustomField } =
  useCustomFieldsServices(props.initialModuleName);

const createForm = ref();
const modal = ref();
const formsId = ref([]);
const currentCustomFieldIndex: Ref<number> = ref(-1);
const messageDeletion = (): LabelType => {
  const customField = get(
    customFields,
    `value.${currentCustomFieldIndex.value}`
  );
  return {
    value: "customFields.questionDelete",
    params: {
      name: customField.name,
    },
    needsTranslate: true,
  };
};
const acordionId = ref();

const onAcordionClosed = (index: number) => {
  formsId.value[index] = uuidv4();
};

async function openDeleteCustomFieldModal(customFieldIndex: number) {
  currentCustomFieldIndex.value = customFieldIndex;
  await nextTick();
  modal.value.openConfirmationModal();
}

async function handleDeleteCustomField() {
  const customField = customFields.value[currentCustomFieldIndex.value];
  await deleteCustomField(customField.id);
  acordionId.value = uuidv4();
}

function handleCustomFieldSubmit() {
  acordionId.value = uuidv4();
}

onMounted(async () => {
  await getModuleCustomFields();
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}

.customField {
  background-color: #7451c2;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.accordion-item:has(.collapse.show) {
  span {
    display: none;
  }
}
</style>
