import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "col-12"
}
const _hoisted_4 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { CreateCustomFieldDto } from "@/store/customFields/dtos/output/CreateCustomField.dto";
import { UpdateCustomFieldDto } from "@/store/customFields/dtos/output/UpdateCustomField.dto";
import { Modal } from "bootstrap";
import { get, merge, unset } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  onMounted,
  PropType,
  ref,
} from "vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { AddNameTranslateTemplate } from "./templates/forms/AddNameTranslate.template";
import { AddOptionTranslateTemplate } from "./templates/forms/AddOptionTranslate.template";
import { CreateOrUpdateCustomFieldTemplate } from "./templates/forms/CreateOrUpdateCustomField.template";
import { ButtonTranslateTemplate } from "./templates/forms/CreateOrUpdateSelectSettings.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateCustomFields',
  props: {
  initialModuleName: { type: String, required: false },
  initialValues: { type: Object as PropType<CustomFieldDto>, required: false },
  isCreating: { type: Boolean, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const { createCustomField, updateCustomField } = useCustomFieldsServices(
  props.initialModuleName
);
// Templates
const mainTemplate = ref();
const translateTemplate = ref();
const settingsTemplate = ref();
// Modals
const translateModal = ref();
const settingsModal = ref();
// Data
const translates = ref({
  metadata: { language: get(props, "initialValues.metadata.language") },
});
const settings = ref();
// Forms
const form = ref();
const settingsForm = ref();
const translateForm = ref();
// Counters
const translateFormId = ref();

const customFieldType = computed(() => {
  return get(form, "value.values.customField.type");
});

async function openTranslate(index?: number) {
  if (index === undefined) {
    translateTemplate.value = AddNameTranslateTemplate(handleSubmitTranslate);
  } else {
    translateTemplate.value = AddOptionTranslateTemplate(
      handleSubmitTranslate,
      `_${index}`
    );
  }
  translateFormId.value = uuidv4();
  Modal.getOrCreateInstance(translateModal.value.$el).show();
}

function openSettings() {
  console.log("Abriendo settings...");
}

async function submitSettings() {
  console.log("Mergeando settings...");
  Modal.getOrCreateInstance(settingsModal.value.$el).hide();
}

async function handleSubmitTranslate() {
  const { values } = translateForm.value;
  translates.value = merge({}, translates.value, values);
  Modal.getInstance(translateModal.value.$el)?.hide();
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const customField = merge({}, valuesFormatted, translates.value);
  clearTranslates(customField);
  if ("id" in customField) {
    await updateCustomField(customField);
  } else {
    await createCustomField(customField);
  }
  emits("handleSubmit");
}

function clearTranslates(
  customField: CreateCustomFieldDto | UpdateCustomFieldDto
) {
  const options = customField.metadata.options;
  const optionsTranslates = get(customField, "metadata.language.options", {});
  for (const langKey in optionsTranslates) {
    Object.keys(get(optionsTranslates, `${langKey}`, {})).forEach(
      (optionKey) => {
        if (!Object.keys(options).includes(optionKey)) {
          unset(
            customField,
            `metadata.language.options.${langKey}.${optionKey}`
          );
        }
      }
    );
  }
}

onMounted(() => {
  const options = Object.keys(
    get(props, "initialValues.metadata.options", {
      _0: "A",
    })
  );
  const customFieldId = get(props, "initialValues.id");
  mainTemplate.value = CreateOrUpdateCustomFieldTemplate(
    customFieldType.value,
    "id" in (props.initialValues ?? {}),
    handleSubmit,
    openSettings,
    openTranslate,
    options,
    customFieldId
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (mainTemplate.value)
        ? (_openBlock(), _createBlock(GeneralForm, {
            key: 0,
            ref_key: "form",
            ref: form,
            form: mainTemplate.value,
            formName: "create",
            "initial-values": props.initialValues
          }, _createSlots({ _: 2 }, [
            _renderList(1000, (_, index) => {
              return {
                name: `input-master-section-1-field-${index}-subField-0`,
                fn: _withCtx(() => [
                  _createVNode(ButtonField, {
                    "button-field": {
              ..._unref(ButtonTranslateTemplate),
              action: () => openTranslate(index),
            }
                  }, null, 8, ["button-field"])
                ])
              }
            })
          ]), 1032, ["form", "initial-values"]))
        : _createCommentVNode("", true)
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(GeneralModal, {
            modalId: `settingsModal${form.value?.formId}`,
            modalName: _ctx.$t('customFields.settings'),
            ref_key: "settingsModal",
            ref: settingsModal,
            "data-bs-backdrop": "false",
            class: "p-4"
          }, {
            default: _withCtx(() => [
              _createVNode(GeneralForm, {
                form: settingsForm.value,
                formName: "settings",
                ref_key: "settingsForm",
                ref: settingsForm
              }, null, 8, ["form"])
            ]),
            _: 1
          }, 8, ["modalId", "modalName"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(GeneralModal, {
        modalId: "translateModal",
        ref_key: "translateModal",
        ref: translateModal,
        modalName: `${_ctx.$t('customFields.translates')}`,
        "data-bs-backdrop": "false",
        class: "p-4"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(GeneralForm, {
            form: translateTemplate.value,
            formName: `translateForm`,
            ref_key: "translateForm",
            ref: translateForm,
            key: translateFormId.value,
            initialValues: translates.value
          }, null, 8, ["form", "initialValues"]))
        ]),
        _: 1
      }, 8, ["modalName"])
    ])
  ]))
}
}

})