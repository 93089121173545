import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }

import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateProfile from "@/components/profiles/CreateOrUpdateProfile.vue";
import DeleteProfile from "@/components/profiles/DeleteProfile.vue";
import { ProfileHeader } from "@/components/profiles/templates/headers/Profile.headers";
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { v4 as uuidv4 } from "uuid";
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

export type SimpleProfileActionType = "create" | "edit" | "view";

type ProfileActionType = SimpleProfileActionType | "delete";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilesView',
  setup(__props) {

const { permissions, getPermissions } = usePermissionsServices();
const { deleteProfile } = useProfilesServices();
const { t } = useI18n();

const form = ref();
const profileTable = ref();
const offCanvasRef = ref();
const profilesHeaderTable = ref();
const profileTemp: Ref<ProfileDto | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<ProfileActionType> = ref();
const confirmModalDeleteProfile: Ref<string> = ref("");
const currentFormId = ref();

const tableFilters = ref([]);

function handleProfileSubmit(data) {
  const { profile, isCreating } = data;
  if (isCreating) {
    profileTable.value.currentData = [
      profile,
      ...profileTable.value.currentData,
    ];
  } else {
    profileTable.value.currentData[lastRowIndex.value] = {
      ...profileTable.value.currentData[lastRowIndex.value],
      ...profile,
    };
  }
  offCanvasRef.value.closeOffCanvas();
}

function openOffcanvasForm(newAction: ProfileActionType) {
  profileTemp.value = null;
  action.value = newAction;
  form.value?.resetForms?.();
  offCanvasRef.value.openOffCanvas();
}

function optionEdit(profile: ProfileDto, index: number) {
  openOffcanvasForm("edit");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function optionDelete(profile: ProfileDto, index: number) {
  action.value = "delete";
  confirmModalDeleteProfile.value = t("global.warnings.delete");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionView(profile: ProfileDto, index: number) {
  openOffcanvasForm("view");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = profileTemp.value;

    await deleteProfile({ oldId, newId }, () => {
      profileTable.value.currentData = profileTable.value.currentData.filter(
        (profile: UserDto, index) => {
          return index !== lastRowIndex.value;
        }
      );
    });
  }
  confirmationModalRef.value?.closeConfirmationModal();
}

onMounted(async () => {
  await getPermissions();
  profilesHeaderTable.value = ProfileHeader(
    optionEdit,
    optionDelete,
    optionView,
    permissions.value
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("profiles.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("profiles.form.add")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: 
      action.value === 'create'
        ? _ctx.$t('profiles.form.createTitle')
        : action.value === 'edit'
        ? _ctx.$t('profiles.form.editTitle')
        : _ctx.$t('profiles.form.viewTitle')
    
    }, {
      headerComplement: _withCtx(() => [
        (action.value === 'view')
          ? (_openBlock(), _createBlock(ButtonField, {
              key: 0,
              "button-field": _unref(editButton)(() => optionEdit(profileTemp.value, lastRowIndex.value))
            }, null, 8, ["button-field"]))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        (_openBlock(), _createBlock(CreateOrUpdateProfile, {
          ref_key: "form",
          ref: form,
          "initial-values": profileTemp.value,
          onHandleSubmit: handleProfileSubmit,
          key: currentFormId.value,
          action: action.value as SimpleProfileActionType
        }, null, 8, ["initial-values", "action"]))
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalDeleteProfile.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef
    }, {
      default: _withCtx(() => [
        (profileTemp.value?.id)
          ? (_openBlock(), _createBlock(DeleteProfile, {
              key: profileTemp.value.id,
              "handle-submit": handleConfirm,
              "current-row": profileTemp.value
            }, null, 8, ["current-row"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"]),
    (profilesHeaderTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/profiles/",
          filters: tableFilters.value,
          headers: profilesHeaderTable.value,
          name: "usersTable",
          ref_key: "profileTable",
          ref: profileTable
        }, null, 8, ["filters", "headers"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})