import axiosInstance from "@/middlewares/Routing.middleware";
import { jsonToFormData } from "@/shared/globals/helpers/Form.helper";
import { MakeImportDto } from "../dtos/MakeImport.dto";

export const ImportFile = async (
  moduleName: string,
  payload: MakeImportDto
): Promise<void> => {
  const formData = jsonToFormData(payload);
  await axiosInstance.post(`${moduleName.replace("_", "-")}/import`, formData);
};
