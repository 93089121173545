import { useLanguageServices } from "@/composables/useLanguageServices";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";

const format = (date, locale, options) =>
  new Intl.DateTimeFormat(locale, options).format(date);

export function formatDate(date: Date): string {
  const { lang } = useLanguageServices();
  if (DateHelper.of().isValid(date)) {
    return format(new Date(date), lang.value, {
      dateStyle: "long",
      timeZone: "UTC",
    });
  }
  return "-";
}
