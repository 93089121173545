import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "accordion-header" }
const _hoisted_2 = ["onClick", "data-bs-target", "aria-controls", "disabled"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "accordion-body" }

import { Collapse } from "bootstrap";
import {
  computed,
  onMounted,
  PropType,
  ref,
  useSlots,
} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralAcordion',
  props: {
  expandedItems: {
    type: Object as PropType<Array<number>>,
    required: false,
  },
  uniqueExpanded: {
    type: Boolean,
    required: false,
  },
},
  emits: ["closed"],
  setup(__props, { emit: __emit }) {

const props = __props;

const accordion = ref();
const accordionItems = ref([]);
const collapseItems = ref([]);
const eventsDone = ref([]);

const slots = useSlots();
const isRunning = ref(false);
const emits = __emit;

const waitForToggleCompletion = (): Promise<void> => {
  return new Promise((resolve) => {
    const checkCondition = async () => {
      const isValid = eventsDone.value.every((done) => {
        return done;
      });
      if (isValid) {
        resolve();
      } else {
        setTimeout(checkCondition, 50);
      }
    };
    checkCondition();
  });
};

const toggleAcordion = async (index) => {
  if (isRunning.value) return;
  isRunning.value = true;
  for (const [i, collapseItem] of collapseItems.value.entries()) {
    eventsDone[i] = false;
    if (i !== index && props.uniqueExpanded) {
      Collapse.getOrCreateInstance(collapseItem).hide();
      emits("closed", { index });
    } else {
      Collapse.getOrCreateInstance(collapseItems.value[index]).toggle();
    }
  }
  if (props.uniqueExpanded) {
    await waitForToggleCompletion();
  }
  setTimeout(() => {
    isRunning.value = false;
  }, 500);
};

const allHeaderSlots = computed(() =>
  Object.keys(slots)
    .filter((slot) => slot.startsWith("header-"))
    .map((slot) => {
      const isHeaderButton = slot.startsWith("header-button-");
      return { index: slot.split("-").at(-1), isHeaderButton };
    })
);

onMounted(() => {
  new Collapse(accordion.value, {
    toggle: false,
  });
  collapseItems.value.forEach((collapseItem, index) => {
    Collapse.getOrCreateInstance(collapseItem, {
      toggle: false,
    });
    collapseItem.addEventListener("hidden.bs.collapse", function () {
      eventsDone.value[index] = true;
    });
    collapseItem.addEventListener("shown.bs.collapse", function () {
      eventsDone.value[index] = true;
    });
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "accordion",
    ref_key: "accordion",
    ref: accordion
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allHeaderSlots.value, (slot, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "accordion-item",
        key: `accordionItem-${index}`,
        ref_for: true,
        ref_key: "accordionItems",
        ref: accordionItems
      }, [
        _createElementVNode("h2", _hoisted_1, [
          (slot.isHeaderButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "accordion-button",
                type: "button",
                onClick: _withModifiers(($event: any) => (toggleAcordion(index)), ["prevent"]),
                "data-bs-toggle": "collapse",
                "data-bs-target": `#collapse${index}`,
                "aria-expanded": "true",
                "aria-controls": `collapse${index}`,
                disabled: isRunning.value
              }, [
                _renderSlot(_ctx.$slots, `header-button-${index}`)
              ], 8, _hoisted_2))
            : _renderSlot(_ctx.$slots, `header-${index}`, {
                key: 1,
                toggleAcordion: () => toggleAcordion(index)
              })
        ]),
        _createElementVNode("div", {
          id: `collapse${index}`,
          class: "accordion-collapse collapse",
          ref_for: true,
          ref_key: "collapseItems",
          ref: collapseItems
        }, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, `body-${index}`)
          ])
        ], 8, _hoisted_3)
      ]))
    }), 128))
  ], 512))
}
}

})