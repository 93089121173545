import { CreateOrderDto } from "@/store/orders/dtos/output/CreateOrder.dto";
import { UpdateOrderDto } from "@/store/orders/dtos/output/UpdateOrder.dto";
import { OrderForm } from "@/store/orders/interfaces/OrderForm.interface";
import { get } from "lodash";

export const OrderFormToOrderDto = (
  order: OrderForm
): CreateOrderDto | UpdateOrderDto => {
  const id = get(order, "order.id", false);
  const orderDate = get(order, "order.orderDate");
  const customer = { id: get(order, "order.customer.id") };
  const status = { id: get(order, "order.status.id") };
  const customFields = get(order, "order.customFields", {});
  return {
    ...(id === false ? {} : { id }),
    customer,
    status,
    orderDate,
    customFields,
  };
};
