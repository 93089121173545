<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="productForm"
          :initialValues="initialValuesRef"
          :is-view="action === 'view'"
        >
          <slot></slot>
        </GeneralForm>
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="translateForm"
            :form="formTranslateRef"
            form-name="translateForm"
            :key="translateFormId"
            :initialValues="translates"
            :is-view="action === 'view'"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProductsServices } from "@/composables/useProductsServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { ProductTranslateFormInterface } from "@/store/products/interfaces/ProductTranslateForm.interface";
import { SimplePoductActionTypes } from "@/views/products/ProductsView.vue";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateProductTemplate } from "./templates/forms/CreateOrUpdateProduct.template";

const { customFields, getModuleCustomFields } = useCustomFieldsServices();
const { createProduct, updateProduct } = useProductsServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<ProductDto>, required: false },
  openMeasureOffcanvas: {
    type: Object as PropType<() => void>,
    required: true,
  },
  action: { type: String as PropType<SimplePoductActionTypes>, required: true },
});

const initialValuesRef: Ref<ProductDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});
const translateFormId = ref();

const emit = defineEmits(["handleSubmit"]);

async function handleTranslateSubmit() {
  const { values }: { values: ProductTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(product: ProductDto) {
  emit("handleSubmit", { product, isCreating: true });
}

function callBackEdit(product: ProductDto) {
  emit("handleSubmit", {
    product,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const product = merge({}, valuesFormatted, translates.value);
  if ("id" in product) {
    await updateProduct(product, callBackEdit);
  } else {
    await createProduct(product, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  [initialValuesRef],
  async () => {
    const metadata = get(initialValuesRef.value, "metadata");
    translates.value = metadata ? { metadata } : {};
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [initialValuesRef],
    async () => {
      formTemplateRef.value = CreateOrUpdateProductTemplate(
        customFields.value,
        handleSubmit,
        props.action,
        handleTranslateButton,
        props.openMeasureOffcanvas
      );
    },
    { deep: true, immediate: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
