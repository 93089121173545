import { mapFromHeader } from "@/shared/globals/tables/helpers/tables.helper";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { GetTableData } from "@/shared/globals/tables/services/Tables.services";
import _, { get, set, unset } from "lodash";
import { computed, ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLanguageServices } from "./useLanguageServices";

export function useTablesServices(
  urlData: string,
  headers: Ref<TableHeader[]>,
  orderBy: Ref<string>,
  orderType: Ref<"ASC" | "DESC">,
  initialPageSize?: number
) {
  const currentData: Ref<any> = ref([]);
  const currentPage = ref(1);
  const pageSize = ref(initialPageSize ?? 5);
  const filters = ref({});
  const totalPages = ref(0);
  const totalItems = ref(0);
  const loadingTable = ref(false);
  const { t } = useI18n();
  const { lang } = useLanguageServices();

  const translatedHeaders = computed(() => {
    return headers.value.map((header) => ({
      ...header,
      value: header.value.needsTranslate
        ? t(header.value.value)
        : header.value.value,
    }));
  });

  const columnsFiltersApplied = computed(() => {
    const filtersHeaders = [];
    Object.keys(filters.value).forEach((filter) => {
      const value = filters.value[filter];
      if (
        (typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0 &&
          value !== undefined &&
          value.id !== undefined) ||
        (typeof value === "string" && value !== "") ||
        (typeof value === "number" && value)
      ) {
        filtersHeaders.push(
          translatedHeaders.value.find((header) =>
            header.mainKey ? header.mainKey === filter : header.key === filter
          )
        );
      }
    });
    return filtersHeaders.filter((filterH) => filterH);
  });

  const mappedData = computed(() => {
    return mapFromHeader(headers.value, currentData.value, lang.value);
  });

  function removeFilter(mappedKey: string) {
    if (Array.isArray(mappedKey)) {
      unset(filters.value, mappedKey[1]);
    } else {
      unset(filters.value, mappedKey);
    }
    getTableData();
  }

  const allFilters = computed(() => {
    const params: any = {
      page: currentPage.value,
      size: pageSize.value,
      orderBy: orderBy.value,
      orderType: orderType.value,
      lang: lang.value,
    };
    if (Object.keys(filters.value).length > 0) {
      params.filters = filters.value;
    }
    return params;
  });

  async function getTableData() {
    loadingTable.value = true;
    try {
      const response = await GetTableData(urlData, allFilters.value);
      currentData.value = response.data.elements;
      totalPages.value = response.data.metadata.totalPages;
      totalItems.value = response.data.metadata.totalItems;
    } catch (error) {
      console.error("Error al obtener los datos de la tabla:", error);
    } finally {
      loadingTable.value = false;
    }
  }

  watch(pageSize, () => {
    currentPage.value = 1;
    getTableData();
  });

  function applyFilters(filter: { path: string; value: any }) {
    if (
      get(filter, "value") ||
      get(filter, "value") === null ||
      (get(filters.value, filter.path, false) &&
        get(filter, "value", false) === "")
    ) {
      set(filters.value, filter.path, filter.value);
      currentPage.value = 1;
      getTableData();
    }
  }

  function addRow(newData) {
    currentData.value = [newData, ...currentData.value];
  }

  function updateRow(index, newData) {
    currentData.value[index] = _.cloneDeep({
      ...currentData.value[index],
      ...newData,
    });
  }

  function deleteRow(deleteIndex) {
    currentData.value = currentData.value.filter((row, index) => {
      return index !== deleteIndex;
    });
  }

  function addOrUpdateRow(key, newData) {
    const rowIndex = currentData.value.findIndex((row) => {
      const valueA = get(row, key, null);
      const valueB = get(newData, key, undefined);
      return valueA && valueB && valueA === valueB;
    });
    if (rowIndex > -1) {
      updateRow(rowIndex, newData);
    } else {
      addRow(newData);
    }
  }

  return {
    getTableData,
    currentData,
    mappedData,
    translatedHeaders,
    currentPage,
    pageSize,
    totalPages,
    totalItems,
    loadingTable,
    filters,
    applyFilters,
    updateRow,
    deleteRow,
    columnsFiltersApplied,
    removeFilter,
    addRow,
    addOrUpdateRow,
    allFilters,
  };
}
