import { CreateUserDto } from "@/store/users/dto/output/create-user.dto";
import { UpdateUserDto } from "@/store/users/dto/output/update-user.dto";
import { UserFormInterface } from "@/store/users/interfaces/UserForm.interface";
import { get } from "lodash";

export const UserFormToUserDto = ({
  user,
}: UserFormInterface): CreateUserDto | UpdateUserDto => {
  const id = get(user, "id", false);
  return {
    ...(id === false ? { id } : {}),
    name: user.name,
    email: user.email,
    profile: { id: user.profile.id },
    status: { id: user.status.id },
    mainPosition: { id: user.mainPosition.id },
    positions: get(user, "positions", []).map((position) => {
      return { id: position.id };
    }),
  };
};
