import { useToast } from "@/composables/useToastServices";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { t } from "@/shared/locales/services/i18n.services";

export function showToastError(
  alias: string,
  messageCode: keyof REQ_RES_CODES
) {
  const error = REQ_RES_CODES[messageCode];
  if (error === REQ_RES_CODES.ACCESS_EXPIRED) {
    return;
  }
  if (error) {
    useToast().errorToast(t(`${alias}.errors.${error}`));
  } else {
    useToast().errorToast(t("global.UNKNOWN_ERROR"));
  }
}

export function showToastInfo(alias: string, messageCode: keyof REQ_RES_CODES) {
  const error = REQ_RES_CODES[messageCode];
  if (error) {
    useToast().infoToast(t(`${alias}.errors.${error}`));
  } else {
    useToast().infoToast(t("global.UNKNOWN_ERROR"));
  }
}
