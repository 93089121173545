<template>
  <div
    class="modal fade"
    :id="props.modalId"
    tabindex="-1"
    :aria-labelledby="`${props.modalId}Label`"
    ref="modalRef"
  >
    <div :class="`modal-dialog modal-dialog-centered ${size ?? ''}`">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${props.modalId}Label`">
            {{ props.modalName }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalClosed"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Modal } from "bootstrap";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from "vue";

type SizeType = "modal-sm" | "modal-lg" | "modal-xl" | "modal-fullscreen";

const props = defineProps({
  modalId: { type: String, required: true },
  modalName: { type: String, required: true },
  size: { type: Object as PropType<SizeType>, required: false },
});

const emit = defineEmits(["modalClosed"]);

const modalRef = ref();
const modalInstance = ref();

function modalClosed() {
  emit("modalClosed");
}

function closeModal() {
  modalInstance.value?.hide();
}

function openModal() {
  modalInstance.value?.show();
}

onMounted(() => {
  modalInstance.value = Modal.getOrCreateInstance(modalRef.value);
});

onBeforeUnmount(() => {
  modalInstance.value.dispose();
});

defineExpose({
  openModal,
  closeModal,
});
</script>

<style lang="scss"></style>
