import axiosInstance from "@/middlewares/Routing.middleware";
import { CustomerDto } from "../dtos/input/Customer.dto";
import { CreateCustomerDto } from "../dtos/output/CreateCustomer.dto";
import { UpdateCustomerDto } from "../dtos/output/UpdateCustomer.dto";

export async function GetAllCustomers(): Promise<CustomerDto[]> {
  return (await axiosInstance.get(`customers/all`)).data;
}

export async function CreateCustomer(
  payload: CreateCustomerDto
): Promise<CustomerDto> {
  return (await axiosInstance.post(`customers`, payload)).data;
}

export async function UpdateCustomer(
  payload: UpdateCustomerDto
): Promise<CustomerDto> {
  const { id, ...customerProps } = payload;
  return (await axiosInstance.put(`customers/${id}`, customerProps)).data;
}

export async function DeleteCustomer(id: number): Promise<void> {
  await axiosInstance.delete(`customers/${id}`);
}
