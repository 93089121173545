import { CreateDivisionDto } from "@/store/divisions/dtos/output/CreateDivision.dto";
import { UpdateDivisionDto } from "@/store/divisions/dtos/output/UpdateDivision.dto";
import { DivisionFormInterface } from "@/store/divisions/interfaces/DivisionForm.interface";
import { get } from "lodash";

export const DivisionFormToDivisionDto = (
  division: DivisionFormInterface
): CreateDivisionDto | UpdateDivisionDto => {
  const id = get(division, "division.id", false);
  const name = get(division, "division.name");
  const location = get(division, "division.location");
  const customFields = get(division, "division.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    location,
    customFields,
  };
};
