import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  confirmationCodeValidation,
  emailValidation,
  passwordValidation,
} from "@/shared/globals/yup/services/YupRules.services";

export const ForgotPasswordStep2Template = (action): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "emailForgot",
            label: {
              value: "auth.fields.email",
              needsTranslate: true,
            },
            type: "email",
            methodsYup: () => {
              return emailValidation();
            },
          },
          {
            path: "newPassword",
            inputName: "forgotPassword",
            label: {
              value: "auth.fields.newPassword",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              return passwordValidation();
            },
          },
          {
            path: "confirmationCode",
            inputName: "confirmationCode",
            label: {
              value: "auth.fields.confirmationCode",
              needsTranslate: true,
            },
            type: "text",
            methodsYup: () => {
              return confirmationCodeValidation();
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "auth.carouselTabs.forgotPassword.submit2",
        needsTranslate: true,
      },
      action,
    },
  };
};
