import axiosInstance from "@/middlewares/Routing.middleware";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { WorkplaceFlowDto } from "../dtos/input/WorkplaceFlow.dto";
import { UpdateWorkplaceFlowDto } from "../dtos/output/UpdateWorkplaceFlow.dto";

export async function GetWorkplacesFlow(
  productId: number
): Promise<WorkplaceFlowDto> {
  return (await axiosInstance.get(`workplaces-flows/${productId}`)).data;
}

export async function UpdateWorkplacesFlow(
  payload: UpdateWorkplaceFlowDto
): Promise<ProductDto> {
  const { productId, ...props } = payload;
  return (await axiosInstance.put(`workplaces-flows/${productId}`, props)).data;
}
