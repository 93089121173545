<template>
  <div>
    <p>
      <span class="fw-bold bg-secondary text-white p-1 rounded">
        {{ detail.product.label }}
      </span>
    </p>
    <p class="row mt-1">
      <class class="col">
        <p class="fw-bold m-0">{{ detail.quantity }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.quantity") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ detail.discountType.label }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.discountType.title") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ detail.discount }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.discount") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ subtotal }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.subtotal") }}</p>
      </class>
    </p>
  </div>
</template>

<script lang="ts" setup>
import { getSubtotal } from "@/shared/orders/helpers/GetSubtotal.helper";
import { DiscountTypes } from "@/store/orders/enums/DiscountTypes.enum";
import { get } from "lodash";
import { PropType, computed, defineProps, toRef } from "vue";
import { ProductOption } from "../../store/products/interfaces/ProductOption.interface";

type Detail = {
  product: ProductOption;
  discount: number;
  discountType: {
    id: number;
    label: string;
    item: number;
    items: number[];
  };
  quantity: number;
};

const props = defineProps({
  detail: { type: Object as PropType<Detail>, required: true },
});
const detailRef = toRef(props, "detail");

const subtotal = computed(() => {
  const discountType = get(
    detailRef.value,
    "discountType.id",
    DiscountTypes.AMOUNT
  );
  const discount = get(detailRef.value, "discount", 0);
  if (detailRef.value?.quantity && detailRef.value?.product) {
    return getSubtotal(
      detailRef.value.quantity,
      Number(detailRef.value.product.item.price),
      discountType,
      discount
    );
  }
  return 0;
});
</script>

<style lang="scss"></style>
