import { CustomFieldValues } from "@/store/customFields/interfaces/CustomFieldValues.interface";
import { ProductFamilyMetadata } from "../../interfaces/ProductFamilyMetadata.interface";

export class ProductFamilyDto {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  metadata: ProductFamilyMetadata;
  customFields: CustomFieldValues;
}
