import { CreateWorkplaceDto } from "@/store/workplaces/dtos/output/CreateWorkplace.dto";
import { UpdateWorkplaceDto } from "@/store/workplaces/dtos/output/UpdateWorkplace.dto";
import { WorkplaceFormInterface } from "@/store/workplaces/interfaces/WorkplaceForm.interface";
import { get } from "lodash";

export function WorkplaceFormToWorkplaceDto(
  workplace: WorkplaceFormInterface
): CreateWorkplaceDto | UpdateWorkplaceDto {
  const id = get(workplace, "workplace.id", false);
  const name = get(workplace, "workplace.name");
  const description = get(workplace, "workplace.description");
  const status = { id: get(workplace, "workplace.status.id") };
  const division = { id: Number(get(workplace, "workplace.division.id")) };
  const customFields = get(workplace, "workplace.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    description,
    status,
    division,
    customFields,
  };
}
