import { CreateMeasureDto } from "@/store/measures/dtos/output/CreateMeasure.dto";
import { UpdateMeasureDto } from "@/store/measures/dtos/output/UpdateMeasure.dto";
import { MeasureForm } from "@/store/measures/interfaces/MeasureForm.interface";
import { get } from "lodash";

export const MeasureFormToMeasureDto = (
  measure: MeasureForm
): CreateMeasureDto | UpdateMeasureDto => {
  const id = get(measure, "measure.id", false);
  const name = get(measure, "measure.name");
  const description = get(measure, "measure.description");
  const customFields = get(measure, "measure.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    description,
    customFields,
  };
};
