import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_12 = { class: "dropdown" }
const _hoisted_13 = {
  key: 0,
  class: "row justify-content-end"
}
const _hoisted_14 = { class: "col-auto" }
const _hoisted_15 = { class: "row justify-content-end" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "row justify-content-end" }
const _hoisted_18 = { class: "col-auto" }
const _hoisted_19 = { class: "col pr-0" }
const _hoisted_20 = { class: "container w-auto" }
const _hoisted_21 = { class: "row justify-content-end" }
const _hoisted_22 = { class: "col-auto" }
const _hoisted_23 = ["onClick"]

import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import CreateOrUpdateMeasure from "@/components/measures/CreateOrUpdateMeasure.vue";
import CreateOrUpdateProduct from "@/components/products/CreateOrUpdateProduct.vue";
import PreviewTechnicalSheet from "@/components/products/PreviewTechnicalSheet.vue";
import { CreateOrUpdateProductTemplate } from "@/components/products/templates/forms/CreateOrUpdateProduct.template";
import { ProductsHeader } from "@/components/products/templates/headers/Products.headers";
import CreateOrUpdateTechnicalSheet from "@/components/technicalSheets/CreateOrUpdateTechnicalSheet.vue";
import CreateOrUpdateWorkplacesFlow from "@/components/workplaces-flows/CreateOrUpdateWorkplacesFlow.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useProductsServices } from "@/composables/useProductsServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export type SimplePoductActionTypes = "create" | "edit" | "view";

type ProductActionTypes =
  | SimplePoductActionTypes
  | "delete"
  | "productsCustomFields"
  | "technicalSheetCustomFields"
  | "workplacesFlowCustomFields";

type ProductActionChildrenTypes =
  | "technicalSheet"
  | "workplacesFlow"
  | "measure";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteProduct } = useProductsServices();
const { exportFile } = useImportExportServices();
const { t } = useI18n();
const { lang } = useLanguageServices();

const formKeys = ref([0, 0, 0]);
const forms = ref([]);
const tableRef = ref();
const offCanvasRefs = ref([]);
const action: Ref<ProductActionTypes> = ref("create");
const actionChildren: Ref<ProductActionChildrenTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductDto | null> = ref();
const technicalSheetModal = ref();
const currentProductId = ref();
const technicalSheetPreviewId = ref();
const productTemplate = ref();
const settingsDropdown = ref();
const importModal = ref();
const importFormId = ref();
const currentFormId = ref();

const moduleName = computed(() => {
  if (
    actionChildren.value === "technicalSheet" ||
    action.value === "technicalSheetCustomFields"
  ) {
    return "technical_sheets";
  } else if (
    actionChildren.value === "workplacesFlow" ||
    action.value === "workplacesFlowCustomFields"
  ) {
    return "workplace_flows";
  }
  return "products";
});

async function openImportModal() {
  importFormId.value = uuidv4();
  productTemplate.value = CreateOrUpdateProductTemplate(customFields.value);
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    tableRef,
    "value.allFilters"
  );
  exportFile(filters);
}

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `products.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const openOffCanvas = (index) => {
  formKeys.value[index]++;
  offCanvasRefs.value?.[index]?.openOffCanvas?.();
};

function handleSubmit(data, index) {
  const { product, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [product, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, product);
  }
  offCanvasRefs.value[index].closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete" | "view",
  row: ProductDto,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  } else if (actionType === "view") {
    openOffcanvasForm("view");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteProduct(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function isProductActionType(
  value: ProductActionTypes | ProductActionChildrenTypes
): value is ProductActionTypes {
  return [
    "create",
    "edit",
    "view",
    "productsCustomFields",
    "technicalSheetCustomFields",
    "workplacesFlowCustomFields",
  ].includes(value);
}

function openOffcanvasForm(
  newAction: ProductActionTypes | ProductActionChildrenTypes
) {
  if (isProductActionType(newAction)) {
    tempRow.value = null;
    action.value = newAction;
    actionChildren.value = null;
    openOffCanvas(0);
  } else {
    actionChildren.value = newAction;
    openOffCanvas(1);
  }
  settingsDropdown.value.closeDropdown();
}

function openTechnicalSheetPreview(id: number) {
  currentProductId.value = id;
  technicalSheetPreviewId.value = uuidv4();
  technicalSheetModal.value.openModal();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields, lang],
    () => {
      headerTable.value = ProductsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("products.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[11] || (_cache[11] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("products.form.add")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(GeneralDropdown, {
                    ref_key: "settingsDropdown",
                    ref: settingsDropdown
                  }, {
                    button: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn ml-1 btn-gear"
                      }, [
                        _createElementVNode("i", { class: "bi bi-gear" })
                      ], -1)
                    ])),
                    body: _withCtx(() => [
                      _createVNode(DropdownOption, null, {
                        name: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "w-100",
                            onClick: _cache[1] || (_cache[1] = 
                            () => openOffcanvasForm('productsCustomFields')
                          )
                          }, _toDisplayString(_ctx.$t("products.settings.customFields")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(DropdownOption, null, {
                        name: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "w-100",
                            onClick: _cache[2] || (_cache[2] = 
                            () =>
                              openOffcanvasForm('technicalSheetCustomFields')
                          )
                          }, _toDisplayString(_ctx.$t("products.settings.technicalSheetsCustomFields")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(DropdownOption, null, {
                        name: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "w-100",
                            onClick: _cache[3] || (_cache[3] = 
                            () =>
                              openOffcanvasForm('workplacesFlowCustomFields')
                          )
                          }, _toDisplayString(_ctx.$t("products.settings.workplacesFlowsCustomFields")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(DropdownOption, null, {
                        name: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "w-100",
                            onClick: openImportModal
                          }, _toDisplayString(_ctx.$t("products.settings.import")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(DropdownOption, null, {
                        name: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "w-100",
                            onClick: exportTable
                          }, _toDisplayString(_ctx.$t("products.settings.export")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref: (el) => (offCanvasRefs.value[0] = el),
      canvasName: 
      action.value === 'productsCustomFields'
        ? _ctx.$t('customFields.title')
        : action.value === 'create'
        ? _ctx.$t('products.form.createTitle')
        : action.value === 'edit'
        ? _ctx.$t('products.form.editTitle')
        : action.value === 'view'
        ? _ctx.$t('products.form.viewTitle')
        : `${_ctx.$t('customFields.title')} - ${_ctx.$t(
            `products.form.${action.value?.split('CustomFields')[0]}Title`
          )}`
    
    }, {
      headerComplement: _withCtx(() => [
        (action.value === 'view')
          ? (_openBlock(), _createBlock(ButtonField, {
              key: 0,
              "button-field": 
          _unref(editButton)(() => runAction('edit', tempRow.value, lastRowIndex.value))
        
            }, null, 8, ["button-field"]))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        (
          [
            'productsCustomFields',
            'technicalSheetCustomFields',
            'workplacesFlowCustomFields',
          ].includes(action.value)
        )
          ? (_openBlock(), _createBlock(CustomFields, {
              "initial-module-name": moduleName.value,
              ref: (el) => (forms.value[0] = el),
              key: formKeys.value[0]
            }, null, 8, ["initial-module-name"]))
          : _createCommentVNode("", true),
        (['create', 'edit', 'view'].includes(action.value))
          ? (_openBlock(), _createBlock(CreateOrUpdateProduct, {
              ref: (el) => (forms.value[0] = el),
              "initial-values": tempRow.value,
              onHandleSubmit: _cache[6] || (_cache[6] = (data) => handleSubmit(data, 0)),
              key: currentFormId.value,
              openMeasureOffcanvas: () => openOffcanvasForm('measure'),
              action: action.value as SimplePoductActionTypes
            }, {
              default: _withCtx(() => [
                (action.value === 'edit')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("span", {
                              class: "actionableLabel",
                              onClick: _cache[4] || (_cache[4] = () => openOffcanvasForm('technicalSheet'))
                            }, _toDisplayString(_ctx.$t("products.form.technicalSheet")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("span", {
                              class: "actionableLabel",
                              onClick: _cache[5] || (_cache[5] = () => openOffcanvasForm('workplacesFlow'))
                            }, _toDisplayString(_ctx.$t("products.form.workplaceFlow")), 1)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["initial-values", "openMeasureOffcanvas", "action"]))
          : _createCommentVNode("", true)
      ]),
      offcanvasChild: _withCtx(({ setRef }) => [
        _createVNode(GeneralOffcanvas, {
          canvasId: `${actionChildren.value}`,
          ref: 
          (el) => {
            offCanvasRefs.value[1] = el;
            setRef(el);
          }
        ,
          canvasName: 
          actionChildren.value === 'technicalSheet'
            ? _ctx.$t('technicalSheets.title', {name:(tempRow.value as ProductDto).name, measure:(tempRow.value as ProductDto).measure.name })
            : (actionChildren.value === 'workplacesFlow'? _ctx.$t('workplacesFlows.title'): _ctx.$t('measures.title'))
        ,
          initialWidth: actionChildren.value === 'technicalSheet' ? 80 : 30
        }, _createSlots({
          content: _withCtx(() => [
            (actionChildren.value === 'technicalSheet' && action.value === 'edit')
              ? (_openBlock(), _createBlock(CreateOrUpdateTechnicalSheet, {
                  ref: (el) => (forms.value[1] = el),
                  key: formKeys.value[1],
                  "product-id": tempRow.value?.id,
                  onHandleSubmit: _cache[8] || (_cache[8] = (data) => handleSubmit(data, 1))
                }, null, 8, ["product-id"]))
              : (actionChildren.value === 'workplacesFlow' && action.value === 'edit')
                ? (_openBlock(), _createBlock(CreateOrUpdateWorkplacesFlow, {
                    key: formKeys.value[1] + 1,
                    ref: (el) => (forms.value[1] = el),
                    "product-id": tempRow.value?.id,
                    onHandleSubmit: _cache[9] || (_cache[9] = (data) => handleSubmit(data, 1))
                  }, null, 8, ["product-id"]))
                : (actionChildren.value === 'measure')
                  ? (_openBlock(), _createBlock(CreateOrUpdateMeasure, {
                      key: 2,
                      ref: (el) => (forms.value[1] = el),
                      onHandleSubmit: _cache[10] || (_cache[10] = () => offCanvasRefs.value[1].closeOffCanvas()),
                      action: "create"
                    }, null, 512))
                  : _createCommentVNode("", true)
          ]),
          offcanvasChild: _withCtx(({ setRef }) => [
            _createVNode(GeneralOffcanvas, {
              canvasId: "customFieldTechnicalSheetsCanvas",
              ref: 
              (el) => {
                offCanvasRefs.value[2] = el;
                setRef(el);
              }
            ,
              canvasName: `${_ctx.$t('customFields.title')} - ${_ctx.$t(
              `products.form.${action.value?.split('CustomFields')[0]}Title`
            )}`
            }, {
              content: _withCtx(() => [
                (_openBlock(), _createBlock(CustomFields, {
                  "initial-module-name": moduleName.value,
                  key: moduleName.value
                }, null, 8, ["initial-module-name"]))
              ]),
              _: 2
            }, 1032, ["canvasName"])
          ]),
          _: 2
        }, [
          (actionChildren.value && action.value === 'edit')
            ? {
                name: "headerComplement",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("button", {
                            class: "btn ml-1 btn-gear",
                            type: "button",
                            onClick: _cache[7] || (_cache[7] = () => openOffCanvas(2))
                          }, _toDisplayString(_ctx.$t("customFields.title")), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["canvasId", "canvasName", "initialWidth"])
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslated.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    (headerTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/products/",
          filters: tableFilters.value,
          headers: headerTable.value,
          name: "productsTable",
          ref_key: "tableRef",
          ref: tableRef
        }, {
          technicalSheet: _withCtx(({ row }) => [
            (_unref(get)(row, 'technicalSheetExists'))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "bi bi-file-earmark-arrow-down fs-4",
                  onClick: ($event: any) => (openTechnicalSheetPreview(row.id))
                }, null, 8, _hoisted_23))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["filters", "headers"]))
      : _createCommentVNode("", true),
    _createVNode(GeneralModal, {
      ref_key: "technicalSheetModal",
      ref: technicalSheetModal,
      modalId: "technicalSheetPreview",
      modalName: _ctx.$t('technicalSheets.view.title'),
      size: "modal-xl"
    }, {
      default: _withCtx(() => [
        (currentProductId.value)
          ? (_openBlock(), _createBlock(PreviewTechnicalSheet, {
              "product-id": currentProductId.value,
              key: technicalSheetPreviewId.value
            }, null, 8, ["product-id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modalName"]),
    _createVNode(GeneralModal, {
      modalId: "importExportModal",
      ref_key: "importModal",
      ref: importModal,
      modalName: _ctx.$t('products.modals.importModal'),
      "data-bs-backdrop": "false",
      class: "p-4",
      size: "modal-xl"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralImport, {
          key: importFormId.value,
          template: productTemplate.value,
          handleSubmit: () => importModal.value.closeModal()
        }, null, 8, ["template", "handleSubmit"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})