import { CreateRawMaterialDto } from "@/store/raw-materials/dtos/output/CreateRawMaterial.dto";
import { UpdateRawMaterialDto } from "@/store/raw-materials/dtos/output/UpdateRawMaterial.dto";
import { RawMaterialForm } from "@/store/raw-materials/interfaces/RawMaterialForm.interface";
import { get } from "lodash";

export function RawMaterialFormToRawMaterialDto(
  rawMaterial: RawMaterialForm
): CreateRawMaterialDto | UpdateRawMaterialDto {
  const id = get(rawMaterial, "rawMaterial.id", false);
  const code = get(rawMaterial, "rawMaterial.code");
  const description = get(rawMaterial, "rawMaterial.description");
  const materialType = get(rawMaterial, "rawMaterial.materialType");
  const unitCost = get(rawMaterial, "rawMaterial.unitCost");
  const thickness = get(rawMaterial, "rawMaterial.thickness");
  const width = get(rawMaterial, "rawMaterial.width");
  const length = get(rawMaterial, "rawMaterial.length");
  const warehouseLocation = get(rawMaterial, "rawMaterial.warehouseLocation");
  const measure = { id: get(rawMaterial, "rawMaterial.measure.id") };
  const supplier = { id: get(rawMaterial, "rawMaterial.supplier.id") };
  const customFields = get(rawMaterial, "rawMaterial.customFields");
  return {
    ...(id === false ? {} : { id }),
    code,
    description,
    materialType,
    unitCost,
    thickness,
    width,
    length,
    warehouseLocation,
    measure,
    supplier,
    customFields,
  };
}
