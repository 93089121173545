import axiosInstance from "@/middlewares/Routing.middleware";
import { PositionDto } from "../dtos/input/Position.dto";
import { CreatePositionDto } from "../dtos/output/CreatePosition.dto";
import { UpdatePositionDto } from "../dtos/output/UpdatePosition.dto";

export async function CreatePosition(
  payload: CreatePositionDto
): Promise<PositionDto> {
  return (await axiosInstance.post(`positions`, payload)).data;
}

export async function UpdatePosition(
  payload: UpdatePositionDto
): Promise<PositionDto> {
  const { id, ...positionProps } = payload;
  return (await axiosInstance.put(`positions/${id}`, positionProps)).data;
}

export async function DeletePosition(id: number): Promise<void> {
  await axiosInstance.delete(`positions/${id}`);
}
