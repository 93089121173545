<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("productFamilies.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("productFamilies.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <GeneralDropdown ref="settingsDropdown">
                <template #button>
                  <button type="button" class="btn ml-1 btn-gear">
                    <i class="bi bi-gear"></i>
                  </button>
                </template>
                <template #body>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="openCustomFields">
                        {{ $t("customers.settings.customFields") }}
                      </span>
                    </template>
                  </DropdownOption>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="openImportModal">
                        {{ $t("customers.settings.import") }}
                      </span>
                    </template>
                  </DropdownOption>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="exportTable">
                        {{ $t("customers.settings.export") }}
                      </span>
                    </template>
                  </DropdownOption>
                </template>
              </GeneralDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      action === 'customField'
        ? $t('customFields.title')
        : action === 'create'
        ? $t('productFamilies.form.createTitle')
        : action === 'edit'
        ? $t('productFamilies.form.editTitle')
        : $t('productFamilies.form.viewTitle')
    "
  >
    <template v-slot:headerComplement>
      <ButtonField
        v-if="action === 'view'"
        :button-field="
          editButton(() => runAction('edit', tempRow, lastRowIndex))
        "
      >
      </ButtonField>
    </template>
    <template v-slot:content>
      <CustomFields v-if="action === 'customField'" ref="form"></CustomFields>
      <CreateOrUpdateProductFamily
        v-if="['create', 'edit', 'view'].includes(action)"
        ref="form"
        :initial-values="tempRow"
        @handle-submit="handleSubmit"
        :key="currentFormId"
        :action="action as SimpleProductFamilyActionType"
      ></CreateOrUpdateProductFamily>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal :title="confirmModalTitleTranslated" ref="confirmationModalRef">
    <DeleteProductFamily
      v-if="tempRow?.id"
      :key="tempRow.id"
      :handle-submit="handleConfirm"
      :current-row="tempRow"
    ></DeleteProductFamily>
  </ConfirmModal>
  <GeneralModal
    modalId="importExportModal"
    ref="importModal"
    :modalName="$t('productFamilies.modals.importModal')"
    data-bs-backdrop="false"
    class="p-4"
    size="modal-xl"
  >
    <GeneralImport
      :key="importFormId"
      :template="productFamilyTemplate"
      :handleSubmit="() => importModal.closeModal()"
    ></GeneralImport>
  </GeneralModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/products-families/"
    :filters="tableFilters"
    :headers="headerTable"
    name="productFamilyTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import CreateOrUpdateProductFamily from "@/components/product-families/CreateOrUpdateProductFamily.vue";
import DeleteProductFamily from "@/components/product-families/DeleteProductFamily.vue";
import { CreateOrUpdateProductFamilyTemplate } from "@/components/product-families/templates/forms/CreateOrUpdateProductFamily.template";
import { ProductsFamiliesHeader } from "@/components/product-families/templates/headers/ProductsFamilies.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useProductFamiliesServices } from "@/composables/useProductFamiliesServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { ProductFamilyDto } from "@/store/product-families/dtos/input/ProductFamily.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export type SimpleProductFamilyActionType = "create" | "edit" | "view";

type ProductFamilyActionType =
  | SimpleProductFamilyActionType
  | "customField"
  | "delete";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteProductFamily } = useProductFamiliesServices();
const { exportFile } = useImportExportServices();
const { t } = useI18n();
const { lang } = useLanguageServices();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<ProductFamilyActionType> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductFamilyDto | null> = ref();
const productFamilyTemplate = ref();
const settingsDropdown = ref();
const importModal = ref();
const importFormId = ref();
const currentFormId = ref();

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

async function openImportModal() {
  importFormId.value = uuidv4();
  productFamilyTemplate.value = CreateOrUpdateProductFamilyTemplate(
    customFields.value
  );
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    tableRef,
    "value.allFilters"
  );
  exportFile(filters);
}

function openCustomFields() {
  settingsDropdown.value.closeDropdown();
  openOffcanvasForm("customField");
}

function handleSubmit(data) {
  const { productFamily, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [productFamily, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, productFamily);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete" | "view",
  row: ProductFamilyDto,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  } else if (actionType === "view") {
    openOffcanvasForm("view");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = tempRow.value;
    await deleteProductFamily({ newId, oldId }, () => {
      tableRef.value.currentData = tableRef.value.currentData.filter(
        (row: ProductFamilyDto) => row.id !== oldId
      );
    });
  }
  confirmationModalRef.value?.closeConfirmationModal();
}

function openOffcanvasForm(newAction: ProductFamilyActionType) {
  tempRow.value = null;
  action.value = newAction;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields, lang],
    () => {
      headerTable.value = ProductsFamiliesHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
