<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :key="JSON.stringify(initialValuesRef)"
          :form="formTemplateRef"
          form-name="usersForm"
          :initialValues="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { SimpleUserActionType } from "@/views/users/UsersView.vue";
import { cloneDeep } from "lodash";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateUserTemplate } from "./templates/forms/CreateOrUpdateUser.template";
const { customFields } = useCustomFieldsServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = defineProps({
  initialValues: {
    type: Object as PropType<UserDto>,
    required: false,
  },
  openPositionOffcanvas: {
    type: Object as PropType<() => void>,
    required: true,
  },
  action: {
    type: String as PropType<SimpleUserActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<UserDto> = ref();
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

function callBackCreate(user: UserDto) {
  emit("handleSubmit", { user, isCreating: true });
}

function callBackEdit(user: UserDto) {
  emit("handleSubmit", {
    user,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { user } = form.value.values;
  const userFormatted = cloneDeep(user);
  userFormatted.status = { id: user.status.id };
  userFormatted.profile = { id: user.profile.id };
  userFormatted.positions = user?.positions?.map((position) => {
    return { id: position.id };
  });
  userFormatted.mainPosition = { id: user?.mainPosition?.id };
  if (!initialValuesRef.value) {
    await createUser(userFormatted, callBackCreate);
  } else {
    await updateUser(userFormatted, callBackEdit);
  }
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await getAllCatalogs();
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
  watch(
    [initialValuesRef],
    async () => {
      formTemplateRef.value = CreateOrUpdateUserTemplate(
        customFields.value,
        catalogs.value,
        props.action,
        handleSubmit,
        props.openPositionOffcanvas
      );
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss"></style>
