<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("workplacesFlows.form.title") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <draggable v-model="tempSteps" group="people" item-key="step">
    <template #item="{ element, index }">
      <div class="card p-3 mb-3">
        <div class="row" :key="element.step">
          <div class="col text-start">
            <WorkplaceFlowStep :step="element"></WorkplaceFlowStep>
          </div>
          <div class="col-auto p-0">
            <ButtonField
              :button-field="
                editButton(() => {
                  handleAddOrEditStep(index);
                })
              "
            ></ButtonField>
          </div>
          <div class="col-auto pl-0">
            <ButtonField
              :button-field="
                deleteButton(() => {
                  tempSteps.splice(index, 1);
                })
              "
            ></ButtonField>
          </div>
        </div>
      </div>
    </template>
  </draggable>
  <div class="col-12 d-flex justify-content-center">
    <ButtonField :buttonField="addWorkplaceFlowButtonTemplate"></ButtonField>
  </div>
  <GeneralModal
    ref="modal"
    modalId="step"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="stepForm"
      :form="stepFormTemplateRef"
      form-name="workplacesFlowForm"
      :key="formId"
      :initialValues="tempSteps[currentIndex]"
    >
    </GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateStepTemplate } from "./templates/forms/CreateOrUpdateStep.template";
import WorkplaceFlowStep from "./WorkplaceFlowStep.vue";

const { t } = useI18n();

const props = defineProps({
  steps: {
    type: Object as PropType<Array<WorkplaceStepForm>>,
    required: true,
  },
});

const stepForm = ref();
const stepFormTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const stepsRef: Ref<Array<WorkplaceStepForm>> = toRef(props, "steps");
const tempSteps: Ref<WorkplaceStepForm[]> = ref();
const addWorkplaceFlowButtonTemplate = ref(
  addButton(async () => {
    await handleAddOrEditStep(tempSteps.value.length);
  })
);
const formId = ref();
const currentIndex = ref();

const excludeOptions = computed(() => {
  return tempSteps.value.map(({ workplace }) => {
    return workplace;
  });
});

const emit = defineEmits(["update:modelValue"]);

async function handleSubmitStepForm(index: number) {
  const { values } = stepForm.value;
  tempSteps.value[index] = merge({}, tempSteps.value[index] ?? {}, values, {
    step: index + 1,
  });
  modal.value.closeModal();
}

async function handleAddOrEditStep(index: number) {
  currentIndex.value = index;
  const isCreating = tempSteps.value.length <= index;
  modalTitle.value = isCreating
    ? t("workplacesFlows.form.addStep")
    : t("workplacesFlows.form.updateStep");
  stepFormTemplateRef.value = CreateOrUpdateStepTemplate(
    () => handleSubmitStepForm(index),
    isCreating
  );
  stepFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  if (!isCreating) {
    await nextTick();
    stepForm.value.setValues(tempSteps.value[index]);
  }
  formId.value = uuidv4();
  modal.value.openModal();
}

watch(
  stepsRef,
  () => {
    tempSteps.value = stepsRef.value;
  },
  { deep: true, immediate: true }
);

watch(
  tempSteps,
  () => {
    let isChanged = false;
    tempSteps.value.forEach((step, index) => {
      if (step.step !== index + 1) {
        step.step = index + 1;
        isChanged = true;
      }
    });
    if (isChanged) {
      emit("update:modelValue", tempSteps.value);
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms: () => stepForm.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
