<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="storeForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="translateForm"
            :form="formTranslateRef"
            form-name="translateForm"
            :key="modalTitle"
            :initial-values="translates"
            :is-view="action === 'view'"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useStoresServices } from "@/composables/useStoresServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { StoreDto } from "@/store/stores/dtos/input/Store.dto";
import { StoreTranslateFormInterface } from "@/store/stores/interfaces/StoreTranslateForm.interface";
import { SimpleStoreActionType } from "@/views/stores/StoresView.vue";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateStoreTemplate } from "./templates/forms/CreateOrUpdateStore.template";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";

const { customFields } = useCustomFieldsServices();
const { createStore, updateStore } = useStoresServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<StoreDto>, required: false },
  action: { type: String as PropType<SimpleStoreActionType>, required: true },
});

const initialValuesRef: Ref<StoreDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates: Ref<StoreTranslateFormInterface> = ref({});
const translateFormId = ref();

const emit = defineEmits(["handleSubmit"]);

async function handleTranslateSubmit() {
  const { values }: { values: StoreTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(store: StoreDto) {
  emit("handleSubmit", { store, isCreating: true });
}

function callBackEdit(store: StoreDto) {
  emit("handleSubmit", {
    store,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const store = merge({}, valuesFormatted, translates.value);
  if ("id" in store) {
    await updateStore(store, callBackEdit);
  } else {
    await createStore(store, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  [initialValuesRef],
  async () => {
    const metadata = get(initialValuesRef.value, "metadata");
    translates.value = metadata ? { metadata } : {};
  },
  { deep: true, immediate: true }
);

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateStoreTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value,
      handleTranslateButton
    );
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
