import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 d-flex justify-content-center" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "col-12 d-flex justify-content-center mt-3"
}
const _hoisted_9 = { class: "" }
const _hoisted_10 = {
  key: 0,
  class: "col-auto mx-auto"
}

import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import {
  Field,
  Fields,
} from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { cloneDeep, get } from "lodash";
import { computed, PropType, Ref, ref, toRef } from "vue";
import ButtonField from "../buttons/ButtonField.vue";
import { addButton } from "../buttons/templates/AddButton.template";
import { deleteButton } from "../buttons/templates/DeleteButton.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleInputMaster',
  props: {
  form: { type: Object as PropType<Form>, required: true },
  errorBag: {
    type: Object,
    required: true,
  },
  sectionIndex: { type: Number, required: true },
  fieldIndex: { type: Number, required: true },
  debug: { type: Boolean, required: false, default: false },
},
  setup(__props) {

const props = __props;

const formRef: Ref<Form> = toRef(props, "form");
const fieldInstance = cloneDeep(
  formRef.value.sections[props.sectionIndex].fields[0]
);
const addButtonTemplate = ref(
  addButton(() => {
    if ("fields" in fieldInstance) {
      const fields = fieldInstance.fields.map((field) => {
        field.path = field.path.replace("0", String(props.fieldIndex + 1));
        field.inputName = field.inputName + props.fieldIndex + 2;
        return field;
      });
      formRef.value.sections[props.sectionIndex].fields.push({
        ...cloneDeep(fieldInstance),
        fields,
      });
    }
  })
);

const removeInstance = deleteButton(() => {
  formRef.value.sections[props.sectionIndex].fields[
    props.fieldIndex
  ].conditions = () => {
    return false;
  };
});

const fieldsLength = computed(() => {
  return formRef.value.sections[props.sectionIndex].fields.length;
});

const currentField: Ref<Fields> = computed(() => {
  const field: Fields =
    formRef.value.sections[props.sectionIndex].fields[props.fieldIndex];
  field.fields.forEach((fieldTemp: Field) => {
    if (fieldTemp?.placeholder?.params) {
      fieldTemp.placeholder.params = { index: String(props.fieldIndex + 1) };
    }
  });
  return field;
});

const lastValidFieldIndex = computed(() => {
  for (let i = fieldsLength.value - 1; i > -1; i--) {
    const condition =
      formRef.value?.sections?.[props.sectionIndex]?.fields?.[i]?.conditions;
    if (condition ? condition() : true) {
      return i;
    }
  }

  return 0;
});

return (_ctx: any,_cache: any) => {
  return (currentField.value.conditions ? currentField.value.conditions() : true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(`${
              currentField.value.fieldOptions?.requireCard ? 'card p-3' : 'w-100'
            } `)
              }, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentField.value.fields, (oneField, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `${props.sectionIndex}-${props.fieldIndex}-${index}`,
                      class: _normalizeClass(`col-lg-${oneField.widthDistribution ?? 12}`)
                    }, [
                      _createVNode(InputMaster, {
                        field: {
              ...oneField,
              buttons: [
                ...(oneField?.buttons ?? []),
                ...(index === currentField.value.fields.length - 1 && currentField.value?.fieldOptions?.requireButtonDelete && currentField.value?.fieldOptions?.deleteDistribution === 'end'
                  ? [
                      {
                        ..._unref(removeInstance),
                        ...(__props.fieldIndex ? {} : { isDisabled: true }),
                        metadata: {
                          positionInField:'end',
                        }
                      },
                    ]
                  : []),
              ] as ButtonTemplate[],
            },
                        errorBag: __props.errorBag,
                        "disabled-default-option": true,
                        "section-index": __props.sectionIndex,
                        "field-index": __props.fieldIndex,
                        "sub-field-index": index
                      }, {
                        "input-master": _withCtx(({ sectionIndex, fieldIndex, subfieldIndex }) => [
                          _renderSlot(_ctx.$slots, "input-master", {
                            sectionIndex: sectionIndex,
                            fieldIndex: fieldIndex,
                            subfieldIndex: subfieldIndex
                          })
                        ]),
                        _: 2
                      }, 1032, ["field", "errorBag", "section-index", "field-index", "sub-field-index"]),
                      _createElementVNode("div", _hoisted_6, [
                        false
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(`section-${__props.sectionIndex}-field-${__props.fieldIndex}-subField-${index}`), 1))
                          : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "dynamic-slot", {
                          sectionIndex: __props.sectionIndex,
                          fieldIndex: __props.fieldIndex,
                          subfieldIndex: index
                        })
                      ])
                    ], 2))
                  }), 128)),
                  (
                  currentField.value?.fieldOptions?.requireButtonDelete &&
                  currentField.value?.fieldOptions?.deleteDistribution === 'bottom'
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(ButtonField, {
                            "button-field": {
                      ..._unref(removeInstance),
                      ...(__props.fieldIndex ? {} : { isDisabled: true }),
                    }
                          }, null, 8, ["button-field"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ])
          ])
        ]),
        (
        __props.fieldIndex === lastValidFieldIndex.value &&
        currentField.value?.fieldOptions?.requireButtonAdd
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(ButtonField, { buttonField: addButtonTemplate.value }, null, 8, ["buttonField"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})