import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import CreateOrUpdateDivision from "@/components/divisions/CreateOrUpdateDivision.vue";
import DeleteDivision from "@/components/divisions/DeleteDivision.vue";
import { CreateOrUpdateDivisionTemplate } from "@/components/divisions/templates/forms/CreateOrUpdateDivision.template";
import { DivisionsHeader } from "@/components/divisions/templates/headers/Division.headers";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export type SimpleDivisionActionType = "create" | "edit" | "view";

type DivisionActionType = SimpleDivisionActionType | "customField" | "delete";


export default /*@__PURE__*/_defineComponent({
  __name: 'DivisionsView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteDivision } = useDivisionsServices();
const { exportFile } = useImportExportServices();
const { t } = useI18n();
const { lang } = useLanguageServices();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<DivisionActionType> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<DivisionDto | null> = ref();
const divisionTemplate = ref();
const settingsDropdown = ref();
const importModal = ref();
const importFormId = ref();
const currentFormId = ref();

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

async function openImportModal() {
  importFormId.value = uuidv4();
  divisionTemplate.value = CreateOrUpdateDivisionTemplate(customFields.value);
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    tableRef,
    "value.allFilters"
  );
  exportFile(filters);
}

function openCustomFields() {
  settingsDropdown.value.closeDropdown();
  openOffcanvasForm("customField");
}

function handleSubmit(data) {
  const { division, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [division, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, division);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: DivisionActionType,
  row: DivisionDto,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  } else if (actionType === "view") {
    openOffcanvasForm("view");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = tempRow.value;
    await deleteDivision({ oldId, newId }, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
      confirmationModalRef.value?.closeConfirmationModal();
    });
  }
}

function openOffcanvasForm(newAction: DivisionActionType) {
  tempRow.value = null;
  action.value = newAction;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields, lang],
    () => {
      headerTable.value = DivisionsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("divisions.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("divisions.form.add")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(GeneralDropdown, {
                  ref_key: "settingsDropdown",
                  ref: settingsDropdown
                }, {
                  button: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn ml-1 btn-gear"
                    }, [
                      _createElementVNode("i", { class: "bi bi-gear" })
                    ], -1)
                  ])),
                  body: _withCtx(() => [
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: openCustomFields
                        }, _toDisplayString(_ctx.$t("divisions.settings.customFields")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: openImportModal
                        }, _toDisplayString(_ctx.$t("divisions.settings.import")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: exportTable
                        }, _toDisplayString(_ctx.$t("divisions.settings.export")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: 
      action.value === 'customField'
        ? _ctx.$t('customFields.title')
        : action.value === 'create'
        ? _ctx.$t('divisions.form.createTitle')
        : action.value === 'edit'
        ? _ctx.$t('divisions.form.editTitle')
        : _ctx.$t('divisions.form.viewTitle')
    
    }, {
      headerComplement: _withCtx(() => [
        (action.value === 'view')
          ? (_openBlock(), _createBlock(ButtonField, {
              key: 0,
              "button-field": 
          _unref(editButton)(() => runAction('edit', tempRow.value, lastRowIndex.value))
        
            }, null, 8, ["button-field"]))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        (action.value === 'customField')
          ? (_openBlock(), _createBlock(CustomFields, {
              key: 0,
              ref_key: "form",
              ref: form
            }, null, 512))
          : _createCommentVNode("", true),
        (['create', 'edit', 'view'].includes(action.value))
          ? (_openBlock(), _createBlock(CreateOrUpdateDivision, {
              ref_key: "form",
              ref: form,
              "initial-values": tempRow.value,
              onHandleSubmit: handleSubmit,
              key: currentFormId.value,
              action: action.value as SimpleDivisionActionType
            }, null, 8, ["initial-values", "action"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslated.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef
    }, {
      default: _withCtx(() => [
        (tempRow.value?.id)
          ? (_openBlock(), _createBlock(DeleteDivision, {
              key: tempRow.value.id,
              "handle-submit": handleConfirm,
              "current-row": tempRow.value
            }, null, 8, ["current-row"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(GeneralModal, {
      modalId: "importExportModal",
      ref_key: "importModal",
      ref: importModal,
      modalName: _ctx.$t('divisions.modals.importModal'),
      "data-bs-backdrop": "false",
      class: "p-4",
      size: "modal-xl"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralImport, {
          key: importFormId.value,
          template: divisionTemplate.value,
          handleSubmit: () => importModal.value.closeModal()
        }, null, 8, ["template", "handleSubmit"]))
      ]),
      _: 1
    }, 8, ["modalName"]),
    (headerTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/divisions/",
          filters: tableFilters.value,
          headers: headerTable.value,
          name: "divisionsTable",
          ref_key: "tableRef",
          ref: tableRef
        }, null, 8, ["filters", "headers"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})