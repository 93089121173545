import { CustomFieldValues } from "@/store/customFields/interfaces/CustomFieldValues.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";

export class SupplierDto {
  id: number;
  name: string;
  code: string;
  supplierType: string;
  businessType: string;
  state: string;
  city: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  customFields?: CustomFieldValues;
  metadata?: any;
  division: SimpleDivision;
}
