<template>
  <div class="dropdown" ref="dropdown">
    <div @click="toggleDropdown">
      <slot name="button"></slot>
    </div>
    <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Dropdown } from "bootstrap";
import { defineExpose, onMounted, ref } from "vue";

const dropdown = ref();
const isOpen = ref(false);
let dropdownInstance;

function openDropdown() {
  dropdownInstance.show();
  isOpen.value = true;
}

function closeDropdown() {
  dropdownInstance.hide();
  isOpen.value = false;
}

function toggleDropdown() {
  if (isOpen.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

onMounted(() => {
  dropdownInstance = Dropdown.getOrCreateInstance(dropdown.value);
});

defineExpose({ openDropdown, closeDropdown, toggleDropdown });
</script>

<style lang="scss"></style>
