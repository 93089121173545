import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "container-fluid tableParentContainer" }
const _hoisted_2 = { class: "row justify-content-between align-items-center mt-3 mb-2" }
const _hoisted_3 = { class: "col-auto d-flex align-items-center" }
const _hoisted_4 = { class: "dropdown" }
const _hoisted_5 = {
  class: "results-button",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_6 = { class: "dropdown-menu" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "dropdown-item" }
const _hoisted_9 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_10 = { class: "container-fluid" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-auto p-0" }
const _hoisted_13 = { class: "col-auto p-0" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col tableContainer" }
const _hoisted_17 = { class: "w-100 table table-bordered table-hover styled-table" }
const _hoisted_18 = { class: "thead-light" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 0,
  class: "bi bi-arrow-up"
}
const _hoisted_22 = {
  key: 1,
  class: "bi bi-arrow-down"
}
const _hoisted_23 = {
  key: 0,
  style: {"display":"table-caption"},
  class: "container-fluid"
}
const _hoisted_24 = { class: "row justify-content-center" }
const _hoisted_25 = { class: "col-auto mt-3" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  key: 1,
  class: "cell-text d-flex flex-row gap-1 flex-wrap"
}
const _hoisted_31 = {
  key: 2,
  class: "cell-text"
}
const _hoisted_32 = {
  key: 3,
  class: "cell-text"
}
const _hoisted_33 = {
  key: 0,
  class: "d-flex flex-row gap-1 flex-wrap"
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 4,
  class: "cell-text"
}
const _hoisted_36 = ["href"]
const _hoisted_37 = {
  key: 9,
  class: "cell-text"
}
const _hoisted_38 = { class: "row justify-content-between align-items-center mt-3" }
const _hoisted_39 = { class: "col-auto" }
const _hoisted_40 = { class: "page-info" }
const _hoisted_41 = { class: "col-auto" }
const _hoisted_42 = { "aria-label": "pagination" }
const _hoisted_43 = { class: "pagination justify-content-center align-items-center" }
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { class: "page-item" }
const _hoisted_46 = ["disabled"]
const _hoisted_47 = { class: "page-item" }
const _hoisted_48 = { class: "page-link active-page bg-primary text-white" }
const _hoisted_49 = { class: "page-item" }
const _hoisted_50 = ["disabled"]
const _hoisted_51 = ["onClick"]

import { useLanguageServices } from "@/composables/useLanguageServices";
import { useTablesServices } from "@/composables/useTablesServices";
import { StatusInterface } from "@/shared/globals/helpers/Catalogs.helper";
import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { ResultsPerPageOptions } from "@/shared/globals/tables/consts/ResultsPerPage.const";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import {
  computed,
  onMounted,
  PropType,
  ref,
  useSlots,
  watch,
} from "vue";
import DropdownMenu from "../UiTools/DropdownMenu.vue";
import IsLoading from "../UiTools/IsLoading.vue";
import StatusBadge from "../UiTools/StatusBadge.vue";
import ProfileTag from "../profile/ProfileTag.vue";
import ColumnFilter from "./ColumnFilter.vue";

const showPagesCounter = 2;


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralTable',
  props: {
  headers: {
    type: Object as PropType<TableHeader[]>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  dataSource: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    default: "id",
  },
  orderType: {
    type: String as PropType<"ASC" | "DESC">,
    default: "DESC",
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {

const { lang } = useLanguageServices();
const slots = useSlots();
const busySlots = computed(() => {
  return Object.keys(slots);
});
const props = __props;

const headersRef = computed(() => props.headers);
const orderBy = ref(props.orderBy);
const orderType = ref(props.orderType);
const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  translatedHeaders,
  loadingTable,
  filters,
  currentData,
  applyFilters,
  updateRow,
  deleteRow,
  columnsFiltersApplied,
  removeFilter,
  addRow,
  addOrUpdateRow,
  allFilters,
} = useTablesServices(props.dataSource, headersRef, orderBy, orderType);

const resultsPerPageOptions = ResultsPerPageOptions;
const prevPagesCounter = computed(() => {
  const pages = currentPage.value - showPagesCounter;
  if (pages > 0) {
    return Math.min(pages, showPagesCounter);
  }
  return 0;
});

const nextPagesCounter = computed(() => {
  const pages = currentPage.value + showPagesCounter - 1;
  if (pages <= totalPages.value) {
    return Math.min(totalPages.value - pages, showPagesCounter);
  }
  return 0;
});

function changeSort(columnKey: string) {
  if (orderBy.value === columnKey) {
    orderType.value = orderType.value === "ASC" ? "DESC" : "ASC";
  } else {
    orderBy.value = columnKey;
    orderType.value = "ASC";
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) currentPage.value++;
}

function goPage(index) {
  currentPage.value = index;
}

function prevPage() {
  if (currentPage.value > 1) currentPage.value--;
}

function getTableRow(row: { [key: string]: unknown }) {
  return translatedHeaders.value.map((header) => {
    const cellData = row[header.key];

    if (header.columnType === "action") {
      return {
        value: { options: header.options },
        type: "action",
        metadata: header.metadata,
      };
    }

    return {
      value: cellData,
      type: header.columnType || "text",
      metadata: header.metadata,
    };
  });
}

watch(
  [orderBy, orderType, currentPage, filters],
  () => {
    getTableData();
  },
  { flush: "post" }
);

onMounted(() => {
  getTableData();
});

__expose({
  currentData,
  updateRow,
  deleteRow,
  addRow,
  addOrUpdateRow,
  allFilters,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("global.tables.results")), 1),
          _createElementVNode("button", _hoisted_5, [
            _createTextVNode(_toDisplayString(_unref(pageSize)) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-caret-down" }, null, -1))
          ]),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resultsPerPageOptions), (option) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `page-option-${option}`,
                onClick: ($event: any) => (pageSize.value = option)
              }, [
                _createElementVNode("a", _hoisted_8, _toDisplayString(option), 1)
              ], 8, _hoisted_7))
            }), 128))
          ])
        ])
      ]),
      (_unref(columnsFiltersApplied).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t("global.tables.filtersApplied")) + ":", 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columnsFiltersApplied), (filterApplied, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: 'filterApplied' + index,
                      class: "badge text-bg-primary ml-2"
                    }, [
                      _createTextVNode(_toDisplayString(filterApplied.value) + " ", 1),
                      _createElementVNode("span", {
                        onClick: ($event: any) => (_unref(removeFilter)(filterApplied.mappedKey)),
                        class: "badge removeFilter text-bg-secondary"
                      }, "✕ ", 8, _hoisted_14)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("table", _hoisted_17, [
          _createElementVNode("colgroup", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header, index) => {
              return (_openBlock(), _createElementBlock("col", {
                key: `col-${props.name}-${header.key}`,
                style: _normalizeStyle(`width: fit-content; max-width:${headersRef.value[index].width};`)
              }, null, 4))
            }), 128))
          ]),
          _createElementVNode("thead", _hoisted_18, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: _normalizeClass(["tableHeader text-center", { sortable: header.sortable }]),
                  key: `header-${props.name}-${header.key}`,
                  onClick: () => (header.sortable ? changeSort(header.key) : '')
                }, [
                  _createTextVNode(_toDisplayString(header?.metadata?.customFieldMetadata?.language?.name?.[
                    _unref(lang)
                  ] ?? header.value) + " ", 1),
                  (orderBy.value === header?.key)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                        (orderType.value === 'ASC')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                          : _createCommentVNode("", true),
                        (orderType.value === 'DESC')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_19))
              }), 128))
            ])
          ]),
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headersRef.value, (header) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: "tableFilter text-center",
                  key: `header-filter-${props.name}-${header.key}`
                }, [
                  true
                    ? (_openBlock(), _createBlock(ColumnFilter, {
                        key: 0,
                        columnHeader: header,
                        onApplyFilters: _unref(applyFilters)
                      }, null, 8, ["columnHeader", "onApplyFilters"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]),
          (_unref(loadingTable) || _unref(mappedData).length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    (_unref(loadingTable))
                      ? (_openBlock(), _createBlock(IsLoading, { key: 0 }))
                      : (_openBlock(), _createElementBlock("h5", _hoisted_26, _toDisplayString(_ctx.$t("global.tables.emptyData")), 1))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("tbody", _hoisted_27, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mappedData), (row, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `row-${props.name}-${index}`,
                    class: "tableRow"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getTableRow(row), (data, dataIndex) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: `cell-${props.name}-${index}-${dataIndex}`,
                        class: "text-center"
                      }, [
                        (__props.debug)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_unref(translatedHeaders)[dataIndex].key), 1))
                          : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, _unref(translatedHeaders)[dataIndex].key, {
                          index: index,
                          row: _unref(currentData)[index]
                        }),
                        (!busySlots.value.includes(_unref(translatedHeaders)[dataIndex].key))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "columnFormat",
                              style: _normalizeStyle(`${
                    data.type === 'action' ? 'overflow: visible !important' : ''
                  }`)
                            }, [
                              (data.type === 'text')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(data.value), 1))
                                : (
                      ['tag', 'select', 'multiple-select'].includes(data.type)
                    )
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, (val, index) => {
                                        return (_openBlock(), _createBlock(StatusBadge, {
                                          key: index,
                                          status: val
                                        }, null, 8, ["status"]))
                                      }), 128)),
                                      _createElementVNode("span", null, _toDisplayString((data.value as any[]).length === 0 ? "-" : ""), 1)
                                    ]))
                                  : (data.type === 'date')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_unref(formatDate)(data.value as Date)), 1))
                                    : (data.type === 'options')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, [
                                          (Array.isArray(data.value))
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, (v, index) => {
                                                  return (_openBlock(), _createBlock(StatusBadge, {
                                                    key: index,
                                                    status: {value:v,color:'white',bgColor:'#7451c2'} as StatusInterface
                                                  }, null, 8, ["status"]))
                                                }), 128))
                                              ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(data.value), 1))
                                        ]))
                                      : (
                      data.type === 'permission' && Array.isArray(data.value)
                    )
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(data.value.join(",")), 1))
                                        : (data.type === 'status')
                                          ? (_openBlock(), _createBlock(StatusBadge, {
                                              key: 5,
                                              status: data.value as StatusInterface
                                            }, null, 8, ["status"]))
                                          : (data.type === 'userTag')
                                            ? (_openBlock(), _createBlock(ProfileTag, {
                                                key: 6,
                                                userData: data.value as UserTag,
                                                showEmail: data.metadata?.showEmail
                                              }, null, 8, ["userData", "showEmail"]))
                                            : (data.type === 'action')
                                              ? (_openBlock(), _createBlock(DropdownMenu, {
                                                  key: 7,
                                                  menuData: (data.value.options(_unref(currentData)[index],index) as Menu),
                                                  name: `menu-${index}-${dataIndex}`
                                                }, null, 8, ["menuData", "name"]))
                                              : (data.type === 'location')
                                                ? (_openBlock(), _createElementBlock("a", {
                                                    key: 8,
                                                    href: `https://www.google.com/maps?q=${data.value[0]},${data.value[1]}`,
                                                    target: "_blank"
                                                  }, _toDisplayString((data.value as string[]).join(",")), 9, _hoisted_36))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(data.value), 1))
                            ], 4))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_38, [
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("global.tables.showingPage", { currentPage: _unref(currentPage), totalPages: _unref(totalPages) })), 1)
      ]),
      _createElementVNode("div", _hoisted_41, [
        _createElementVNode("nav", _hoisted_42, [
          _createElementVNode("ul", _hoisted_43, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prevPagesCounter.value, (i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `${i}`,
                class: "page-item"
              }, [
                _createElementVNode("span", {
                  class: "page-link",
                  onClick: ($event: any) => (goPage(_unref(currentPage) - prevPagesCounter.value + i - 2))
                }, _toDisplayString(_unref(currentPage) - prevPagesCounter.value + i - 2), 9, _hoisted_44)
              ]))
            }), 128)),
            _createElementVNode("li", _hoisted_45, [
              _createElementVNode("button", {
                class: "prev-next-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (prevPage())),
                disabled: _unref(currentPage) === 1
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "bi bi-arrow-left" }, null, -1)
              ]), 8, _hoisted_46)
            ]),
            _createElementVNode("li", _hoisted_47, [
              _createElementVNode("span", _hoisted_48, _toDisplayString(_unref(currentPage)), 1)
            ]),
            _createElementVNode("li", _hoisted_49, [
              _createElementVNode("button", {
                class: "prev-next-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage())),
                disabled: _unref(currentPage) === _unref(totalPages)
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "bi bi-arrow-right" }, null, -1)
              ]), 8, _hoisted_50)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nextPagesCounter.value, (j) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `${j}`,
                class: "page-item"
              }, [
                _createElementVNode("span", {
                  class: "page-link",
                  onClick: ($event: any) => (goPage(_unref(currentPage) + 1 + j))
                }, _toDisplayString(_unref(currentPage) + 1 + j), 9, _hoisted_51)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})