import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "modal-body" }

import { Modal } from "bootstrap";
import {
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from "vue";

type SizeType = "modal-sm" | "modal-lg" | "modal-xl" | "modal-fullscreen";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralModal',
  props: {
  modalId: { type: String, required: true },
  modalName: { type: String, required: true },
  size: { type: Object as PropType<SizeType>, required: false },
},
  emits: ["modalClosed"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const modalRef = ref();
const modalInstance = ref();

function modalClosed() {
  emit("modalClosed");
}

function closeModal() {
  modalInstance.value?.hide();
}

function openModal() {
  modalInstance.value?.show();
}

onMounted(() => {
  modalInstance.value = Modal.getOrCreateInstance(modalRef.value);
});

onBeforeUnmount(() => {
  modalInstance.value.dispose();
});

__expose({
  openModal,
  closeModal,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: props.modalId,
    tabindex: "-1",
    "aria-labelledby": `${props.modalId}Label`,
    ref_key: "modalRef",
    ref: modalRef
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`modal-dialog modal-dialog-centered ${__props.size ?? ''}`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", {
            class: "modal-title",
            id: `${props.modalId}Label`
          }, _toDisplayString(props.modalName), 9, _hoisted_4),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
            onClick: modalClosed
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 2)
  ], 8, _hoisted_1))
}
}

})