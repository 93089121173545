import { t } from "@/shared/locales/services/i18n.services";
import { TechnicalSheetDto } from "@/store/technical-sheets/dtos/input/TechnicalSheet.dto";
import { MaterialType } from "@/store/technical-sheets/enums/MaterialType.enum";
import { TechnicalSheetForm } from "@/store/technical-sheets/interfaces/TechnicalSheetForm.interface";
import { get } from "lodash";

export function TechnicalSheetDtoToTechnicalSheetForm(
  technicalSheet: TechnicalSheetDto
): TechnicalSheetForm {
  const productId = get(technicalSheet, "product.id");
  const customFields = get(technicalSheet, "customFields");
  const manufactureSpecification = {
    images: get(
      technicalSheet,
      "product.manufactureSpecification.images",
      []
    ).map((url: string) => {
      return { url };
    }),
    details: get(
      technicalSheet,
      "product.manufactureSpecification.details",
      []
    ).reduce<{ [key: string]: string }>(
      (acc, detail: string, index: number) => {
        return { ...acc, [`detail${index}`]: detail };
      },
      {}
    ),
  };
  const descriptiveImages = get(
    technicalSheet,
    "product.descriptiveImages",
    []
  ).map((url: string) => {
    return { url };
  });
  const materialType: MaterialType = get(
    technicalSheet,
    "product.materialType"
  );
  const materialTypeOption = materialType
    ? {
        id: materialType,
        label: t(`technicalSheets.type.${materialType}`),
        item: materialType,
        items: [materialType],
      }
    : null;
  const instructions = {
    description: get(technicalSheet, "product.instructions.description", ""),
    images: get(technicalSheet, "product.instructions.images", []).map(
      (url: string) => {
        return { url };
      }
    ),
  };
  const qualityCriteria = {
    orders: get(
      technicalSheet,
      "product.qualityCriteria.qualityCriteria",
      []
    ).reduce<{ [key: string]: string }>((acc, { order }, index) => {
      return { ...acc, [`order${index}`]: order };
    }, {}),
    descriptions: get(
      technicalSheet,
      "product.qualityCriteria.qualityCriteria",
      []
    ).reduce<{ [key: string]: string }>((acc, { description }, index) => {
      return { ...acc, [`description${index}`]: description };
    }, {}),
    observations: get(technicalSheet, "product.qualityCriteria.observations"),
  };
  return {
    technicalSheet: {
      productId,
      manufactureSpecification,
      qualityCriteria,
      descriptiveImages,
      ...(materialTypeOption ? { materialType: materialTypeOption } : {}),
      instructions,
      customFields,
    },
  };
}
