import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { MeasureOption } from "@/store/measures/interfaces/MeasureOption.interface";
import { SimpleMeasure } from "@/store/measures/interfaces/SimpleMeasure.interface";
import { ProductFamilyOption } from "@/store/product-families/interfaces/ProductFamilyOption.interface";
import { SimpleProductFamily } from "@/store/product-families/interfaces/SimpleProductFamily.interface";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { ProductForm } from "@/store/products/interfaces/ProductForm.interface";
import { get } from "lodash";

export const productDtoToProductForm = (product: ProductDto): ProductForm => {
  const id = get(product, "id", false);
  const name = get(product, "name");
  const description = get(product, "description");
  const price = get(product, "price");
  const measure = formatOption<SimpleMeasure, MeasureOption>(
    get(product, "measure"),
    ["id"],
    ["description"]
  );
  const productFamily = formatOption<SimpleProductFamily, ProductFamilyOption>(
    get(product, "productFamily"),
    ["id"],
    ["name"]
  );
  const customFields = get(product, "customFields");
  return {
    product: {
      ...(id === false ? {} : { id }),
      name,
      description,
      price,
      measure,
      productFamily,
      customFields,
    },
  };
};
