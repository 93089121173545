<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("technicalSheets.entity.rawMaterial") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn ml-1 btn-gear"
        type="button"
        @click="handleAddRawMaterial"
      >
        {{ $t("technicalSheets.form.rawMaterial.add") }}
      </button>
    </div>
    <div class="col-12">
      <BTable
        v-bind="{ items: allRawMaterials, fields: rawMaterialsFields, showEmpty: true } as any"
      >
        <template #cell(actions)="row">
          <DropdownMenu
            :menuData="getOptions(row.index)"
            :name="`menu-${row.index}-${row.key}`"
          />
        </template>
        <template #empty>
          <h5 class="text-center">
            {{ $t("technicalSheets.tables.rawMaterials.isEmpty") }}
          </h5>
        </template>
      </BTable>
    </div>
  </div>
  <GeneralModal
    ref="modal"
    modalId="translateModal"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="rawMaterialForm"
      :form="rawMaterialFormTemplateRef"
      form-name="translateForm"
      :key="formId"
      :initialValues="currentRawMaterial"
    ></GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import {
  TechnicalSheetDto,
  TechnicalSheetRawMaterial,
} from "@/store/technical-sheets/dtos/input/TechnicalSheet.dto";
import { RawMaterialQuantityForm } from "@/store/technical-sheets/interfaces/RawMaterialQuantityForm.interface";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { cloneDeep, get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import DropdownMenu from "../globals/UiTools/DropdownMenu.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";

const { t } = useI18n();

const props = defineProps({
  technicalSheet: {
    type: Object as PropType<TechnicalSheetDto>,
    required: false,
  },
});

const technicalSheetRef: Ref<TechnicalSheetDto> = toRef(
  props,
  "technicalSheet"
);

let currentRawMaterialIndex = ref(-1);
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();
const formId = ref();
const allRawMaterials: Ref<
  Array<TechnicalSheetRawMaterial | RawMaterialQuantityForm>
> = ref(cloneDeep(get(technicalSheetRef, "value.rawMaterials", [])));

const currentRawMaterial = computed(() => {
  return cloneDeep(
    get(allRawMaterials, `value.${currentRawMaterialIndex.value}`)
  );
});

const emit = defineEmits(["update:modelValue"]);

async function handleAddRawMaterial() {
  currentRawMaterialIndex.value = allRawMaterials.value.length;
  await handleAddOrEditRawMaterial(currentRawMaterialIndex.value);
}

const excludeOptions = computed(() => {
  return allRawMaterials.value.map(({ rawMaterial }) => {
    return rawMaterial;
  });
});

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  allRawMaterials.value[currentRawMaterialIndex.value] = cloneDeep(values);
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = allRawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  rawMaterialFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  formId.value = uuidv4();
  modal.value.openModal();
  currentRawMaterialIndex.value = index;
}

function resetForms() {
  allRawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

function getOptions(index: number): Menu {
  const options = [
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.edit",
        needsTranslate: true,
      },
      id: 1,
      action: () => handleAddOrEditRawMaterial(index),
    },
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.delete",
        needsTranslate: true,
      },
      id: 2,
      action: () => allRawMaterials.value.splice(index, 1),
    },
  ];
  return {
    options,
  };
}

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (
      value,
      key,
      { rawMaterial }: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("label" in rawMaterial) {
        return rawMaterial.label;
      }
      return rawMaterial.description;
    },
  },
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.quantity;
      }
      return item.quantity;
    },
  },

  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.thickness;
      } else if ("thickness" in item.rawMaterial.item) {
        return item.rawMaterial.item.thickness;
      }
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.width;
      } else if ("width" in item.rawMaterial.item) {
        return item.rawMaterial.item.width;
      }
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.length;
      } else if ("length" in item.rawMaterial.item) {
        return item.rawMaterial.item.length;
      }
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.volume;
      } else if ("volume" in item.rawMaterial.item) {
        return item.rawMaterial.item.volume;
      }
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      let volume;
      if ("id" in item) {
        volume = item.rawMaterial.volume;
      } else {
        volume = item.rawMaterial.item.volume;
      }
      return volume * item.quantity;
    },
  },
  {
    key: "actions",
    sortable: false,
    label: t("users.table.actions"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

watch(
  allRawMaterials,
  () => {
    const rawMaterials = allRawMaterials.value.map(
      ({ rawMaterial, quantity }) => {
        return {
          id: rawMaterial.id,
          quantity: quantity,
        };
      }
    );
    emit("update:modelValue", rawMaterials);
  },
  { deep: true, immediate: true }
);

defineExpose({ resetForms });
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}
</style>
