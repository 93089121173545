import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { CreateProfileDto } from "@/store/profiles/dtos/output/CreateProfile.dto";
import { DeleteProfileDto } from "@/store/profiles/dtos/output/DeleteProfile.dto";
import { UpdateProfileDto } from "@/store/profiles/dtos/output/UpdateProfile.dto";
import {
  CreateProfile,
  DeleteProfile,
  UpdateProfile,
} from "@/store/profiles/services/Profiles.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useProfilesServices() {
  const { t } = useI18n();
  const alias = "profiles";

  const createProfile = async (
    payload: CreateProfileDto,
    callBackCreate: (profile: ProfileDto) => void
  ): Promise<void> => {
    await CreateProfile(payload)
      .then((profile: ProfileDto) => {
        callBackCreate(profile);
        useToast().successToast(t("profiles.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateProfile = async (
    payload: UpdateProfileDto,
    callBackUpdate: (profile: ProfileDto) => void
  ): Promise<void> => {
    await UpdateProfile(payload)
      .then((profile: ProfileDto) => {
        callBackUpdate(profile);
        useToast().successToast(t("profiles.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteProfile = async (
    payload: DeleteProfileDto,
    callBack: () => void
  ): Promise<void> => {
    await DeleteProfile(payload)
      .then(() => {
        callBack();
        useToast().successToast(t("profiles.success.deletedAndReplaced"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createProfile,
    updateProfile,
    deleteProfile,
  };
}
