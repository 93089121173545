<template>
  <AuthCard ref="card">
    <template v-slot:content>
      <div class="row justify-content-center">
        <div class="col-12 d-flex align-items-center">
          <h2 class="textBlack">
            {{ $t("auth.carouselTabs.forgotPassword.primaryTitle") }}
          </h2>
        </div>
      </div>
      <div class="row justify-content-between mt-2 mb-1">
        <div class="col-auto">
          <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
          <span class="actionableLabel" @click="goToLogin">{{
            $t("auth.carouselTabs.forgotPassword.hrefs.signUp")
          }}</span>
        </div>
        <div class="col-auto">
          <span
            v-if="isFirstStep"
            class="actionableLabel"
            @click="changeFormTemplate"
            >{{
              $t("auth.carouselTabs.forgotPassword.hrefs.haveACodeYet")
            }}</span
          >
          <span v-else class="actionableLabel" @click="changeFormTemplate">
            {{ $t("auth.carouselTabs.forgotPassword.hrefs.notHaveACodeYet") }}
          </span>
        </div>
      </div>
      <div class="row">
        <GeneralForm
          ref="formRef"
          :form="formTemplate"
          formName="forgotPassword"
        ></GeneralForm>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { AuthTypes } from "@/store/auth/types/Credentials.type";
import { get } from "lodash";
import {
  computed,
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { ForgotPasswordStep1Template } from "./templates/forms/ForgotPasswordStep1.template";
import { ForgotPasswordStep2Template } from "./templates/forms/ForgotPasswordStep2.template";
const { forgotPassword, confirmForgotPassword } = useAuthServices();

const emit = defineEmits(["goToSlide", "update:modelValue"]);
const props = defineProps({
  initialValues: {
    type: Object as PropType<AuthTypes>,
    required: true,
  },
});

const initialValuesRef = toRef(props, "initialValues");
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formTemplate = ref();
const card = ref();
const cardIsObserved = ref(false);

function changeFormTemplate(): void {
  isFirstStep.value = !isFirstStep.value;
}

function goToLogin(): void {
  isFirstStep.value = true;
  emit("goToSlide", 1);
}

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    await forgotPassword({ email }, changeFormTemplate);
  } else {
    confirmForgotPassword({ email, newPassword, confirmationCode }, goToLogin);
  }
}

const email = computed(() => {
  const initialEmail = get(initialValuesRef, "value.email", "");
  const email = get(formRef, "value.values.email", "");
  if (initialEmail !== email) {
    emit("update:modelValue", { email });
  }
  return email;
});

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      cardIsObserved.value = true;
    } else {
      cardIsObserved.value = false;
    }
  });
});

watch(
  isFirstStep,
  () => {
    formTemplate.value = isFirstStep.value
      ? ForgotPasswordStep1Template(submitForgotPassword)
      : ForgotPasswordStep2Template(submitForgotPassword);
  },
  { deep: true, immediate: true }
);

watch(
  cardIsObserved,
  () => {
    if (cardIsObserved.value) {
      isFirstStep.value = true;
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  observer.observe(card.value.$el);

  watch(
    [initialValuesRef, isFirstStep, formTemplate],
    () => {
      formRef.value.setValues(props.initialValues);
    },
    { deep: true, immediate: true }
  );
});
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnForgotPassword {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
