import axiosInstance from "@/middlewares/Routing.middleware";
import { MeasureDto } from "../dtos/input/Measure.dto";
import { CreateMeasureDto } from "../dtos/output/CreateMeasure.dto";
import { DeleteMeasureDto } from "../dtos/output/DeleteMeasure.dto";
import { UpdateMeasureDto } from "../dtos/output/UpdateMeasure.dto";

export async function CreateMeasure(
  payload: CreateMeasureDto
): Promise<MeasureDto> {
  return (await axiosInstance.post(`measures`, payload)).data;
}

export async function UpdateMeasure(
  payload: UpdateMeasureDto
): Promise<MeasureDto> {
  const { id, ...measureProps } = payload;
  return (await axiosInstance.put(`measures/${id}`, measureProps)).data;
}

export async function DeleteMeasure(payload: DeleteMeasureDto): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`measures?newId=${newId}&oldId=${oldId}`);
}
