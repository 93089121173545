import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { DivisionOption } from "@/store/divisions/interfaces/DivisionOption.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";
import { PositionDto } from "@/store/positions/dtos/input/Position.dto";
import { PositionForm } from "@/store/positions/interfaces/PositionForm.interface";
import { get } from "lodash";

export function PositionDtoToPositionForm(position: PositionDto): PositionForm {
  const id = get(position, "id", false);
  const name = get(position, "name");
  const description = get(position, "description");
  const baseSalary = get(position, "baseSalary");
  const division = formatOption<SimpleDivision, DivisionOption>(
    get(position, "division"),
    ["id"],
    ["name"]
  );
  const customFields = get(position, "customFields");
  return {
    position: {
      ...(id === false ? {} : { id }),
      name,
      description,
      baseSalary,
      division,
      customFields,
    },
  };
}
