import { CreateProductFamilyDto } from "@/store/product-families/dtos/output/CreateProductFamily.dto";
import { UpdateProductFamilyDto } from "@/store/product-families/dtos/output/UpdateProductFamily.dto";
import { ProductFamilyForm } from "@/store/product-families/interfaces/ProductFamilyForm.interface";
import { get } from "lodash";

export function ProductFamilyFormToProductFamilyDto(
  productFamily: ProductFamilyForm
): CreateProductFamilyDto | UpdateProductFamilyDto {
  const id = get(productFamily, "productFamily.id", false);
  const name = get(productFamily, "productFamily.name");
  const description = get(productFamily, "productFamily.description");
  const customFields = get(productFamily, "productFamily.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    description,
    customFields,
  };
}
