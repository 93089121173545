import { DispatchInventoryDto } from "@/store/inventories/dtos/output/DispatchInventory.dto";
import { InsertInventoryDto } from "@/store/inventories/dtos/output/InsertInventory.dto";
import { UpdateInventoryDto } from "@/store/inventories/dtos/output/UpdateInventory.dto";
import { InventoryForm } from "@/store/inventories/interfaces/InventoryForm.interface";
import { get } from "lodash";

export const InventoryFormToInventoryDto = (
  inventory: InventoryForm
): InsertInventoryDto | DispatchInventoryDto | UpdateInventoryDto => {
  const id = get(inventory, "inventory.id", false);
  const store = { id: get(inventory, "inventory.store.id") };
  const element0 = get(inventory, "inventory.elements.element0", {});
  const elementId = get(element0, "id.id");
  const lotCode = get(element0, "lotCode");
  const quantity = get(element0, "quantity");
  const expirationDate = get(element0, "expirationDate");
  const customFields = get(element0, "customFields", {});
  return {
    ...(id === false ? {} : { id }),
    store,
    elements: [
      {
        id: elementId,
        lotCode,
        quantity,
        expirationDate,
        customFields,
      },
    ],
  };
};
