import axiosInstance from "@/middlewares/Routing.middleware";
import { StoreDto } from "../dtos/input/Store.dto";
import { CreateStoreDto } from "../dtos/output/CreateStore.dto";
import { DeleteStoreDto } from "../dtos/output/DeleteStore.dto";
import { UpdateStoreDto } from "../dtos/output/UpdateStore.dto";

export async function CreateStore(payload: CreateStoreDto): Promise<StoreDto> {
  return (await axiosInstance.post(`stores`, payload)).data;
}

export async function UpdateStore(payload: UpdateStoreDto): Promise<StoreDto> {
  const { id, ...storeProps } = payload;
  return (await axiosInstance.put(`stores/${id}`, storeProps)).data;
}

export async function DeleteStore(payload: DeleteStoreDto): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`stores?newId=${newId}&oldId=${oldId}`);
}
