<template>
  <AuthCard>
    <template v-slot:content>
      <div class="row">
        <div class="col-12 mb-2">
          <h2 class="fs-2">
            {{ $t("auth.carouselTabs.signUp.primaryTitle") }}
          </h2>
          <p class="title">
            {{ $t("auth.carouselTabs.signUp.secondaryTitle") }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <GeneralForm ref="formRef" :form="formTemplate" formName="loginUser">
            <template #section-0-field-1>
              <div class="row g-2 justify-content-between">
                <div class="col-auto">
                  <span
                    class="actionableLabel"
                    @click="goToForgotPassword"
                    data-bs-target="#carouselAuthViews"
                    data-bs-slide-to="1"
                    >{{
                      $t("auth.carouselTabs.signUp.hrefs.recoveryPassword")
                    }}</span
                  >
                </div>
              </div>
            </template>
          </GeneralForm>
        </div>
      </div>
      <div class="row" id="anotherOptionsDivider">
        <div class="col pr-1">
          <hr />
        </div>
        <div class="col-auto p-0 dividerText d-flex align-items-center">
          <p class="m-0 fs-6">
            {{ $t("auth.carouselTabs.signUp.anotherAccessOptions") }}
          </p>
        </div>
        <div class="col pl-1">
          <hr />
        </div>
      </div>
      <div class="row justify-content-around pt-2 pb-3" id="anotherOption">
        <div class="col">
          <button class="google-button btn extraOptionButton p-0 w-100 p-2">
            <img
              class="bg-transparent"
              src="images/icon/brands/icon-google.svg"
              width="30px"
              alt="googleLogo"
            />
          </button>
        </div>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { AuthTypes } from "@/store/auth/types/Credentials.type";
import { get } from "lodash";
import {
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { LoginUserTemplate } from "./templates/forms/loginUser.template";

const { login } = useAuthServices();

const emit = defineEmits([
  "goToSlide",
  "emitTemporalToken",
  "update:modelValue",
]);

const props = defineProps({
  initialValues: {
    type: Object as PropType<AuthTypes>,
    required: true,
  },
});

const initialValuesRef = toRef(props, "initialValues");
const formRef = ref();
const formTemplate = ref(LoginUserTemplate(submitLogin));

async function submitLogin(): Promise<void> {
  const { values } = formRef.value;
  await login(values, goToForgotPassword, goToChangePassword);
}

function goToForgotPassword(): void {
  const email = get(formRef, "value.values.email");
  emit("update:modelValue", { email });
  formRef.value.resetForm();
  emit("goToSlide", 0);
}

function goToChangePassword(token: string): void {
  const { email, password: oldPassword } = get(formRef, "value.values");
  emit("update:modelValue", { email, oldPassword });
  formRef.value.resetForm();
  emit("emitTemporalToken", token);
  emit("goToSlide", 2);
}

onMounted(() => {
  watch(
    initialValuesRef,
    () => {
      formRef.value.setValues(props.initialValues);
    },
    { deep: true, immediate: true }
  );
});
</script>

<style lang="scss" scoped>
.createAccount {
  font-size: 14px;
}

.title {
  font-size: 15px;
}

.google-button {
  background-color: #dae2e9;
}

.link {
  color: #dbc7a2;
}

.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);

  span {
    width: $labelWith;
    text-align: start;
  }

  input {
    width: $inputWith;
  }
}

#anotherOptionsDivider {
  hr {
    color: rgb(71, 71, 71);
  }

  font-size: 0.6em;
  color: rgb(0, 0, 0);
}

#anotherOption {
  button {
    &:hover {
      background-color: $FourthColor;
    }
  }
}

.extraOptionButton {
  &:hover {
    background-color: rgb(206, 206, 206) !important;
  }
}
</style>
