import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

const { langValues } = useLanguageServices();

export const TranslateTemplate = (
  action,
  path,
  type: "text" | "textarea"
): Form => {
  return {
    sections: [
      {
        fields: langValues.value.map((language) => {
          return {
            path: `${path}.${language.key}`,
            inputName: `${language.key}`,
            label: {
              value: `${language.name}`,
              needsTranslate: false,
            },
            type,
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          };
        }),
      },
    ],
    submit: {
      text: {
        value: "global.buttons.save",
        needsTranslate: true,
      },
      action,
    },
  };
};
