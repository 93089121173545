import { useLanguageServices } from "@/composables/useLanguageServices";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { CatalogOption } from "@/store/catalogs/interfaces/CatalogOption.interface";
import { SimpleCatalog } from "@/store/catalogs/interfaces/SimpleCatalog.interface";
import { DivisionOption } from "@/store/divisions/interfaces/DivisionOption.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";
import { WorkplaceDto } from "@/store/workplaces/dtos/input/Workplace.dto";
import { WorkplaceFormInterface } from "@/store/workplaces/interfaces/WorkplaceForm.interface";
import { get } from "lodash";

export function WorkplaceDtoToWorkplaceForm(
  workplace: WorkplaceDto
): WorkplaceFormInterface {
  const { lang } = useLanguageServices();
  const id = get(workplace, "id", false);
  const name = get(workplace, "name");
  const description = get(workplace, "description");
  const status = formatOption<SimpleCatalog, CatalogOption>(
    get(workplace, "status"),
    ["id"],
    [`catalogMetadata.name.language.[${lang.value}]`, "name"]
  );
  const division = formatOption<SimpleDivision, DivisionOption>(
    get(workplace, "division"),
    ["id"],
    ["name"]
  );
  const customFields = get(workplace, "customFields");
  return {
    workplace: {
      ...(id === false ? {} : { id }),
      name,
      description,
      status,
      division,
      customFields,
    },
  };
}
