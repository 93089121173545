import { SimplePermission } from "@/store/permissions/interfaces/SimplePermission.interface";
import { ProfileMetadata } from "../../interfaces/ProfileMetadata.interface";

export class ProfileDto {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  permissions: SimplePermission[];
  metadata: ProfileMetadata;
}
