import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_6 = { class: "m-0 fs-5" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = {
  key: 0,
  class: "col-auto p-0"
}
const _hoisted_9 = {
  key: 1,
  class: "col-auto pl-0"
}
const _hoisted_10 = { class: "col-12 text-end" }
const _hoisted_11 = {
  key: 0,
  class: "col-12 d-flex justify-content-center"
}
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "text-end" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useOrdersServices } from "@/composables/useOrdersServices";
import { getSubtotal } from "@/shared/orders/helpers/GetSubtotal.helper";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { OrderDto } from "@/store/orders/dtos/input/Order.dto";
import { DiscountTypes } from "@/store/orders/enums/DiscountTypes.enum";
import { OrderDetailForm } from "@/store/orders/interfaces/OrderDetailForm.interface";
import { SimpleOrderActionType } from "@/views/orders/types/SimpleOrderAction.type";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { DetailsDtoToDetailsForm } from "./formatters/DetailsDtoToDetailsForm.formatter";
import { DetailsFormsToDetailsDtos } from "./formatters/DetailsFormsToDetailsDtos.formatter";
import OrderDetail from "./OrderDetail.vue";
import { CreateOrUpdateDetailTemplate } from "./templates/forms/CreateOrUpdateDetail.template";
import { CreateOrUpdateOrderTemplate } from "./templates/forms/CreateOrUpdateOrder.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateOrder',
  props: {
  initialValues: {
    type: Object as PropType<OrderDto>,
    required: false,
  },
  isCreating: {
    type: Boolean,
    required: true,
  },
  action: {
    type: String as PropType<SimpleOrderActionType>,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createOrder, updateOrder } = useOrdersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const { t } = useI18n();

const props = __props;

const initialValuesRef: Ref<OrderDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const detailForm = ref();
const detailFormTemplateRef = ref();
const details: Ref<Array<OrderDetailForm>> = ref();
const currentDetailIndex = ref(-1);
const addButtonTemplate = ref(
  addButton(async () => {
    currentDetailIndex.value = details.value.length;
    await handleAddOrEditDetail(currentDetailIndex.value);
  })
);
const detailFormId = ref();

const emit = __emit;

const total = computed(() => {
  return details.value
    .map(({ detail }) => {
      const discountType = get(detail, "discountType.id", DiscountTypes.AMOUNT);
      const discount = get(detail, "discount", 0);
      return getSubtotal(
        detail.quantity,
        Number(detail.product.item.price),
        discountType,
        discount
      );
    })
    .reduce((accumulator, current) => {
      accumulator = accumulator + current;
      return accumulator;
    }, 0);
});

const subtotal = computed(() => {
  const detail = get(detailForm.value, "values.detail", {});
  const discountType = get(detail, "discountType.id", DiscountTypes.AMOUNT);
  const discount = get(detail, "discount", 0);
  if (detail?.quantity && detail?.product) {
    return getSubtotal(
      detail.quantity,
      Number(detail.product.item.price),
      discountType,
      discount
    );
  }
  return 0;
});

async function handleSubmitDetail() {
  const { values } = detailForm.value;
  details.value[currentDetailIndex.value] = merge(
    {},
    details.value[currentDetailIndex.value] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditDetail(detailIndex: number) {
  const isCreating = details.value.length <= detailIndex;
  modalTitle.value = isCreating
    ? t("orderDetail.form.createTitle")
    : t("orderDetail.form.editTitle");
  detailFormTemplateRef.value = CreateOrUpdateDetailTemplate(
    handleSubmitDetail,
    props.action
  );
  currentDetailIndex.value = detailIndex;
  detailFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(order: OrderDto) {
  emit("handleSubmit", { order, isCreating: true });
}

function callBackEdit(order: OrderDto) {
  emit("handleSubmit", {
    order,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const order = merge({}, valuesFormatted, {
    details: DetailsFormsToDetailsDtos(details.value),
  });
  if ("id" in order) {
    await updateOrder(order, callBackEdit);
  } else {
    await createOrder(order, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  detailForm.value?.resetForm();
  details.value = [];
  modal.value.closeModal();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [initialValuesRef, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateOrderTemplate(
          customFields.value,
          handleSubmit,
          props.action,
          catalogs.value
        );
      }
    },
    { deep: true, immediate: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
  get(initialValuesRef.value, "details", []).forEach((detail) => {
    console.log(detail);
    const price = get(detail, "data.price", detail.product.price);
    detail.product.price = price;
    console.log("Seteando precio: ", price);
  });
  details.value = DetailsDtoToDetailsForm(initialValuesRef.value);
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formTemplateRef.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "form",
              ref: form,
              form: formTemplateRef.value,
              key: JSON.stringify(props.initialValues),
              "form-name": "orderForm",
              initialValues: initialValuesRef.value,
              "is-view": __props.action === 'view'
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
                    _createElementVNode("hr")
                  ], -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("b", _hoisted_6, _toDisplayString(_unref(t)("orderDetail.title")), 1)
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
                    _createElementVNode("hr")
                  ], -1))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(details.value, (detail, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row mt-3",
                    key: `detail${index}`
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(OrderDetail, {
                        detail: detail.detail
                      }, null, 8, ["detail"])
                    ]),
                    (__props.action !== 'view')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(ButtonField, {
                            "button-field": 
                  _unref(editButton)(() => {
                    handleAddOrEditDetail(index);
                  })
                
                          }, null, 8, ["button-field"])
                        ]))
                      : _createCommentVNode("", true),
                    (__props.action !== 'view')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(ButtonField, {
                            "button-field": 
                  _unref(deleteButton)(() => {
                    details.value.splice(index, 1);
                  })
                
                          }, null, 8, ["button-field"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h4", null, "Total: " + _toDisplayString(total.value), 1)
                ]),
                (__props.action !== 'view')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(ButtonField, { buttonField: addButtonTemplate.value }, null, 8, ["buttonField"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["form", "initialValues", "is-view"]))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(GeneralModal, {
              ref_key: "modal",
              ref: modal,
              modalId: "translateModal",
              modalName: modalTitle.value,
              "data-bs-backdrop": "false",
              class: "p-4",
              initialValues: initialValuesRef.value
            }, {
              default: _withCtx(() => [
                (currentDetailIndex.value > -1)
                  ? (_openBlock(), _createBlock(GeneralForm, {
                      ref_key: "detailForm",
                      ref: detailForm,
                      form: detailFormTemplateRef.value,
                      "form-name": "translateForm",
                      key: detailFormId.value,
                      initialValues: details.value[currentDetailIndex.value],
                      "is-view": __props.action === 'view'
                    }, {
                      "section-0-field-3": _withCtx(() => [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-2" }, null, -1)),
                        _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.$t("orderDetail.entity.subtotal")) + " " + _toDisplayString(subtotal.value), 1)
                      ]),
                      _: 1
                    }, 8, ["form", "initialValues", "is-view"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modalName", "initialValues"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})