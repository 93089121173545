import { createButton } from "@/components/globals/buttons/templates/CreateButton.tempalte";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isEmailYup,
  isNotRequired,
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { UserDtoToUserForm } from "../../formatters/UserDtoToUserForm.formatter";
import { UserFormToUserDto } from "../../formatters/UserFormToUserDto.formatter";

export const CreateOrUpdateUserTemplate = (
  customFields: CustomFieldDto[],
  catalogs: CatalogClass[],
  actionType?: "create" | "edit" | "view",
  action?: () => Promise<void>,
  openPositionOffcanvas?: () => void
): Form => {
  const { lang } = useLanguageServices();
  const catalog =
    catalogs.find((catalog) => {
      return catalog.key === CatalogClassesKeys.USERS_STATUS_CLASS;
    })?.catalogs ?? [];
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "user.name",
            inputName: "userName",
            label: {
              value: "users.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup(), minCharsYup(8)];
            },
          },
          {
            path: "user.email",
            inputName: "userEmail",
            label: {
              value: "users.entity.email",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup(), isEmailYup()];
            },
          },
          {
            path: "user.profile",
            inputName: "userProfile",
            label: {
              value: "users.entity.profile",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              dataSource: "/profiles/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
          {
            path: "user.status",
            inputName: "userStatus",
            label: {
              value: "users.entity.status",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "id",
              labelKey: [`catalogMetadata.language.[${lang.value}]`, "name"],
              isMultiple: false,
              initialOptions: catalog,
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
          {
            path: "user.mainPosition",
            inputName: "userMainPosition",
            label: {
              value: "users.entity.mainPosition",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/positions/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
            ...(actionType === "view"
              ? {}
              : {
                  buttons: [createButton(openPositionOffcanvas)],
                }),
          },
          {
            path: "user.positions",
            inputName: "userPositions",
            label: {
              value: "users.entity.positions",
              needsTranslate: true,
            },
            type: "multiple-select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/positions/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: true,
              searchPath: "name",
            },
            methodsYup: () => {
              return [stringYup, isNotRequired()];
            },
            ...(actionType === "view"
              ? {}
              : { buttons: [createButton(openPositionOffcanvas)] }),
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: actionType === "create" ? "users.form.add" : "users.form.update",
        needsTranslate: true,
      },
      action,
    },
    formatterDtoToForm: UserDtoToUserForm,
    formatterFormToDto: UserFormToUserDto,
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "user", "user"),
    });
  }

  return form;
};
