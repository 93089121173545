import { Module } from "vuex";
import { CustomFieldDto } from "../customFields/dtos/input/CustomField.dto";
import { Module as ModuleInterface } from "./interfaces/Module.interface";

interface State {
  [key: string]: ModuleInterface;
}

export const modulesStore: Module<State, unknown> = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    setCustomFields(state, module: ModuleInterface) {
      state[module.name] = module;
    },
    addCustomField(
      state,
      {
        moduleName,
        customField,
      }: { moduleName: string; customField: CustomFieldDto }
    ) {
      state[moduleName].customFields.push(customField);
    },
    updateCustomField(
      state,
      {
        moduleName,
        customField,
      }: { moduleName: string; customField: CustomFieldDto }
    ) {
      const customFieldIndex = state[moduleName].customFields.findIndex(
        (field) => field.id === customField.id
      );
      if (customFieldIndex !== -1) {
        state[moduleName].customFields[customFieldIndex] = customField;
      }
    },
    deleteCustomField(
      state,
      { id, moduleName }: { id: string; moduleName: string }
    ) {
      state[moduleName].customFields = state[moduleName].customFields.filter(
        (customField) => customField.id !== id
      );
    },
  },
  actions: {},
  modules: {},
};
