import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "my-3" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import { SimpleDivisionActionType } from "@/views/divisions/DivisionsView.vue";
import {
  computed,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import MapMaster from "../globals/inputs/MapMaster.vue";
import { CreateOrUpdateDivisionTemplate } from "./templates/forms/CreateOrUpdateDivision.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateDivision',
  props: {
  initialValues: { type: Object as PropType<DivisionDto>, required: false },
  action: {
    type: String as PropType<SimpleDivisionActionType>,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createDivision, updateDivision } = useDivisionsServices();

const props = __props;

const initialValuesRef: Ref<DivisionDto> = ref();
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = __emit;

const coordinates = computed(() => {
  const [latitude, longitude] = form?.value?.values?.division?.location?.split(
    ","
  ) ?? [19.432608, -99.133209];
  return {
    latitude: (!isNaN(latitude) && Number(latitude)) || 19.432608,
    longitude: (!isNaN(longitude) && Number(longitude)) || -99.133209,
  };
});

function callBackCreate(division: DivisionDto) {
  emit("handleSubmit", { division, isCreating: true });
}

function callBackEdit(division: DivisionDto) {
  emit("handleSubmit", {
    division,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted: division } = form.value;
  if (!initialValuesRef.value) {
    await createDivision(division, callBackCreate);
  } else {
    await updateDivision(division, callBackEdit);
  }
}

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateDivisionTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value
    );
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "divisionForm",
          "initial-values": initialValuesRef.value,
          "is-view": __props.action === 'view'
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(MapMaster, {
                latitude: coordinates.value.latitude,
                longitude: coordinates.value.longitude
              }, null, 8, ["latitude", "longitude"])
            ])
          ]),
          _: 1
        }, 8, ["form", "initial-values", "is-view"]))
      ])
    ])
  ]))
}
}

})