import { DetailDto } from "@/store/orders/dtos/output/CreateOrder.dto";
import { OrderDetailForm } from "@/store/orders/interfaces/OrderDetailForm.interface";
import { get } from "lodash";

export const DetailsFormsToDetailsDtos = (
  details: OrderDetailForm[]
): DetailDto[] => {
  return details.map((detail) => {
    const product = { id: get(detail, "detail.product.id") };
    const price = Number(get(detail, "detail.product.item.price"));
    const quantity = get(detail, "detail.quantity");
    const discount = get(detail, "detail.discount");
    const discountType = get(detail, "detail.discountType.id");
    return {
      product,
      quantity,
      discount,
      price,
      discountType,
    };
  });
};
