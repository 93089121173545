<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="supplierForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useSuppliersServices } from "@/composables/useSuppliersServices";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { SupplierDto } from "@/store/suppliers/dtos/input/Supplier.dto";
import { SimpleSupplierActionType } from "@/views/suppliers/SuppliersView.vue";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateSupplierTemplate } from "./templates/forms/CreateOrUpdateSupplier.template";

const { customFields } = useCustomFieldsServices();
const { createSupplier, updateSupplier } = useSuppliersServices();

const props = defineProps({
  initialValues: { type: Object as PropType<SupplierDto>, required: false },
  action: {
    type: String as PropType<SimpleSupplierActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<SupplierDto> = ref();
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

function callBackCreate(supplier: SupplierDto) {
  emit("handleSubmit", { supplier, isCreating: true });
}

function callBackEdit(supplier: SupplierDto) {
  emit("handleSubmit", {
    supplier,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted: supplier } = form.value;
  if ("id" in supplier) {
    await updateSupplier(supplier, callBackEdit);
  } else {
    await createSupplier(supplier, callBackCreate);
  }
}

watch(
  [customFields, initialValuesRef],
  async () => {
    formTemplateRef.value = CreateOrUpdateSupplierTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value
    );
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss"></style>
