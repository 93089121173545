<template>
  <div class="row">
    <div class="col">
      <GeneralForm
        ref="workplaceFlowForm"
        :form="workplaceFlowFormTemplateRef"
        :key="`${props.productId}.${JSON.stringify(workplaceFlow)}`"
        form-name="workplacesFlowForm"
        :initialValues="workplaceFlow"
      >
        <CreateOrUpdateStep :steps="steps" v-model="steps">
        </CreateOrUpdateStep>
      </GeneralForm>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import {
  UpdateCustomFieldsDto,
  UpdateStepDto,
} from "@/store/workplaces-flow/dtos/output/UpdateWorkplaceFlow.dto";
import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { get, merge } from "lodash";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateStep from "./CreateOrUpdateStep.vue";
import { StepDtoToStepForm } from "./formatters/StepDtoToStepForm.formatter";
import { StepsFormToStepsDto } from "./formatters/StepFormToStepDto.formatter";
import { CreateOrUpdateWorkplaceFlows } from "./templates/forms/CreateOrUpdateWorkplaceFlows.template";

const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("workplace_flows");

const props = defineProps({
  productId: { type: Number, required: true },
});

const { workplaceFlow, getWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices(props.productId);

const workplaceFlowForm = ref();
const workplaceFlowFormTemplateRef = ref();
const steps: Ref<Array<WorkplaceStepForm>> = ref([]);

const emit = defineEmits(["handleSubmit"]);

function callBackEdit(product: ProductDto) {
  emit("handleSubmit", {
    product,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted }: { valuesFormatted: UpdateCustomFieldsDto } =
    workplaceFlowForm.value;
  const stepsFormatted: UpdateStepDto[] = StepsFormToStepsDto(steps.value);
  const workplaceFlow = merge({ productId: props.productId }, valuesFormatted, {
    steps: stepsFormatted,
  });
  await updateWorkplacesFlow(workplaceFlow, callBackEdit);
}

onMounted(async () => {
  await Promise.all([getModuleCustomFields(), getWorkplacesFlow()]);
  steps.value = StepDtoToStepForm(get(workplaceFlow, "value.steps"));
  watch(
    [customFields, workplaceFlow],
    async () => {
      workplaceFlowFormTemplateRef.value = CreateOrUpdateWorkplaceFlows(
        customFields.value,
        handleSubmit
      );
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms: () => workplaceFlowForm.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
