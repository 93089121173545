import { Module } from "vuex";
import { SupplierDto } from "./dtos/input/Supplier.dto";

interface State {
  suppliers?: Partial<SupplierDto>[];
}

export const suppliersStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    suppliers: [],
  },
  getters: {},
  mutations: {
    setSuppliers(state, suppliers: Partial<SupplierDto>[]) {
      state.suppliers = suppliers;
    },
    addSupplier(state, supplier: Partial<SupplierDto>) {
      state.suppliers = [...state.suppliers, supplier];
    },
    updateSupplier(state, supplier: Partial<SupplierDto>) {
      const index = state.suppliers.findIndex((p) => p.id === supplier.id);
      if (index !== -1) {
        state.suppliers[index] = { ...state.suppliers[index], ...supplier };
      } else {
        state.suppliers.push({ ...supplier });
      }
    },
    deleteSupplier(state, supplier: Partial<SupplierDto>) {
      state.suppliers = state.suppliers.filter((p) => p.id !== supplier.id);
    },
  },
  actions: {},
  modules: {},
};
