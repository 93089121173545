import axiosInstance from "@/middlewares/Routing.middleware";
import { WorkplaceDto } from "../dtos/input/Workplace.dto";
import { CreateWorkplaceDto } from "../dtos/output/CreateWorkplace.dto";
import { UpdateWorkplaceDto } from "../dtos/output/UpdateWorkplace.dto";

export async function CreateWorkplace(
  payload: CreateWorkplaceDto
): Promise<WorkplaceDto> {
  return (await axiosInstance.post(`workplaces`, payload)).data;
}

export async function UpdateWorkplace(
  payload: UpdateWorkplaceDto
): Promise<WorkplaceDto> {
  const { id, ...workplaceProps } = payload;
  return (await axiosInstance.put(`workplaces/${id}`, workplaceProps)).data;
}

export async function DeleteWorkplace(id: number): Promise<void> {
  await axiosInstance.delete(`workplaces/${id}`);
}
