import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { RawMaterialDto } from "@/store/raw-materials/dtos/input/RawMaterial.dto";
import { CreateRawMaterialDto } from "@/store/raw-materials/dtos/output/CreateRawMaterial.dto";
import { UpdateRawMaterialDto } from "@/store/raw-materials/dtos/output/UpdateRawMaterial.dto";
import {
  CreateRawMaterial,
  DeleteRawMaterial,
  UpdateRawMaterial,
} from "@/store/raw-materials/services/RawMaterial.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useRawMaterialsServices() {
  const { t } = useI18n();
  const alias = "rawMaterials";

  const createRawMaterial = async (
    payload: CreateRawMaterialDto,
    callBackCreate: (rawMaterial: RawMaterialDto) => void
  ): Promise<void> => {
    await CreateRawMaterial(payload)
      .then((rawMaterial: RawMaterialDto) => {
        callBackCreate(rawMaterial);
        useToast().successToast(t("rawMaterials.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateRawMaterial = async (
    payload: UpdateRawMaterialDto,
    callBackUpdate: (rawMaterial: RawMaterialDto) => void
  ): Promise<void> => {
    await UpdateRawMaterial(payload)
      .then((rawMaterial: RawMaterialDto) => {
        callBackUpdate(rawMaterial);
        useToast().successToast(t("rawMaterials.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteRawMaterial = async (
    payload: RawMaterialDto,
    callBackDelete: (rawMaterial: RawMaterialDto) => void
  ): Promise<void> => {
    await DeleteRawMaterial(payload.id)
      .then(() => {
        callBackDelete(payload);
        useToast().successToast(t("rawMaterials.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createRawMaterial,
    updateRawMaterial,
    deleteRawMaterial,
  };
}
