import axiosInstance from "@/middlewares/Routing.middleware";
import qs from "qs";
import { ProductInventoryDto } from "../dtos/input/ProductInventory.dto";
import { DispatchInventoryDto } from "../dtos/output/DispatchInventory.dto";
import { InsertInventoryDto } from "../dtos/output/InsertInventory.dto";
import { UpdateInventoryDto } from "../dtos/output/UpdateInventory.dto";
import { CheckInventoryInterface } from "../interfaces/CheckInventory.interface";
import { CheckResultInventory } from "../interfaces/CheckResultInventory.interface";

export async function CheckProductInventory(
  payload: CheckInventoryInterface
): Promise<CheckResultInventory> {
  const options = {
    params: payload,
  };
  if (payload) {
    options["paramsSerializer"] = (params) => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false });
    };
  }
  const { data } = await axiosInstance.get(
    "products-inventories/check",
    options
  );
  return data;
}

export async function DispatchProductInventory(
  payload: DispatchInventoryDto
): Promise<ProductInventoryDto[]> {
  const { data } = await axiosInstance.post(
    `products-inventories/dispatch`,
    payload
  );
  return data;
}

export async function InsertProductInventory(
  payload: InsertInventoryDto
): Promise<ProductInventoryDto[]> {
  const { data } = await axiosInstance.post(
    `products-inventories/insert`,
    payload
  );
  return data;
}

export async function ModifyProductInventory(
  payload: UpdateInventoryDto
): Promise<ProductInventoryDto[]> {
  const { id, ...inventoryProps } = payload;
  const { data } = await axiosInstance.put(
    `products-inventories/${id}/modify`,
    inventoryProps
  );
  return data;
}
