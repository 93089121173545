<template>
  <div class="alert alert-warning" role="alert">
    <div v-html="$t('stores.warnings.deleteAndReplace', { name, id })"></div>
  </div>
  <GeneralForm
    v-if="deletionFormTemplate"
    :form="deletionFormTemplate"
    formName="deleteForm"
    ref="form"
  >
  </GeneralForm>
</template>

<script lang="ts" setup>
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { StoreDto } from "@/store/stores/dtos/input/Store.dto";
import {
  computed,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { DeleteStoreTemplate } from "./templates/forms/DeleteStore.template";

const props = defineProps({
  handleSubmit: {
    type: Object as PropType<
      (values: { newId: { id: number; label: string } }) => void
    >,
    required: true,
  },
  currentRow: {
    type: Object as PropType<StoreDto>,
    required: true,
  },
});

const deletionFormTemplate = ref();
const form = ref();
const currentRowRef: Ref<StoreDto> = toRef(props, "currentRow");

const name = computed(() => {
  if (currentRowRef.value) {
    return translateFromKey(currentRowRef.value, "name");
  }
  return "";
});

const id = computed(() => {
  return currentRowRef.value?.id ?? "";
});
watch(
  [currentRowRef],
  async () => {
    deletionFormTemplate.value = DeleteStoreTemplate(async () => {
      await props.handleSubmit(form.value.values);
    }, props.currentRow.id);
    await nextTick();
    form.value?.setValues({ newId: "" });
  },
  { deep: true, immediate: true }
);

defineExpose({ ...form });
</script>

<style lang="scss" scoped>
::v-deep #btnLogin {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
  &:hover {
    color: red !important;
    background-color: white !important;
  }
}
</style>
