import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import { CreateDivisionDto } from "@/store/divisions/dtos/output/CreateDivision.dto";
import { DeleteDivisionDto } from "@/store/divisions/dtos/output/DeleteDivision.dto";
import { UpdateDivisionDto } from "@/store/divisions/dtos/output/UpdateDivision.dto";
import {
  CreateDivision,
  DeleteDivision,
  UpdateDivision,
} from "@/store/divisions/services/Division.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useDivisionsServices() {
  const { t } = useI18n();
  const alias = "divisions";

  const createDivision = async (
    payload: CreateDivisionDto,
    callBackCreate: (division: DivisionDto) => void
  ): Promise<void> => {
    await CreateDivision(payload)
      .then((division: DivisionDto) => {
        callBackCreate(division);
        useToast().successToast(t("divisions.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateDivision = async (
    payload: UpdateDivisionDto,
    callBackUpdate: (division: DivisionDto) => void
  ): Promise<void> => {
    await UpdateDivision(payload)
      .then((division: DivisionDto) => {
        callBackUpdate(division);
        useToast().successToast(t("divisions.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteDivision = async (
    payload: DeleteDivisionDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteDivision(payload)
      .then(() => {
        callBackDelete();
        useToast().successToast(t("divisions.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createDivision,
    updateDivision,
    deleteDivision,
  };
}
