import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { WorkplaceDto } from "@/store/workplaces/dtos/input/Workplace.dto";
import { CreateWorkplaceDto } from "@/store/workplaces/dtos/output/CreateWorkplace.dto";
import { UpdateWorkplaceDto } from "@/store/workplaces/dtos/output/UpdateWorkplace.dto";
import {
  CreateWorkplace,
  DeleteWorkplace,
  UpdateWorkplace,
} from "@/store/workplaces/services/Workplace.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useWorkplacesServices() {
  const { t } = useI18n();
  const alias = "workplaces";

  const createWorkplace = async (
    payload: CreateWorkplaceDto,
    callBackCreate: (workplace: WorkplaceDto) => void
  ): Promise<void> => {
    await CreateWorkplace(payload)
      .then((workplace: WorkplaceDto) => {
        callBackCreate(workplace);
        useToast().successToast(t("workplaces.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateWorkplace = async (
    payload: UpdateWorkplaceDto,
    callBackUpdate: (workplace: WorkplaceDto) => void
  ): Promise<void> => {
    await UpdateWorkplace(payload)
      .then((workplace: WorkplaceDto) => {
        callBackUpdate(workplace);
        useToast().successToast(t("workplaces.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteWorkplace = async (
    payload: WorkplaceDto,
    callBackDelete: (workplace: WorkplaceDto) => void
  ): Promise<void> => {
    await DeleteWorkplace(payload.id)
      .then(() => {
        callBackDelete(payload);
        useToast().successToast(t("workplaces.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createWorkplace,
    updateWorkplace,
    deleteWorkplace,
  };
}
