import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { t } from "@/shared/locales/services/i18n.services";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";

export const ProductsHeader = (
  runAction: (
    actionType: "edit" | "delete" | "view",
    product: ProductDto,
    index: number
  ) => void,
  customFields
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const { lang } = useLanguageServices();
  return [
    {
      sortable: true,
      value: { value: "products.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "products.entity.name", needsTranslate: true },
      key: "name",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "products.entity.description", needsTranslate: true },
      key: "description",
      mappedKey: [`metadata.language.description.${lang.value}`, "description"],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "products.entity.price", needsTranslate: true },
      key: "price",
      mappedKey: "price",
      columnType: "number",
      width: "100px",
      filterType: "number",
    },
    {
      sortable: true,
      value: { value: "products.entity.stock", needsTranslate: true },
      key: "stock",
      mappedKey: "stock",
      columnType: "number",
      width: "100px",
      filterType: "number",
    },
    {
      sortable: true,
      value: { value: "products.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: {
        value: "products.entity.measure",
        needsTranslate: true,
      },
      key: "measure",
      mainKey: "measure",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "190px",
      filterType: "select",
      selectOptions: {
        dataSource: "/measures/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    {
      sortable: true,
      value: {
        value: "products.entity.productFamily",
        needsTranslate: true,
      },
      key: "productFamily",
      mainKey: "productFamily",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "190px",
      filterType: "select",
      selectOptions: {
        dataSource: "/products-families/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    {
      sortable: true,
      value: { value: "products.entity.technicalSheet", needsTranslate: true },
      key: "technicalSheet",
      mappedKey: "technicalSheetExists",
      columnType: "text",
      width: "100px",
      filterType: "select",
      selectOptions: {
        idKey: "value",
        labelKey: "name",
        isMultiple: false,
        initialOptions: [
          {
            name: t("products.table.filters.technicalSheet.exists"),
            value: true,
          },
          {
            name: t("products.table.filters.technicalSheet.notExists"),
            value: false,
          },
        ],
      },
    },
    ...customFieldsHeaders,
    {
      sortable: false,
      value: { value: "products.table.actions", needsTranslate: true },
      columnType: "action",
      options: (product: ProductDto, index: number): Menu => {
        const options = [
          {
            label: {
              value: "products.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("edit", product, index),
          },
          {
            label: {
              value: "products.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("delete", product, index),
          },
          {
            label: {
              value: "products.table.actionList.view",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("view", product, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
