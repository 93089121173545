import { CreateCustomerDto } from "@/store/customers/dtos/output/CreateCustomer.dto";
import { UpdateCustomerDto } from "@/store/customers/dtos/output/UpdateCustomer.dto";
import { CustomerFormInterface } from "@/store/customers/interfaces/CustomerForm.interface";
import { get } from "lodash";

export function CustomerFormToCustomerDto(
  customer: CustomerFormInterface
): CreateCustomerDto | UpdateCustomerDto {
  const id = get(customer, "customer.id", false);
  const name = get(customer, "customer.name");
  const email = get(customer, "customer.email");
  const customerType = get(customer, "customer.customerType");
  const businessType = get(customer, "customer.businessType");
  const state = get(customer, "customer.state");
  const city = get(customer, "customer.city");
  const customFields = get(customer, "customer.customFields", {});
  const metadata = get(customer, "customer.metadata");
  const division = { id: get(customer, "customer.division.id") };

  return {
    ...(id === false ? {} : { id }),
    name,
    email,
    customerType,
    businessType,
    state,
    city,
    customFields,
    metadata,
    division,
  };
}
