import { CreatePositionDto } from "@/store/positions/dtos/output/CreatePosition.dto";
import { UpdatePositionDto } from "@/store/positions/dtos/output/UpdatePosition.dto";
import { PositionForm } from "@/store/positions/interfaces/PositionForm.interface";
import { get } from "lodash";

export function PositionFormToPositionDto(
  position: PositionForm
): CreatePositionDto | UpdatePositionDto {
  const id = get(position, "position.id", false);
  const name = get(position, "position.name");
  const description = get(position, "position.description");
  const baseSalary = Number(get(position, "position.baseSalary"));
  const division = { id: get(position, "position.division.id") };
  const metadata = get(position, "position.metadata");
  const customFields = get(position, "position.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    description,
    baseSalary,
    division,
    metadata,
    customFields,
  };
}
