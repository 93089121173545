import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { WorkplaceFlowDto } from "@/store/workplaces-flow/dtos/input/WorkplaceFlow.dto";
import { UpdateWorkplaceFlowDto } from "@/store/workplaces-flow/dtos/output/UpdateWorkplaceFlow.dto";
import {
  GetWorkplacesFlow,
  UpdateWorkplacesFlow,
} from "@/store/workplaces-flow/services/WorkplacesFlow.service";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useWorkplacesFlowsServices(productId: number) {
  const { t } = useI18n();
  const alias = "workplacesFlows";

  const workplaceFlow: Ref<WorkplaceFlowDto> = ref();

  const getWorkplacesFlow = async (): Promise<void> => {
    await GetWorkplacesFlow(productId)
      .then((workplaceFlowTemp: WorkplaceFlowDto) => {
        workplaceFlow.value = workplaceFlowTemp;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };
  const updateWorkplacesFlow = async (
    payload: UpdateWorkplaceFlowDto,
    callBackUpdate: (workplaceFlow: ProductDto) => void
  ): Promise<void> => {
    await UpdateWorkplacesFlow(payload)
      .then((product: ProductDto) => {
        callBackUpdate(product);
        useToast().successToast(t("workplacesFlows.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return { workplaceFlow, getWorkplacesFlow, updateWorkplacesFlow };
}
