import axiosInstance from "@/middlewares/Routing.middleware";
import { ProductFamilyDto } from "../dtos/input/ProductFamily.dto";
import { CreateProductFamilyDto } from "../dtos/output/CreateProductFamily.dto";
import { DeleteProductFamilyDto } from "../dtos/output/DeleteProductFamily.dto";
import { UpdateProductFamilyDto } from "../dtos/output/UpdateProductFamily.dto";

export async function CreateProductFamily(
  payload: CreateProductFamilyDto
): Promise<ProductFamilyDto> {
  return (await axiosInstance.post(`products-families`, payload)).data;
}

export async function UpdateProductFamily(
  payload: UpdateProductFamilyDto
): Promise<ProductFamilyDto> {
  const { id, ...productFamilyProps } = payload;
  return (
    await axiosInstance.put(`products-families/${id}`, productFamilyProps)
  ).data;
}

export async function DeleteProductFamily(
  payload: DeleteProductFamilyDto
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`products-families?newId=${newId}&oldId=${oldId}`);
}
