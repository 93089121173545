import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { CreateProductDto } from "@/store/products/dtos/output/CreateProduct.dto";
import { UpdateProductDto } from "@/store/products/dtos/output/UpdateProduct.dto";
import {
  CreateProduct,
  DeleteProduct,
  UpdateProduct,
} from "@/store/products/services/Product.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useProductsServices() {
  const { t } = useI18n();
  const alias = "products";

  const createProduct = async (
    payload: CreateProductDto,
    callBackCreate: (product: ProductDto) => void
  ): Promise<void> => {
    await CreateProduct(payload)
      .then((product: ProductDto) => {
        callBackCreate(product);
        useToast().successToast(t("products.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateProduct = async (
    payload: UpdateProductDto,
    callBackUpdate: (product: ProductDto) => void
  ): Promise<void> => {
    await UpdateProduct(payload)
      .then((product: ProductDto) => {
        callBackUpdate(product);
        useToast().successToast(t("products.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteProduct = async (
    payload: ProductDto,
    callBackDelete: (product: ProductDto) => void
  ): Promise<void> => {
    await DeleteProduct(payload.id)
      .then(() => {
        callBackDelete(payload);
        useToast().successToast(t("products.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createProduct,
    updateProduct,
    deleteProduct,
  };
}
