<template>
  <div class="container-fluid">
    <div class="row" v-if="formTemplateRef">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="orderForm"
          :initialValues="initialValuesRef"
          :is-view="action === 'view'"
        >
          <div class="row mt-3">
            <div class="col-1"><hr /></div>
            <div class="col-auto p-0 dividerText d-flex align-items-center">
              <b class="m-0 fs-5"> {{ t("orderDetail.title") }} </b>
            </div>
            <div class="col pl-1"><hr /></div>
          </div>
          <div
            class="row mt-3"
            v-for="(detail, index) in details"
            :key="`detail${index}`"
          >
            <div class="col">
              <OrderDetail :detail="detail.detail" />
            </div>
            <div class="col-auto p-0" v-if="action !== 'view'">
              <ButtonField
                :button-field="
                  editButton(() => {
                    handleAddOrEditDetail(index);
                  })
                "
              ></ButtonField>
            </div>
            <div class="col-auto pl-0" v-if="action !== 'view'">
              <ButtonField
                :button-field="
                  deleteButton(() => {
                    details.splice(index, 1);
                  })
                "
              ></ButtonField>
            </div>
          </div>
          <div class="col-12 text-end">
            <h4>Total: {{ total }}</h4>
          </div>
          <div
            class="col-12 d-flex justify-content-center"
            v-if="action !== 'view'"
          >
            <ButtonField :buttonField="addButtonTemplate"></ButtonField>
          </div>
        </GeneralForm>
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
          :initialValues="initialValuesRef"
        >
          <GeneralForm
            v-if="currentDetailIndex > -1"
            ref="detailForm"
            :form="detailFormTemplateRef"
            form-name="translateForm"
            :key="detailFormId"
            :initialValues="details[currentDetailIndex]"
            :is-view="action === 'view'"
          >
            <template #section-0-field-3>
              <div class="mt-2"></div>
              <h4 class="text-end">
                {{ $t("orderDetail.entity.subtotal") }} {{ subtotal }}
              </h4>
            </template>
          </GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useOrdersServices } from "@/composables/useOrdersServices";
import { getSubtotal } from "@/shared/orders/helpers/GetSubtotal.helper";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { OrderDto } from "@/store/orders/dtos/input/Order.dto";
import { DiscountTypes } from "@/store/orders/enums/DiscountTypes.enum";
import { OrderDetailForm } from "@/store/orders/interfaces/OrderDetailForm.interface";
import { SimpleOrderActionType } from "@/views/orders/types/SimpleOrderAction.type";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { DetailsDtoToDetailsForm } from "./formatters/DetailsDtoToDetailsForm.formatter";
import { DetailsFormsToDetailsDtos } from "./formatters/DetailsFormsToDetailsDtos.formatter";
import OrderDetail from "./OrderDetail.vue";
import { CreateOrUpdateDetailTemplate } from "./templates/forms/CreateOrUpdateDetail.template";
import { CreateOrUpdateOrderTemplate } from "./templates/forms/CreateOrUpdateOrder.template";

const { customFields } = useCustomFieldsServices();
const { createOrder, updateOrder } = useOrdersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const { t } = useI18n();

const props = defineProps({
  initialValues: {
    type: Object as PropType<OrderDto>,
    required: false,
  },
  isCreating: {
    type: Boolean,
    required: true,
  },
  action: {
    type: String as PropType<SimpleOrderActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<OrderDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const detailForm = ref();
const detailFormTemplateRef = ref();
const details: Ref<Array<OrderDetailForm>> = ref();
const currentDetailIndex = ref(-1);
const addButtonTemplate = ref(
  addButton(async () => {
    currentDetailIndex.value = details.value.length;
    await handleAddOrEditDetail(currentDetailIndex.value);
  })
);
const detailFormId = ref();

const emit = defineEmits(["handleSubmit"]);

const total = computed(() => {
  return details.value
    .map(({ detail }) => {
      const discountType = get(detail, "discountType.id", DiscountTypes.AMOUNT);
      const discount = get(detail, "discount", 0);
      return getSubtotal(
        detail.quantity,
        Number(detail.product.item.price),
        discountType,
        discount
      );
    })
    .reduce((accumulator, current) => {
      accumulator = accumulator + current;
      return accumulator;
    }, 0);
});

const subtotal = computed(() => {
  const detail = get(detailForm.value, "values.detail", {});
  const discountType = get(detail, "discountType.id", DiscountTypes.AMOUNT);
  const discount = get(detail, "discount", 0);
  if (detail?.quantity && detail?.product) {
    return getSubtotal(
      detail.quantity,
      Number(detail.product.item.price),
      discountType,
      discount
    );
  }
  return 0;
});

async function handleSubmitDetail() {
  const { values } = detailForm.value;
  details.value[currentDetailIndex.value] = merge(
    {},
    details.value[currentDetailIndex.value] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditDetail(detailIndex: number) {
  const isCreating = details.value.length <= detailIndex;
  modalTitle.value = isCreating
    ? t("orderDetail.form.createTitle")
    : t("orderDetail.form.editTitle");
  detailFormTemplateRef.value = CreateOrUpdateDetailTemplate(
    handleSubmitDetail,
    props.action
  );
  currentDetailIndex.value = detailIndex;
  detailFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(order: OrderDto) {
  emit("handleSubmit", { order, isCreating: true });
}

function callBackEdit(order: OrderDto) {
  emit("handleSubmit", {
    order,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const order = merge({}, valuesFormatted, {
    details: DetailsFormsToDetailsDtos(details.value),
  });
  if ("id" in order) {
    await updateOrder(order, callBackEdit);
  } else {
    await createOrder(order, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  detailForm.value?.resetForm();
  details.value = [];
  modal.value.closeModal();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [initialValuesRef, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateOrderTemplate(
          customFields.value,
          handleSubmit,
          props.action,
          catalogs.value
        );
      }
    },
    { deep: true, immediate: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
  get(initialValuesRef.value, "details", []).forEach((detail) => {
    console.log(detail);
    const price = get(detail, "data.price", detail.product.price);
    detail.product.price = price;
    console.log("Seteando precio: ", price);
  });
  details.value = DetailsDtoToDetailsForm(initialValuesRef.value);
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
