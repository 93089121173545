<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("users.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("users.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <GeneralDropdown ref="settingsDropdown">
                <template #button>
                  <button type="button" class="btn ml-1 btn-gear">
                    <i class="bi bi-gear"></i>
                  </button>
                </template>
                <template #body>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="openCustomFields">
                        {{ $t("users.settings.customFields") }}
                      </span>
                    </template>
                  </DropdownOption>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="openImportModal">
                        {{ $t("users.settings.import") }}
                      </span>
                    </template>
                  </DropdownOption>
                  <DropdownOption>
                    <template #name>
                      <span class="w-100" @click="exportTable">
                        {{ $t("users.settings.export") }}
                      </span>
                    </template>
                  </DropdownOption>
                </template>
              </GeneralDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      action === 'customField'
        ? $t('customFields.title')
        : action === 'create'
        ? $t('users.form.createTitle')
        : action === 'edit'
        ? $t('users.form.editTitle')
        : $t('users.form.viewTitle')
    "
  >
    <template v-slot:headerComplement>
      <ButtonField
        v-if="action === 'view'"
        :button-field="editButton(() => optionEdit(userTemp, lastRowIndex))"
      ></ButtonField
    ></template>
    <template v-slot:content>
      <CustomFields v-if="action === 'customField'" ref="form"></CustomFields>
      <CreateOrUpdateOrViewUser
        v-if="['create', 'edit', 'view'].includes(action)"
        ref="form"
        :initial-values="userTemp"
        @handle-submit="handleUserSubmit"
        :open-position-offcanvas="openPositionOffcanvas"
        :key="currentFormId"
        :action="action as SimpleUserActionType"
      ></CreateOrUpdateOrViewUser>
    </template>
    <template #offcanvasChild="{ setRef }">
      <GeneralOffcanvas
        canvasId="positionOffCanvas"
        :ref="
          (el) => {
            positionOffCanvasRef = el;
            setRef(el);
          }
        "
        :canvasName="$t('positions.form.createTitle')"
      >
        <template #content>
          <CreateOrUpdatePosition
            v-if="['create', 'edit'].includes(action)"
            ref="positionForm"
            @handle-submit="() => positionOffCanvasRef.closeOffCanvas()"
            action="create"
          ></CreateOrUpdatePosition>
        </template>
      </GeneralOffcanvas>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslate"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralModal
    modalId="importExportModal"
    ref="importModal"
    :modalName="$t('users.modals.importModal')"
    data-bs-backdrop="false"
    class="p-4"
    size="modal-xl"
  >
    <GeneralImport
      :key="importFormId"
      :template="userTemplate"
      :handleSubmit="() => importModal.closeModal()"
    ></GeneralImport>
  </GeneralModal>
  <GeneralTable
    v-if="usersHeaderTable"
    dataSource="/users/"
    :filters="tableFilters"
    :headers="usersHeaderTable"
    name="usersTable"
    ref="userTable"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import CreateOrUpdatePosition from "@/components/positions/CreateOrUpdatePosition.vue";
import CreateOrUpdateOrViewUser from "@/components/users/CreateOrUpdateOrViewUser.vue";
import { CreateOrUpdateUserTemplate } from "@/components/users/templates/forms/CreateOrUpdateUser.template";
import { UsersHeader } from "@/components/users/templates/headers/Users.headers";
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { t } from "@/shared/locales/services/i18n.services";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";

const { enableUser, disableUser, deleteUser } = useUsersServices();
const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { exportFile } = useImportExportServices();
const { lang } = useLanguageServices();

export type SimpleUserActionType = "create" | "edit" | "view";

type UserActionType =
  | SimpleUserActionType
  | "customField"
  | "disable"
  | "enable"
  | "delete";

const form = ref();
const userTable = ref();
const offCanvasRef = ref();
const usersHeaderTable = ref();
const userTemp: Ref<UserDto | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<UserActionType> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const positionOffCanvasRef = ref();
const positionForm = ref();
const tableFilters = ref([]);
const settingsDropdown = ref();
const importModal = ref();
const userTemplate = ref();
const importFormId = ref();
const currentFormId = ref();

async function openImportModal() {
  importFormId.value = uuidv4();
  await getAllCatalogs();
  userTemplate.value = CreateOrUpdateUserTemplate(
    customFields.value,
    catalogs.value
  );
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    userTable,
    "value.allFilters"
  );
  exportFile(filters);
}

function openCustomFields() {
  settingsDropdown.value.closeDropdown();
  openOffcanvasForm("customField");
}

function openPositionOffcanvas() {
  positionOffCanvasRef.value.openOffCanvas();
}

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.updateRow(lastRowIndex.value, user);
  }
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: UserDto, index: number) {
  openOffcanvasForm("edit");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function optionDisable(user: UserDto, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: UserDto, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: UserDto, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warnings.delete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionView(user: UserDto, index: number) {
  openOffcanvasForm("view");
  action.value = "view";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function callBackUpdate(user: UserDto) {
  userTable.value.currentData[lastRowIndex.value] = user;
}

function callBackDelete() {
  userTable.value.currentData = userTable.value.currentData.filter(
    (user: UserDto, index) => {
      return index !== lastRowIndex.value;
    }
  );
}

async function handleConfirm() {
  if (action.value === "enable") {
    await enableUser(userTemp.value, callBackUpdate);
  } else if (action.value === "disable") {
    await disableUser(userTemp.value, callBackUpdate);
  } else if (action.value === "delete") {
    await deleteUser(userTemp.value, callBackDelete);
  }
}

function openOffcanvasForm(newAction: UserActionType) {
  userTemp.value = null;
  action.value = newAction;
  form.value?.resetForms?.();
  offCanvasRef.value.openOffCanvas();
}

function closeOffcanvasForm() {
  offCanvasRef.value.closeOffCanvas();
  form.value.resetForms && form.value.resetForms();
}

onMounted(async () => {
  await getModuleCustomFields();
  await getAllCatalogs();
  watch(
    [customFields, lang],
    () => {
      usersHeaderTable.value = UsersHeader(
        optionEdit,
        optionDelete,
        optionDisable,
        optionEnable,
        optionView,
        customFields.value,
        catalogs.value
      );
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
