<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("products.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("products.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <div class="dropdown">
                <GeneralDropdown ref="settingsDropdown">
                  <template #button>
                    <button type="button" class="btn ml-1 btn-gear">
                      <i class="bi bi-gear"></i>
                    </button>
                  </template>
                  <template #body>
                    <DropdownOption>
                      <template #name>
                        <span
                          class="w-100"
                          @click="
                            () => openOffcanvasForm('productsCustomFields')
                          "
                        >
                          {{ $t("products.settings.customFields") }}
                        </span>
                      </template>
                    </DropdownOption>
                    <DropdownOption>
                      <template #name>
                        <span
                          class="w-100"
                          @click="
                            () =>
                              openOffcanvasForm('technicalSheetCustomFields')
                          "
                        >
                          {{
                            $t("products.settings.technicalSheetsCustomFields")
                          }}
                        </span>
                      </template>
                    </DropdownOption>
                    <DropdownOption>
                      <template #name>
                        <span
                          class="w-100"
                          @click="
                            () =>
                              openOffcanvasForm('workplacesFlowCustomFields')
                          "
                        >
                          {{
                            $t("products.settings.workplacesFlowsCustomFields")
                          }}
                        </span>
                      </template>
                    </DropdownOption>
                    <DropdownOption>
                      <template #name>
                        <span class="w-100" @click="openImportModal">
                          {{ $t("products.settings.import") }}
                        </span>
                      </template>
                    </DropdownOption>
                    <DropdownOption>
                      <template #name>
                        <span class="w-100" @click="exportTable">
                          {{ $t("products.settings.export") }}
                        </span>
                      </template>
                    </DropdownOption>
                  </template>
                </GeneralDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    :ref="(el) => (offCanvasRefs[0] = el)"
    :canvasName="
      action === 'productsCustomFields'
        ? $t('customFields.title')
        : action === 'create'
        ? $t('products.form.createTitle')
        : action === 'edit'
        ? $t('products.form.editTitle')
        : action === 'view'
        ? $t('products.form.viewTitle')
        : `${$t('customFields.title')} - ${$t(
            `products.form.${action?.split('CustomFields')[0]}Title`
          )}`
    "
  >
    <template v-slot:headerComplement>
      <ButtonField
        v-if="action === 'view'"
        :button-field="
          editButton(() => runAction('edit', tempRow, lastRowIndex))
        "
      ></ButtonField
    ></template>
    <template #content>
      <CustomFields
        v-if="
          [
            'productsCustomFields',
            'technicalSheetCustomFields',
            'workplacesFlowCustomFields',
          ].includes(action)
        "
        :initial-module-name="moduleName"
        :ref="(el) => (forms[0] = el)"
        :key="formKeys[0]"
      ></CustomFields>
      <CreateOrUpdateProduct
        v-if="['create', 'edit', 'view'].includes(action)"
        :ref="(el) => (forms[0] = el)"
        :initial-values="tempRow"
        @handle-submit="(data) => handleSubmit(data, 0)"
        :key="currentFormId"
        :openMeasureOffcanvas="() => openOffcanvasForm('measure')"
        :action="action as SimplePoductActionTypes"
      >
        <div class="row justify-content-end" v-if="action === 'edit'">
          <div class="col-auto">
            <div class="row justify-content-end">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  @click="() => openOffcanvasForm('technicalSheet')"
                >
                  {{ $t("products.form.technicalSheet") }}
                </span>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  @click="() => openOffcanvasForm('workplacesFlow')"
                >
                  {{ $t("products.form.workplaceFlow") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CreateOrUpdateProduct>
    </template>
    <template #offcanvasChild="{ setRef }">
      <GeneralOffcanvas
        :canvasId="`${actionChildren}`"
        :ref="
          (el) => {
            offCanvasRefs[1] = el;
            setRef(el);
          }
        "
        :canvasName="
          actionChildren === 'technicalSheet'
            ? $t('technicalSheets.title', {name:(tempRow as ProductDto).name, measure:(tempRow as ProductDto).measure.name })
            : (actionChildren === 'workplacesFlow'? $t('workplacesFlows.title'): $t('measures.title'))
        "
        :initialWidth="actionChildren === 'technicalSheet' ? 80 : 30"
      >
        <template #headerComplement v-if="actionChildren && action === 'edit'">
          <div class="col pr-0">
            <div class="container w-auto">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button
                    class="btn ml-1 btn-gear"
                    type="button"
                    @click="() => openOffCanvas(2)"
                  >
                    {{ $t("customFields.title") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <CreateOrUpdateTechnicalSheet
            v-if="actionChildren === 'technicalSheet' && action === 'edit'"
            :ref="(el) => (forms[1] = el)"
            :key="formKeys[1]"
            :product-id="tempRow?.id"
            @handle-submit="(data) => handleSubmit(data, 1)"
          />
          <CreateOrUpdateWorkplacesFlow
            v-else-if="actionChildren === 'workplacesFlow' && action === 'edit'"
            :key="formKeys[1] + 1"
            :ref="(el) => (forms[1] = el)"
            :product-id="tempRow?.id"
            @handle-submit="(data) => handleSubmit(data, 1)"
          ></CreateOrUpdateWorkplacesFlow>
          <CreateOrUpdateMeasure
            v-else-if="actionChildren === 'measure'"
            :ref="(el) => (forms[1] = el)"
            @handle-submit="() => offCanvasRefs[1].closeOffCanvas()"
            action="create"
          ></CreateOrUpdateMeasure>
        </template>
        <template #offcanvasChild="{ setRef }">
          <GeneralOffcanvas
            canvasId="customFieldTechnicalSheetsCanvas"
            :ref="
              (el) => {
                offCanvasRefs[2] = el;
                setRef(el);
              }
            "
            :canvasName="`${$t('customFields.title')} - ${$t(
              `products.form.${action?.split('CustomFields')[0]}Title`
            )}`"
          >
            <template #content>
              <CustomFields
                :initial-module-name="moduleName"
                :key="moduleName"
              ></CustomFields>
            </template>
          </GeneralOffcanvas>
        </template>
      </GeneralOffcanvas>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslated"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/products/"
    :filters="tableFilters"
    :headers="headerTable"
    name="productsTable"
    ref="tableRef"
  >
    <template #technicalSheet="{ row }">
      <i
        v-if="get(row, 'technicalSheetExists')"
        class="bi bi-file-earmark-arrow-down fs-4"
        @click="openTechnicalSheetPreview(row.id)"
      ></i>
    </template>
  </GeneralTable>
  <GeneralModal
    ref="technicalSheetModal"
    modalId="technicalSheetPreview"
    :modalName="$t('technicalSheets.view.title')"
    size="modal-xl"
  >
    <PreviewTechnicalSheet
      v-if="currentProductId"
      :product-id="currentProductId"
      :key="technicalSheetPreviewId"
    ></PreviewTechnicalSheet>
  </GeneralModal>
  <GeneralModal
    modalId="importExportModal"
    ref="importModal"
    :modalName="$t('products.modals.importModal')"
    data-bs-backdrop="false"
    class="p-4"
    size="modal-xl"
  >
    <GeneralImport
      :key="importFormId"
      :template="productTemplate"
      :handleSubmit="() => importModal.closeModal()"
    ></GeneralImport>
  </GeneralModal>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import CreateOrUpdateMeasure from "@/components/measures/CreateOrUpdateMeasure.vue";
import CreateOrUpdateProduct from "@/components/products/CreateOrUpdateProduct.vue";
import PreviewTechnicalSheet from "@/components/products/PreviewTechnicalSheet.vue";
import { CreateOrUpdateProductTemplate } from "@/components/products/templates/forms/CreateOrUpdateProduct.template";
import { ProductsHeader } from "@/components/products/templates/headers/Products.headers";
import CreateOrUpdateTechnicalSheet from "@/components/technicalSheets/CreateOrUpdateTechnicalSheet.vue";
import CreateOrUpdateWorkplacesFlow from "@/components/workplaces-flows/CreateOrUpdateWorkplacesFlow.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useProductsServices } from "@/composables/useProductsServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export type SimplePoductActionTypes = "create" | "edit" | "view";

type ProductActionTypes =
  | SimplePoductActionTypes
  | "delete"
  | "productsCustomFields"
  | "technicalSheetCustomFields"
  | "workplacesFlowCustomFields";

type ProductActionChildrenTypes =
  | "technicalSheet"
  | "workplacesFlow"
  | "measure";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteProduct } = useProductsServices();
const { exportFile } = useImportExportServices();
const { t } = useI18n();
const { lang } = useLanguageServices();

const formKeys = ref([0, 0, 0]);
const forms = ref([]);
const tableRef = ref();
const offCanvasRefs = ref([]);
const action: Ref<ProductActionTypes> = ref("create");
const actionChildren: Ref<ProductActionChildrenTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductDto | null> = ref();
const technicalSheetModal = ref();
const currentProductId = ref();
const technicalSheetPreviewId = ref();
const productTemplate = ref();
const settingsDropdown = ref();
const importModal = ref();
const importFormId = ref();
const currentFormId = ref();

const moduleName = computed(() => {
  if (
    actionChildren.value === "technicalSheet" ||
    action.value === "technicalSheetCustomFields"
  ) {
    return "technical_sheets";
  } else if (
    actionChildren.value === "workplacesFlow" ||
    action.value === "workplacesFlowCustomFields"
  ) {
    return "workplace_flows";
  }
  return "products";
});

async function openImportModal() {
  importFormId.value = uuidv4();
  productTemplate.value = CreateOrUpdateProductTemplate(customFields.value);
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    tableRef,
    "value.allFilters"
  );
  exportFile(filters);
}

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `products.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const openOffCanvas = (index) => {
  formKeys.value[index]++;
  offCanvasRefs.value?.[index]?.openOffCanvas?.();
};

function handleSubmit(data, index) {
  const { product, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [product, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, product);
  }
  offCanvasRefs.value[index].closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete" | "view",
  row: ProductDto,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  } else if (actionType === "view") {
    openOffcanvasForm("view");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteProduct(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function isProductActionType(
  value: ProductActionTypes | ProductActionChildrenTypes
): value is ProductActionTypes {
  return [
    "create",
    "edit",
    "view",
    "productsCustomFields",
    "technicalSheetCustomFields",
    "workplacesFlowCustomFields",
  ].includes(value);
}

function openOffcanvasForm(
  newAction: ProductActionTypes | ProductActionChildrenTypes
) {
  if (isProductActionType(newAction)) {
    tempRow.value = null;
    action.value = newAction;
    actionChildren.value = null;
    openOffCanvas(0);
  } else {
    actionChildren.value = newAction;
    openOffCanvas(1);
  }
  settingsDropdown.value.closeDropdown();
}

function openTechnicalSheetPreview(id: number) {
  currentProductId.value = id;
  technicalSheetPreviewId.value = uuidv4();
  technicalSheetModal.value.openModal();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields, lang],
    () => {
      headerTable.value = ProductsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

i {
  cursor: pointer;
}

.modal {
  background-color: $BackgroundModal;
}

.accordion-item {
  border: none;
}

.accordionItem {
  border-bottom: rgb(204, 204, 204) 1px solid;
  cursor: pointer;
  &:hover {
    background-color: rgb(216, 216, 216);
  }
}

.accordion-body {
  background-color: #f6f6f6;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  box-shadow: none;
}
</style>
