<template>
  <button type="button" class="btn btn-primary" @click="downloadTemplate">
    {{ $t("importAndExport.downloadTemplate") }}
  </button>
  <GeneralForm
    ref="form"
    :form="formTemplateRef"
    formName="UploadExcel"
    :is-view="false"
  >
    <template #file-preview-0-0-0>
      <TablePreview
        :items="items"
        :template="formattedTemplateRef"
      ></TablePreview>
    </template>
  </GeneralForm>
</template>

<script lang="ts" setup>
import { useImportExportServices } from "@/composables/useImportExportServices";
import {
  generateAndDownloadExcel,
  getHeaderAndRows,
  loadExcel,
} from "@/shared/globals/excel/helpers/excel.helper";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { isField } from "@/shared/globals/helpers/Template.helper";
import { cloneDeep } from "lodash";
import {
  computed,
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import TablePreview from "./TablePreview.vue";
import { UploadImportTemplate } from "./templates/forms/UploadImport.template";

const { importFile } = useImportExportServices();

const props = defineProps({
  template: { type: Object as PropType<Form>, required: true },
});

const templateRef = toRef(props, "template");
const route = useRoute();
const moduleName: string = route.meta.moduleName as string;
const formTemplateRef = ref();
const form = ref();
const items = ref([]);
const formattedTemplateRef = computed(() => {
  const formattedTemplate = cloneDeep(templateRef.value);
  formattedTemplate?.sections?.forEach((sections) => {
    sections.fields.forEach((field) => {
      if (isField(field)) {
        const [_, ...path] = field.path.split(".");
        field.path = path.join(".");
      }
    });
  });
  return formattedTemplate;
});

const emits = defineEmits(["handleSubmit"]);

async function downloadTemplate() {
  await generateAndDownloadExcel(formattedTemplateRef.value, moduleName);
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  await importFile(valuesFormatted);
  emits("handleSubmit");
}

const excelFile: Ref<File> = computed(() => {
  return form.value?.values?.files?.[0]?.file;
});

watch(excelFile, async () => {
  if (excelFile.value) {
    const workbook = await loadExcel(excelFile.value);
    items.value = getHeaderAndRows(workbook);
  } else {
    items.value = [];
  }
});

onMounted(() => {
  formTemplateRef.value = UploadImportTemplate(handleSubmit);
});
</script>

<style lang="scss"></style>
