<template>
  <div v-if="debug">
    currentPage: {{ currentPage }}
    <br />
    totalRows: {{ totalRows }}
    <br />
    perPage: {{ perPage }}
    <br />
    totalPages: {{ totalPages }}
    <br />
    startIndex: {{ startIndex }}
    <br />
    endIndex: {{ endIndex }}
  </div>

  <BTable v-bind="{ items:itemsPaginated, fields, showEmpty: true } as any">
    <template #empty>
      <h5 class="text-center">
        {{ $t("technicalSheets.tables.rawMaterials.isEmpty") }}
      </h5>
    </template>
    <template
      v-for="field of fields"
      :key="field.key"
      v-slot:[`cell(${field.key})`]="{ item }"
    >
      <div v-if="field.field.type === 'date'" class="text-nowrap">
        {{
          formatDate(get(item, field.key) as Date) !== "Invalid Date"
            ? formatDate(get(item, field.key) as Date)
            : "-"
        }}
      </div>
      <div v-else class="text-nowrap">
        {{ get(item, field.key) }}
      </div>
    </template>
  </BTable>
  <div class="d-flex justify-content-center">
    <BPagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      first-number
    />
  </div>
</template>

<script lang="ts" setup>
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { isField } from "@/shared/globals/helpers/Template.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { BPagination, BTable } from "bootstrap-vue-3";
import { get } from "lodash";
import { computed, defineProps, PropType, ref, Ref, toRef } from "vue";
import { boolean } from "yup";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  template: { type: Object as PropType<Form>, required: true },
  debug: { type: boolean, required: false, default: false },
});

const items = toRef(props, "items");
const perPage = ref(5);
const currentPage = ref(1);
const totalRows = computed(() => {
  return items.value.length;
});

const fields: Ref<
  Array<{
    key: string;
    sortable: boolean;
    label: string;
    field: Field;
  }>
> = computed(() => {
  const fields = [];
  props.template.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (isField(field)) {
        fields.push({
          key: field.path,
          sortable: false,
          label: t(field.label.value),
          field: field,
        });
      }
    });
  });
  return fields;
});

const totalPages = computed(() => {
  return Math.round(totalRows.value / perPage.value);
});

const startIndex = computed(() => {
  return (currentPage.value - 1) * perPage.value;
});

const endIndex = computed(() => {
  return currentPage.value * perPage.value;
});

const itemsPaginated = computed(() => {
  return items.value.filter((item, index) => {
    return startIndex.value <= index && index < endIndex.value;
  });
});
</script>

<style lang="scss" scoped></style>
