import { get, unset } from "lodash";
import { CustomFieldDto } from "../dtos/input/CustomField.dto";
import { CustomFieldValues } from "../interfaces/CustomFieldValues.interface";

export function clearCustomFields(item, customFields: CustomFieldDto[]) {
  const customFieldsValues = get(item, "customFields", {});
  filterCustomField(customFieldsValues, customFields);
  return item;
}

// Limpiar los custom fields ya sea de los custom fields borrados u options de un custom fields borrados
export function filterCustomField(
  currentCustomFieldsValues: CustomFieldValues | null,
  customFields: CustomFieldDto[]
) {
  const customFieldsKeys = customFields.map((customField) => {
    return customField.id;
  });
  Object.keys(currentCustomFieldsValues).forEach((customFieldKey) => {
    if (!customFieldsKeys.includes(customFieldKey)) {
      unset(currentCustomFieldsValues, `${customFieldKey}`);
    } else {
      if (typeof currentCustomFieldsValues[customFieldKey] === "object") {
        // es un option
        const customField = customFields.find(
          (customField) => customField.id === customFieldKey
        );
        const optionsKeys = Object.keys(customField.metadata.options);
        if (Array.isArray(currentCustomFieldsValues[customFieldKey])) {
          currentCustomFieldsValues[customFieldKey] = currentCustomFieldsValues[
            customFieldKey
          ].filter(({ id }) => {
            return optionsKeys.includes(String(id));
          });
        } else {
          const optionId = currentCustomFieldsValues[customFieldKey].id;
          if (!optionsKeys.includes(String(optionId))) {
            unset(currentCustomFieldsValues, `${customFieldKey}`);
          }
        }
      }
    }
  });
}
