import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import DropdownOption from "@/components/globals/dropdowns/DropdownOption.vue";
import GeneralDropdown from "@/components/globals/dropdowns/GeneralDropdown.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import GeneralImport from "@/components/importAndExport/GeneralImport.vue";
import CreateOrUpdatePosition from "@/components/positions/CreateOrUpdatePosition.vue";
import CreateOrUpdateOrViewUser from "@/components/users/CreateOrUpdateOrViewUser.vue";
import { CreateOrUpdateUserTemplate } from "@/components/users/templates/forms/CreateOrUpdateUser.template";
import { UsersHeader } from "@/components/users/templates/headers/Users.headers";
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useImportExportServices } from "@/composables/useImportExportServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { t } from "@/shared/locales/services/i18n.services";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";

export type SimpleUserActionType = "create" | "edit" | "view";

type UserActionType =
  | SimpleUserActionType
  | "customField"
  | "disable"
  | "enable"
  | "delete";


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersView',
  setup(__props) {

const { enableUser, disableUser, deleteUser } = useUsersServices();
const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { exportFile } = useImportExportServices();
const { lang } = useLanguageServices();

const form = ref();
const userTable = ref();
const offCanvasRef = ref();
const usersHeaderTable = ref();
const userTemp: Ref<UserDto | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<UserActionType> = ref();
const confirmModalTitleTranslate: Ref<string> = ref("");
const positionOffCanvasRef = ref();
const positionForm = ref();
const tableFilters = ref([]);
const settingsDropdown = ref();
const importModal = ref();
const userTemplate = ref();
const importFormId = ref();
const currentFormId = ref();

async function openImportModal() {
  importFormId.value = uuidv4();
  await getAllCatalogs();
  userTemplate.value = CreateOrUpdateUserTemplate(
    customFields.value,
    catalogs.value
  );
  settingsDropdown.value.closeDropdown();
  importModal.value.openModal();
}

async function exportTable() {
  settingsDropdown.value.closeDropdown();
  const { page, size, ...filters }: GetTableDataDto = get(
    userTable,
    "value.allFilters"
  );
  exportFile(filters);
}

function openCustomFields() {
  settingsDropdown.value.closeDropdown();
  openOffcanvasForm("customField");
}

function openPositionOffcanvas() {
  positionOffCanvasRef.value.openOffCanvas();
}

function handleUserSubmit(data) {
  const { user, isCreating } = data;
  if (isCreating) {
    userTable.value.currentData = [user, ...userTable.value.currentData];
  } else {
    userTable.value.updateRow(lastRowIndex.value, user);
  }
  closeOffcanvasForm();
}

const confirmationQuestion = computed(() => {
  return {
    value: `users.${question.value}`,
    params: {
      name: userTemp.value?.name || "",
    },
    needsTranslate: true,
  };
});

const question = computed(() => {
  if (action.value === "disable") {
    return "questionDisable";
  } else if (action.value === "enable") {
    return "questionEnable";
  } else if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

function optionEdit(user: UserDto, index: number) {
  openOffcanvasForm("edit");
  action.value = "edit";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function optionDisable(user: UserDto, index: number) {
  action.value = "disable";
  confirmModalTitleTranslate.value = t("global.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionEnable(user: UserDto, index: number) {
  action.value = "enable";
  confirmModalTitleTranslate.value = t("users.warnings.status");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionDelete(user: UserDto, index: number) {
  action.value = "delete";
  confirmModalTitleTranslate.value = t("global.warnings.delete");
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionView(user: UserDto, index: number) {
  openOffcanvasForm("view");
  action.value = "view";
  userTemp.value = { ...user };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function callBackUpdate(user: UserDto) {
  userTable.value.currentData[lastRowIndex.value] = user;
}

function callBackDelete() {
  userTable.value.currentData = userTable.value.currentData.filter(
    (user: UserDto, index) => {
      return index !== lastRowIndex.value;
    }
  );
}

async function handleConfirm() {
  if (action.value === "enable") {
    await enableUser(userTemp.value, callBackUpdate);
  } else if (action.value === "disable") {
    await disableUser(userTemp.value, callBackUpdate);
  } else if (action.value === "delete") {
    await deleteUser(userTemp.value, callBackDelete);
  }
}

function openOffcanvasForm(newAction: UserActionType) {
  userTemp.value = null;
  action.value = newAction;
  form.value?.resetForms?.();
  offCanvasRef.value.openOffCanvas();
}

function closeOffcanvasForm() {
  offCanvasRef.value.closeOffCanvas();
  form.value.resetForms && form.value.resetForms();
}

onMounted(async () => {
  await getModuleCustomFields();
  await getAllCatalogs();
  watch(
    [customFields, lang],
    () => {
      usersHeaderTable.value = UsersHeader(
        optionEdit,
        optionDelete,
        optionDisable,
        optionEnable,
        optionView,
        customFields.value,
        catalogs.value
      );
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("users.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("users.form.add")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(GeneralDropdown, {
                  ref_key: "settingsDropdown",
                  ref: settingsDropdown
                }, {
                  button: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn ml-1 btn-gear"
                    }, [
                      _createElementVNode("i", { class: "bi bi-gear" })
                    ], -1)
                  ])),
                  body: _withCtx(() => [
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: openCustomFields
                        }, _toDisplayString(_ctx.$t("users.settings.customFields")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: openImportModal
                        }, _toDisplayString(_ctx.$t("users.settings.import")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(DropdownOption, null, {
                      name: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "w-100",
                          onClick: exportTable
                        }, _toDisplayString(_ctx.$t("users.settings.export")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: 
      action.value === 'customField'
        ? _ctx.$t('customFields.title')
        : action.value === 'create'
        ? _ctx.$t('users.form.createTitle')
        : action.value === 'edit'
        ? _ctx.$t('users.form.editTitle')
        : _ctx.$t('users.form.viewTitle')
    
    }, {
      headerComplement: _withCtx(() => [
        (action.value === 'view')
          ? (_openBlock(), _createBlock(ButtonField, {
              key: 0,
              "button-field": _unref(editButton)(() => optionEdit(userTemp.value, lastRowIndex.value))
            }, null, 8, ["button-field"]))
          : _createCommentVNode("", true)
      ]),
      content: _withCtx(() => [
        (action.value === 'customField')
          ? (_openBlock(), _createBlock(CustomFields, {
              key: 0,
              ref_key: "form",
              ref: form
            }, null, 512))
          : _createCommentVNode("", true),
        (['create', 'edit', 'view'].includes(action.value))
          ? (_openBlock(), _createBlock(CreateOrUpdateOrViewUser, {
              ref_key: "form",
              ref: form,
              "initial-values": userTemp.value,
              onHandleSubmit: handleUserSubmit,
              "open-position-offcanvas": openPositionOffcanvas,
              key: currentFormId.value,
              action: action.value as SimpleUserActionType
            }, null, 8, ["initial-values", "action"]))
          : _createCommentVNode("", true)
      ]),
      offcanvasChild: _withCtx(({ setRef }) => [
        _createVNode(GeneralOffcanvas, {
          canvasId: "positionOffCanvas",
          ref: 
          (el) => {
            positionOffCanvasRef.value = el;
            setRef(el);
          }
        ,
          canvasName: _ctx.$t('positions.form.createTitle')
        }, {
          content: _withCtx(() => [
            (['create', 'edit'].includes(action.value))
              ? (_openBlock(), _createBlock(CreateOrUpdatePosition, {
                  key: 0,
                  ref_key: "positionForm",
                  ref: positionForm,
                  onHandleSubmit: _cache[1] || (_cache[1] = () => positionOffCanvasRef.value.closeOffCanvas()),
                  action: "create"
                }, null, 512))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["canvasName"])
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslate.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    _createVNode(GeneralModal, {
      modalId: "importExportModal",
      ref_key: "importModal",
      ref: importModal,
      modalName: _ctx.$t('users.modals.importModal'),
      "data-bs-backdrop": "false",
      class: "p-4",
      size: "modal-xl"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralImport, {
          key: importFormId.value,
          template: userTemplate.value,
          handleSubmit: () => importModal.value.closeModal()
        }, null, 8, ["template", "handleSubmit"]))
      ]),
      _: 1
    }, 8, ["modalName"]),
    (usersHeaderTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/users/",
          filters: tableFilters.value,
          headers: usersHeaderTable.value,
          name: "usersTable",
          ref_key: "userTable",
          ref: userTable
        }, null, 8, ["filters", "headers"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})