<template>
  <div class="accordion" ref="accordion">
    <div
      class="accordion-item"
      v-for="(slot, index) in allHeaderSlots"
      :key="`accordionItem-${index}`"
      ref="accordionItems"
    >
      <h2 class="accordion-header">
        <button
          v-if="slot.isHeaderButton"
          class="accordion-button"
          type="button"
          @click.prevent="toggleAcordion(index)"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${index}`"
          aria-expanded="true"
          :aria-controls="`collapse${index}`"
          :disabled="isRunning"
        >
          <slot :name="`header-button-${index}`"></slot>
        </button>
        <slot
          v-else
          :name="`header-${index}`"
          :toggleAcordion="() => toggleAcordion(index)"
        ></slot>
      </h2>
      <div
        :id="`collapse${index}`"
        class="accordion-collapse collapse"
        ref="collapseItems"
      >
        <div class="accordion-body">
          <slot :name="`body-${index}`"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Collapse } from "bootstrap";
import {
  computed,
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref,
  useSlots,
} from "vue";

const props = defineProps({
  expandedItems: {
    type: Object as PropType<Array<number>>,
    required: false,
  },
  uniqueExpanded: {
    type: Boolean,
    required: false,
  },
});

const accordion = ref();
const accordionItems = ref([]);
const collapseItems = ref([]);
const eventsDone = ref([]);

const slots = useSlots();
const isRunning = ref(false);
const emits = defineEmits(["closed"]);

const waitForToggleCompletion = (): Promise<void> => {
  return new Promise((resolve) => {
    const checkCondition = async () => {
      const isValid = eventsDone.value.every((done) => {
        return done;
      });
      if (isValid) {
        resolve();
      } else {
        setTimeout(checkCondition, 50);
      }
    };
    checkCondition();
  });
};

const toggleAcordion = async (index) => {
  if (isRunning.value) return;
  isRunning.value = true;
  for (const [i, collapseItem] of collapseItems.value.entries()) {
    eventsDone[i] = false;
    if (i !== index && props.uniqueExpanded) {
      Collapse.getOrCreateInstance(collapseItem).hide();
      emits("closed", { index });
    } else {
      Collapse.getOrCreateInstance(collapseItems.value[index]).toggle();
    }
  }
  if (props.uniqueExpanded) {
    await waitForToggleCompletion();
  }
  setTimeout(() => {
    isRunning.value = false;
  }, 500);
};

const allHeaderSlots = computed(() =>
  Object.keys(slots)
    .filter((slot) => slot.startsWith("header-"))
    .map((slot) => {
      const isHeaderButton = slot.startsWith("header-button-");
      return { index: slot.split("-").at(-1), isHeaderButton };
    })
);

onMounted(() => {
  new Collapse(accordion.value, {
    toggle: false,
  });
  collapseItems.value.forEach((collapseItem, index) => {
    Collapse.getOrCreateInstance(collapseItem, {
      toggle: false,
    });
    collapseItem.addEventListener("hidden.bs.collapse", function () {
      eventsDone.value[index] = true;
    });
    collapseItem.addEventListener("shown.bs.collapse", function () {
      eventsDone.value[index] = true;
    });
  });
});
</script>

<style lang="scss" scoped>
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-color: white; // letras botón
  --bs-accordion-btn-bg: #7451c2; // background del botón
  --bs-accordion-bg: white; // background del body del acordión
}

.accordion-button:not(.collapsed) {
  background-color: #986aff;
  color: white;
  font-weight: 600;
}
</style>
