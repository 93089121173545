<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="customerForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomersServices } from "@/composables/useCustomersServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { CustomerDto } from "@/store/customers/dtos/input/Customer.dto";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { SimplePositionActionType } from "@/views/positions/PositionsView.vue";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateCustomerTemplate } from "./templates/forms/CreateOrUpdateCustomer.template";

const { customFields } = useCustomFieldsServices();
const { createCustomer, updateCustomer } = useCustomersServices();

const props = defineProps({
  initialValues: { type: Object as PropType<CustomerDto>, required: false },
  action: {
    type: String as PropType<SimplePositionActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<CustomerDto> = ref();
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

function callBackCreate(customer: CustomerDto) {
  emit("handleSubmit", { customer, isCreating: true });
}

function callBackEdit(customer: CustomerDto) {
  emit("handleSubmit", {
    customer,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted: customer } = form.value;
  if ("id" in customer) {
    await updateCustomer(customer, callBackEdit);
  } else {
    await createCustomer(customer, callBackCreate);
  }
}

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateCustomerTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value
    );
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss"></style>
