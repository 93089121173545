import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { StepDto } from "@/store/workplaces-flow/dtos/input/WorkplaceFlow.dto";
import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { get } from "lodash";

export function StepDtoToStepForm(steps: Array<StepDto>): WorkplaceStepForm[] {
  return steps.map(({ workplace, workTime, step }) => {
    return {
      workplace: {
        id: get(workplace, "id"),
        label: translateFromKey(workplace, "name"),
        item: workplace,
        items: [workplace],
      },
      workTime,
      step,
    };
  });
}
