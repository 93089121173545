import axiosInstance from "@/middlewares/Routing.middleware";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { UserDto } from "../dto/input/user.dto";
import { CreateUserDto } from "../dto/output/create-user.dto";
import { UpdateUserDto } from "../dto/output/update-user.dto";

export async function GetUsers(payload: GetTableDataDto) {
  return await axiosInstance.get("/users/", { params: payload });
}

export async function CreateUser(payload: CreateUserDto): Promise<UserDto> {
  return (await axiosInstance.post("/users/", payload)).data;
}

export async function UpdateUser(payload: UpdateUserDto): Promise<UserDto> {
  const { id, ...user } = payload;
  return (await axiosInstance.put(`/users/${id}`, user)).data;
}

export async function EnableUser(id: number): Promise<UserDto> {
  return (await axiosInstance.put(`/users/${id}/enable`)).data;
}

export async function DisableUser(id: number): Promise<UserDto> {
  return (await axiosInstance.put(`/users/${id}/disable`)).data;
}

export async function DeleteUser(id: number): Promise<void> {
  await axiosInstance.delete(`/users/${id}/delete`);
}
