import axiosInstance from "@/middlewares/Routing.middleware";
import QueryString from "qs";
import { MakeExportDto } from "../dtos/MakeExport.dto";

export const ExportFile = async (
  moduleName: string,
  payload: MakeExportDto
): Promise<void> => {
  const options = {
    params: payload,
  };
  if (payload.filters) {
    options["paramsSerializer"] = (params) => {
      return QueryString.stringify(params, {
        arrayFormat: "repeat",
        encode: false,
      });
    };
  }
  await axiosInstance.get(`${moduleName.replace("_", "-")}/export`, options);
};
