import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { CreateProfileDto } from "@/store/profiles/dtos/output/CreateProfile.dto";
import { UpdateProfileDto } from "@/store/profiles/dtos/output/UpdateProfile.dto";
import { ProfileTranslateFormInterface } from "@/store/profiles/interfaces/ProfileTranslateForm.interface";
import { SimpleProfileActionType } from "@/views/profiles/ProfilesView.vue";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateProfileTemplate } from "./templates/forms/CreateOrUpdateProfile.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateProfile',
  props: {
  initialValues: { type: Object as PropType<ProfileDto>, required: false },
  action: { type: String as PropType<SimpleProfileActionType>, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { permissions } = usePermissionsServices();
const { createProfile, updateProfile } = useProfilesServices();
const { t } = useI18n();

const props = __props;

const initialValuesRef: Ref<ProfileDto> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates: Ref<ProfileTranslateFormInterface> = ref({});
const translateFormId = ref();
const emit = __emit;

async function handleTranslateSubmit() {
  const { values }: { values: ProfileTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(profile: ProfileDto) {
  emit("handleSubmit", { profile, isCreating: true });
}

function callBackEdit(profile: ProfileDto) {
  emit("handleSubmit", {
    profile,
    isCreating: false,
  });
}

async function handleSubmit() {
  const {
    valuesFormatted,
  }: { valuesFormatted: CreateProfileDto | UpdateProfileDto } = form.value;
  const profile: CreateProfileDto | UpdateProfileDto = merge(
    {},
    valuesFormatted,
    translates.value
  );
  if ("id" in profile) {
    await updateProfile(profile, callBackEdit);
  } else {
    await createProfile(profile, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  [initialValuesRef],
  async () => {
    const metadata = get(initialValuesRef.value, "metadata");
    translates.value = metadata ? { metadata } : {};
  },
  { deep: true, immediate: true }
);

watch(
  [initialValuesRef, permissions],
  async () => {
    formTemplateRef.value = CreateOrUpdateProfileTemplate(
      permissions.value,
      props.action,
      handleSubmit,
      handleTranslateButton
    );
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "profileForm",
          "initial-values": initialValuesRef.value,
          "is-view": __props.action === 'view'
        }, null, 8, ["form", "initial-values", "is-view"]))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          ref_key: "modal",
          ref: modal,
          modalId: "translateModal",
          modalName: modalTitle.value,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "translateForm",
              ref: translateForm,
              form: formTranslateRef.value,
              "form-name": "translateForm",
              key: translateFormId.value,
              "initial-values": translates.value,
              "is-view": __props.action === 'view'
            }, null, 8, ["form", "initial-values", "is-view"]))
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})