import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { TechnicalSheetDto } from "@/store/technical-sheets/dtos/input/TechnicalSheet.dto";
import { UpdateTechnicalSheetDto } from "@/store/technical-sheets/dtos/output/UpdateTechnicalSheet.dto";
import {
  GetTechnicalSheet,
  UpdateTechnicalSheet,
} from "@/store/technical-sheets/services/TechnicalSheet.service";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useTechnicalSheetsServices(productId: number) {
  const { t } = useI18n();
  const alias = "technicalSheets";

  const technicalSheet: Ref<TechnicalSheetDto> = ref();

  const getTechnicalSheet = async (): Promise<void> => {
    await GetTechnicalSheet(productId)
      .then((technicalSheetTemp: TechnicalSheetDto) => {
        technicalSheet.value = technicalSheetTemp;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateTechnicalSheet = async (
    payload: UpdateTechnicalSheetDto,
    callBackUpdate: (product: ProductDto) => void
  ): Promise<void> => {
    await UpdateTechnicalSheet(payload)
      .then((product: ProductDto) => {
        callBackUpdate(product);
        useToast().successToast(t("technicalSheets.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return { technicalSheet, getTechnicalSheet, updateTechnicalSheet };
}
