import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { DivisionOption } from "@/store/divisions/interfaces/DivisionOption.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";
import { StoreDto } from "@/store/stores/dtos/input/Store.dto";
import { StoreFormInterface } from "@/store/stores/interfaces/StoreForm.interface";
import { get } from "lodash";

export const StoreDtoToStoreForm = (store: StoreDto): StoreFormInterface => {
  const id = get(store, "id", false);
  const name = get(store, "name");
  const division = formatOption<SimpleDivision, DivisionOption>(
    get(store, "division"),
    ["id"],
    ["name"]
  );
  const customFields = get(store, "customFields");
  return {
    store: { ...(id === false ? {} : { id }), name, division, customFields },
  };
};
