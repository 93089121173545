<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t(title) }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary mr-2"
                @click="() => openOffcanvasForm('insert')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("inventories.form.insert") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('customField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="$t(offCanvasTitle)"
    :initialWidth="30"
  >
    <template v-slot:headerComplement>
      <ButtonField
        v-if="action === 'view'"
        :button-field="
          editButton(() => runAction('modify', tempRow, lastRowIndex))
        "
      >
      </ButtonField>
    </template>
    <template v-slot:content>
      <CustomFields v-if="action === 'customField'" ref="form"></CustomFields>
      <InventoryTransactionForm
        v-else-if="['insert', 'dispatch', 'modify', 'view'].includes(action)"
        ref="form"
        :initial-values="tempRow"
        :actionType="action as SimpleInventoryActionType"
        @handle-submit="handleSubmit"
        :key="currentFormId"
      ></InventoryTransactionForm>
    </template>
  </GeneralOffcanvas>
  <GeneralTable
    v-if="headerTable"
    :dataSource="dataSource"
    :filters="tableFilters"
    :headers="headerTable"
    name="usersTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import InventoryTransactionForm from "@/components/inventories/InventoryTransactionForm.vue";
import { InventoriesHeader } from "@/components/inventories/templates/headers/Inventory.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { ProductInventoryDto } from "@/store/inventories/dtos/input/ProductInventory.dto";
import { RawMaterialInventoryDto } from "@/store/inventories/dtos/input/RawMaterialInventory.dto";
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { InventoryActionType } from "./types/InventoryAction.type";
import { SimpleInventoryActionType } from "./types/SimpleInventoryAction.type";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const route = useRoute();
const { lang } = useLanguageServices();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const lastRowIndex: Ref<number> = ref();
const action: Ref<InventoryActionType> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductInventoryDto | RawMaterialInventoryDto | null> =
  ref();
const currentFormId = ref();

const moduleName: string = route.meta.moduleName as string;

const isProductModule = computed(() => {
  return moduleName !== "raw_materials_inventories";
});

const dataSource = computed(() => {
  if (isProductModule.value) {
    return "/products-inventories/";
  }
  return "/raw-materials-inventories/";
});

const title = computed(() => {
  if (isProductModule.value) {
    return "inventories.productsTitle";
  }
  return "inventories.rawMaterialsTitle";
});

const offCanvasTitle = computed(() => {
  if (action.value === "customField") {
    return "customFields.title";
  } else if (action.value === "insert") {
    if (isProductModule.value) {
      return "inventories.form.insertProductTitle";
    } else {
      return "inventories.form.insertRawMaterialTitle";
    }
  } else if (action.value === "dispatch") {
    if (isProductModule.value) {
      return "inventories.form.dispatchProductTitle";
    } else {
      return "inventories.form.dispatchRawMaterialTitle";
    }
  } else if (action.value === "modify") {
    if (isProductModule.value) {
      return "inventories.form.modifyProductTitle";
    } else {
      return "inventories.form.modifyRawMaterialTitle";
    }
  } else if (action.value === "view") {
    if (isProductModule.value) {
      return "inventories.form.viewProductTitle";
    } else {
      return "inventories.form.viewRawMaterialTitle";
    }
  }
  return "";
});

function handleSubmit(data) {
  const { inventories } = data;
  inventories.map((inventory) => {
    tableRef.value.addOrUpdateRow("id", inventory);
  });
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: SimpleInventoryActionType,
  row: ProductInventoryDto | RawMaterialInventoryDto,
  index: number
) {
  if (actionType === "modify") {
    openOffcanvasForm("modify");
  } else if (actionType === "view") {
    openOffcanvasForm("view");
  } else {
    openOffcanvasForm("dispatch");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function openOffcanvasForm(newAction: InventoryActionType) {
  tempRow.value = null;
  action.value = newAction;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields, lang],
    () => {
      headerTable.value = InventoriesHeader(
        runAction,
        customFields.value,
        isProductModule.value
      );
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
