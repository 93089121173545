<template>
  <div class="row">
    <div class="col-12">
      <GeneralForm
        v-if="mainTemplate"
        ref="form"
        :form="mainTemplate"
        formName="create"
        :initial-values="props.initialValues"
      >
        <template
          v-for="(_, index) in 1000"
          :key="`input-master-section-1-field-${index}-subField-0`"
          v-slot:[`input-master-section-1-field-${index}-subField-0`]
        >
          <ButtonField
            :button-field="{
              ...ButtonTranslateTemplate,
              action: () => openTranslate(index),
            }"
          ></ButtonField>
        </template>
      </GeneralForm>
    </div>
    <div v-if="false" class="col-12">
      <GeneralModal
        :modalId="`settingsModal${form?.formId}`"
        :modalName="$t('customFields.settings')"
        ref="settingsModal"
        data-bs-backdrop="false"
        class="p-4"
      >
        <GeneralForm
          :form="settingsForm"
          formName="settings"
          ref="settingsForm"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
    <div class="col-12">
      <GeneralModal
        modalId="translateModal"
        ref="translateModal"
        :modalName="`${$t('customFields.translates')}`"
        data-bs-backdrop="false"
        class="p-4"
      >
        <GeneralForm
          :form="translateTemplate"
          :formName="`translateForm`"
          ref="translateForm"
          :key="translateFormId"
          :initialValues="translates"
        >
        </GeneralForm>
      </GeneralModal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { CreateCustomFieldDto } from "@/store/customFields/dtos/output/CreateCustomField.dto";
import { UpdateCustomFieldDto } from "@/store/customFields/dtos/output/UpdateCustomField.dto";
import { Modal } from "bootstrap";
import { get, merge, unset } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref,
} from "vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { AddNameTranslateTemplate } from "./templates/forms/AddNameTranslate.template";
import { AddOptionTranslateTemplate } from "./templates/forms/AddOptionTranslate.template";
import { CreateOrUpdateCustomFieldTemplate } from "./templates/forms/CreateOrUpdateCustomField.template";
import { ButtonTranslateTemplate } from "./templates/forms/CreateOrUpdateSelectSettings.template";

const emits = defineEmits(["handleSubmit"]);

const props = defineProps({
  initialModuleName: { type: String, required: false },
  initialValues: { type: Object as PropType<CustomFieldDto>, required: false },
  isCreating: { type: Boolean, required: true },
});

const { createCustomField, updateCustomField } = useCustomFieldsServices(
  props.initialModuleName
);
// Templates
const mainTemplate = ref();
const translateTemplate = ref();
const settingsTemplate = ref();
// Modals
const translateModal = ref();
const settingsModal = ref();
// Data
const translates = ref({
  metadata: { language: get(props, "initialValues.metadata.language") },
});
const settings = ref();
// Forms
const form = ref();
const settingsForm = ref();
const translateForm = ref();
// Counters
const translateFormId = ref();

const customFieldType = computed(() => {
  return get(form, "value.values.customField.type");
});

async function openTranslate(index?: number) {
  if (index === undefined) {
    translateTemplate.value = AddNameTranslateTemplate(handleSubmitTranslate);
  } else {
    translateTemplate.value = AddOptionTranslateTemplate(
      handleSubmitTranslate,
      `_${index}`
    );
  }
  translateFormId.value = uuidv4();
  Modal.getOrCreateInstance(translateModal.value.$el).show();
}

function openSettings() {
  console.log("Abriendo settings...");
}

async function submitSettings() {
  console.log("Mergeando settings...");
  Modal.getOrCreateInstance(settingsModal.value.$el).hide();
}

async function handleSubmitTranslate() {
  const { values } = translateForm.value;
  translates.value = merge({}, translates.value, values);
  Modal.getInstance(translateModal.value.$el)?.hide();
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const customField = merge({}, valuesFormatted, translates.value);
  clearTranslates(customField);
  if ("id" in customField) {
    await updateCustomField(customField);
  } else {
    await createCustomField(customField);
  }
  emits("handleSubmit");
}

function clearTranslates(
  customField: CreateCustomFieldDto | UpdateCustomFieldDto
) {
  const options = customField.metadata.options;
  const optionsTranslates = get(customField, "metadata.language.options", {});
  for (const langKey in optionsTranslates) {
    Object.keys(get(optionsTranslates, `${langKey}`, {})).forEach(
      (optionKey) => {
        if (!Object.keys(options).includes(optionKey)) {
          unset(
            customField,
            `metadata.language.options.${langKey}.${optionKey}`
          );
        }
      }
    );
  }
}

onMounted(() => {
  const options = Object.keys(
    get(props, "initialValues.metadata.options", {
      _0: "A",
    })
  );
  const customFieldId = get(props, "initialValues.id");
  mainTemplate.value = CreateOrUpdateCustomFieldTemplate(
    customFieldType.value,
    "id" in (props.initialValues ?? {}),
    handleSubmit,
    openSettings,
    openTranslate,
    options,
    customFieldId
  );
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
