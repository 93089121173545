import { useLanguageServices } from "@/composables/useLanguageServices";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { OrderDto } from "@/store/orders/dtos/input/Order.dto";
import { OrderDetailForm } from "@/store/orders/interfaces/OrderDetailForm.interface";
import { ProductOption } from "@/store/products/interfaces/ProductOption.interface";
import { SimpleProduct } from "@/store/products/interfaces/SimpleProduct.interface";
import { get } from "lodash";

export const DetailsDtoToDetailsForm = (order: OrderDto): OrderDetailForm[] => {
  const { lang } = useLanguageServices();

  return get(order, "details", []).map((detail) => {
    const discount = detail.data.discount;
    const quantity = detail.data.quantity;
    const discountType = {
      id: get(detail, "data.discountType", 0),
      label: t(
        `orderDetail.entity.discountType.options.${get(
          detail,
          "data.discountType",
          0
        )}`
      ),
      item: get(detail, "data.discountType", 0),
      items: [get(detail, "data.discountType", 0)],
    };
    const product = formatOption<SimpleProduct, ProductOption>(
      get(detail, "product"),
      ["id"],
      [`catalogMetadata.language.[${lang.value}]`, "name"]
    );
    return {
      detail: {
        discount,
        quantity,
        product,
        discountType,
      },
    };
  });
};
