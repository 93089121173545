import axiosInstance from "@/middlewares/Routing.middleware";
import { OrderDto } from "../dtos/input/Order.dto";
import { CreateOrderDto } from "../dtos/output/CreateOrder.dto";
import { UpdateOrderDto } from "../dtos/output/UpdateOrder.dto";

export async function CreateOrder(payload: CreateOrderDto): Promise<OrderDto> {
  return (await axiosInstance.post(`orders`, payload)).data;
}

export async function UpdateOrder(payload: UpdateOrderDto): Promise<OrderDto> {
  const { id, ...orderProps } = payload;
  return (await axiosInstance.put(`orders/${id}`, orderProps)).data;
}

export async function DeleteOrder(id: number): Promise<void> {
  await axiosInstance.delete(`orders/${id}`);
}
