import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

const { langValues } = useLanguageServices();

export const AddOptionTranslateTemplate = (action, option: string): Form => {
  return {
    sections: [
      {
        fields: langValues.value.map((language) => {
          return {
            path: `metadata.language.options.${language.key}.${option}`,
            inputName: `customFieldOptionTranslate.${option}.${language.key}`,
            label: {
              value: `${language.name}`,
              needsTranslate: false,
            },
            type: "text",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          };
        }),
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "customFields.save",
        needsTranslate: true,
      },
      action,
    },
  };
};
