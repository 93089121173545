import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "alert alert-warning",
  role: "alert"
}
const _hoisted_2 = ["innerHTML"]

import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { DeleteDivisionTemplate } from "./templates/forms/DeleteDivision.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteDivision',
  props: {
  handleSubmit: {
    type: Object as PropType<
      (values: { newId: { id: number; label: string } }) => void
    >,
    required: true,
  },
  currentRow: {
    type: Object as PropType<DivisionDto>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const deletionFormTemplate = ref();
const form = ref();
const currentRowRef: Ref<DivisionDto> = toRef(props, "currentRow");

const name = computed(() => {
  return currentRowRef.value.name ?? "";
});

const id = computed(() => {
  return currentRowRef.value?.id ?? "";
});

watch(
  [currentRowRef],
  async () => {
    deletionFormTemplate.value = DeleteDivisionTemplate(async () => {
      await props.handleSubmit(form.value.values);
    }, props.currentRow.id);
    await nextTick();
    form.value?.setValues({ newId: "" });
  },
  { deep: true, immediate: true }
);

__expose({ ...form });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        innerHTML: _ctx.$t('divisions.warnings.deleteAndReplace', { name: name.value, id: id.value })
      }, null, 8, _hoisted_2)
    ]),
    (deletionFormTemplate.value)
      ? (_openBlock(), _createBlock(GeneralForm, {
          key: 0,
          form: deletionFormTemplate.value,
          formName: "deleteForm",
          ref_key: "form",
          ref: form
        }, null, 8, ["form"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})