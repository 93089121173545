<template>
  <div
    class="row"
    v-if="currentField.conditions ? currentField.conditions() : true"
  >
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div
            :class="`${
              currentField.fieldOptions?.requireCard ? 'card p-3' : 'w-100'
            } `"
          >
            <div class="row">
              <div
                v-for="(oneField, index) in currentField.fields"
                :key="`${props.sectionIndex}-${props.fieldIndex}-${index}`"
                :class="`col-lg-${oneField.widthDistribution ?? 12}`"
              >
                <InputMaster
                  :field="{
              ...oneField,
              buttons: [
                ...(oneField?.buttons ?? []),
                ...(index === currentField.fields.length - 1 && currentField?.fieldOptions?.requireButtonDelete && currentField?.fieldOptions?.deleteDistribution === 'end'
                  ? [
                      {
                        ...removeInstance,
                        ...(fieldIndex ? {} : { isDisabled: true }),
                        metadata: {
                          positionInField:'end',
                        }
                      },
                    ]
                  : []),
              ] as ButtonTemplate[],
            }"
                  :errorBag="errorBag"
                  :disabled-default-option="true"
                  :section-index="sectionIndex"
                  :field-index="fieldIndex"
                  :sub-field-index="index"
                >
                  <template
                    #input-master="{ sectionIndex, fieldIndex, subfieldIndex }"
                  >
                    <slot
                      name="input-master"
                      :section-index="sectionIndex"
                      :field-index="fieldIndex"
                      :subfield-index="subfieldIndex"
                    ></slot>
                  </template>
                </InputMaster>
                <div class="col-12">
                  <div v-if="false">
                    {{
                      `section-${sectionIndex}-field-${fieldIndex}-subField-${index}`
                    }}
                  </div>
                  <slot
                    name="dynamic-slot"
                    :section-index="sectionIndex"
                    :field-index="fieldIndex"
                    :subfield-index="index"
                  ></slot>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-center mt-3"
                v-if="
                  currentField?.fieldOptions?.requireButtonDelete &&
                  currentField?.fieldOptions?.deleteDistribution === 'bottom'
                "
              >
                <div class="">
                  <ButtonField
                    :button-field="{
                      ...removeInstance,
                      ...(fieldIndex ? {} : { isDisabled: true }),
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-auto mx-auto"
      v-if="
        fieldIndex === lastValidFieldIndex &&
        currentField?.fieldOptions?.requireButtonAdd
      "
    >
      <ButtonField :buttonField="addButtonTemplate"></ButtonField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import {
  Field,
  Fields,
} from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { cloneDeep, get } from "lodash";
import { computed, defineProps, PropType, Ref, ref, toRef } from "vue";
import ButtonField from "../buttons/ButtonField.vue";
import { addButton } from "../buttons/templates/AddButton.template";
import { deleteButton } from "../buttons/templates/DeleteButton.template";

const props = defineProps({
  form: { type: Object as PropType<Form>, required: true },
  errorBag: {
    type: Object,
    required: true,
  },
  sectionIndex: { type: Number, required: true },
  fieldIndex: { type: Number, required: true },
  debug: { type: Boolean, required: false, default: false },
});

const formRef: Ref<Form> = toRef(props, "form");
const fieldInstance = cloneDeep(
  formRef.value.sections[props.sectionIndex].fields[0]
);
const addButtonTemplate = ref(
  addButton(() => {
    if ("fields" in fieldInstance) {
      const fields = fieldInstance.fields.map((field) => {
        field.path = field.path.replace("0", String(props.fieldIndex + 1));
        field.inputName = field.inputName + props.fieldIndex + 2;
        return field;
      });
      formRef.value.sections[props.sectionIndex].fields.push({
        ...cloneDeep(fieldInstance),
        fields,
      });
    }
  })
);

const removeInstance = deleteButton(() => {
  formRef.value.sections[props.sectionIndex].fields[
    props.fieldIndex
  ].conditions = () => {
    return false;
  };
});

const fieldsLength = computed(() => {
  return formRef.value.sections[props.sectionIndex].fields.length;
});

const currentField: Ref<Fields> = computed(() => {
  const field: Fields =
    formRef.value.sections[props.sectionIndex].fields[props.fieldIndex];
  field.fields.forEach((fieldTemp: Field) => {
    if (fieldTemp?.placeholder?.params) {
      fieldTemp.placeholder.params = { index: String(props.fieldIndex + 1) };
    }
  });
  return field;
});

const lastValidFieldIndex = computed(() => {
  for (let i = fieldsLength.value - 1; i > -1; i--) {
    const condition =
      formRef.value?.sections?.[props.sectionIndex]?.fields?.[i]?.conditions;
    if (condition ? condition() : true) {
      return i;
    }
  }

  return 0;
});
</script>

<style lang="scss"></style>
