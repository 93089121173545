<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="profileForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        />
      </div>
      <div class="col-12">
        <GeneralModal
          ref="modal"
          modalId="translateModal"
          :modalName="modalTitle"
          data-bs-backdrop="false"
          class="p-4"
        >
          <GeneralForm
            ref="translateForm"
            :form="formTranslateRef"
            form-name="translateForm"
            :key="translateFormId"
            :initial-values="translates"
            :is-view="action === 'view'"
          ></GeneralForm>
        </GeneralModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { CreateProfileDto } from "@/store/profiles/dtos/output/CreateProfile.dto";
import { UpdateProfileDto } from "@/store/profiles/dtos/output/UpdateProfile.dto";
import { ProfileTranslateFormInterface } from "@/store/profiles/interfaces/ProfileTranslateForm.interface";
import { SimpleProfileActionType } from "@/views/profiles/ProfilesView.vue";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  defineEmits,
  defineExpose,
  defineProps,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateProfileTemplate } from "./templates/forms/CreateOrUpdateProfile.template";

const { permissions } = usePermissionsServices();
const { createProfile, updateProfile } = useProfilesServices();
const { t } = useI18n();

const props = defineProps({
  initialValues: { type: Object as PropType<ProfileDto>, required: false },
  action: { type: String as PropType<SimpleProfileActionType>, required: true },
});

const initialValuesRef: Ref<ProfileDto> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates: Ref<ProfileTranslateFormInterface> = ref({});
const translateFormId = ref();
const emit = defineEmits(["handleSubmit"]);

async function handleTranslateSubmit() {
  const { values }: { values: ProfileTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(profile: ProfileDto) {
  emit("handleSubmit", { profile, isCreating: true });
}

function callBackEdit(profile: ProfileDto) {
  emit("handleSubmit", {
    profile,
    isCreating: false,
  });
}

async function handleSubmit() {
  const {
    valuesFormatted,
  }: { valuesFormatted: CreateProfileDto | UpdateProfileDto } = form.value;
  const profile: CreateProfileDto | UpdateProfileDto = merge(
    {},
    valuesFormatted,
    translates.value
  );
  if ("id" in profile) {
    await updateProfile(profile, callBackEdit);
  } else {
    await createProfile(profile, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  [initialValuesRef],
  async () => {
    const metadata = get(initialValuesRef.value, "metadata");
    translates.value = metadata ? { metadata } : {};
  },
  { deep: true, immediate: true }
);

watch(
  [initialValuesRef, permissions],
  async () => {
    formTemplateRef.value = CreateOrUpdateProfileTemplate(
      permissions.value,
      props.action,
      handleSubmit,
      handleTranslateButton
    );
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
