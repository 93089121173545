import axiosInstance from "@/middlewares/Routing.middleware";
import { ProductDto } from "../dtos/input/Product.dto";
import { CreateProductDto } from "../dtos/output/CreateProduct.dto";
import { UpdateProductDto } from "../dtos/output/UpdateProduct.dto";

export async function CreateProduct(
  payload: CreateProductDto
): Promise<ProductDto> {
  return (await axiosInstance.post(`products`, payload)).data;
}

export async function UpdateProduct(
  payload: UpdateProductDto
): Promise<ProductDto> {
  const { id, ...productProps } = payload;
  return (await axiosInstance.put(`products/${id}`, productProps)).data;
}

export async function DeleteProduct(id: number): Promise<void> {
  await axiosInstance.delete(`products/${id}`);
}
