import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }
const _hoisted_4 = { class: "card p-3 mb-3" }
const _hoisted_5 = { class: "col text-start" }
const _hoisted_6 = { class: "col-auto p-0" }
const _hoisted_7 = { class: "col-auto pl-0" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-center" }

import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import draggable from "vuedraggable";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateStepTemplate } from "./templates/forms/CreateOrUpdateStep.template";
import WorkplaceFlowStep from "./WorkplaceFlowStep.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateStep',
  props: {
  steps: {
    type: Object as PropType<Array<WorkplaceStepForm>>,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const props = __props;

const stepForm = ref();
const stepFormTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const stepsRef: Ref<Array<WorkplaceStepForm>> = toRef(props, "steps");
const tempSteps: Ref<WorkplaceStepForm[]> = ref();
const addWorkplaceFlowButtonTemplate = ref(
  addButton(async () => {
    await handleAddOrEditStep(tempSteps.value.length);
  })
);
const formId = ref();
const currentIndex = ref();

const excludeOptions = computed(() => {
  return tempSteps.value.map(({ workplace }) => {
    return workplace;
  });
});

const emit = __emit;

async function handleSubmitStepForm(index: number) {
  const { values } = stepForm.value;
  tempSteps.value[index] = merge({}, tempSteps.value[index] ?? {}, values, {
    step: index + 1,
  });
  modal.value.closeModal();
}

async function handleAddOrEditStep(index: number) {
  currentIndex.value = index;
  const isCreating = tempSteps.value.length <= index;
  modalTitle.value = isCreating
    ? t("workplacesFlows.form.addStep")
    : t("workplacesFlows.form.updateStep");
  stepFormTemplateRef.value = CreateOrUpdateStepTemplate(
    () => handleSubmitStepForm(index),
    isCreating
  );
  stepFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  if (!isCreating) {
    await nextTick();
    stepForm.value.setValues(tempSteps.value[index]);
  }
  formId.value = uuidv4();
  modal.value.openModal();
}

watch(
  stepsRef,
  () => {
    tempSteps.value = stepsRef.value;
  },
  { deep: true, immediate: true }
);

watch(
  tempSteps,
  () => {
    let isChanged = false;
    tempSteps.value.forEach((step, index) => {
      if (step.step !== index + 1) {
        step.step = index + 1;
        isChanged = true;
      }
    });
    if (isChanged) {
      emit("update:modelValue", tempSteps.value);
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms: () => stepForm.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-1" }, [
        _createElementVNode("hr")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)("workplacesFlows.form.title")), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col pl-1" }, [
        _createElementVNode("hr")
      ], -1))
    ]),
    _createVNode(_unref(draggable), {
      modelValue: tempSteps.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tempSteps).value = $event)),
      group: "people",
      "item-key": "step"
    }, {
      item: _withCtx(({ element, index }) => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: element.step
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(WorkplaceFlowStep, { step: element }, null, 8, ["step"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(ButtonField, {
                "button-field": 
                _unref(editButton)(() => {
                  handleAddOrEditStep(index);
                })
              
              }, null, 8, ["button-field"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(ButtonField, {
                "button-field": 
                _unref(deleteButton)(() => {
                  tempSteps.value.splice(index, 1);
                })
              
              }, null, 8, ["button-field"])
            ])
          ]))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(ButtonField, { buttonField: addWorkplaceFlowButtonTemplate.value }, null, 8, ["buttonField"])
    ]),
    _createVNode(GeneralModal, {
      ref_key: "modal",
      ref: modal,
      modalId: "step",
      modalName: modalTitle.value,
      "data-bs-backdrop": "false",
      class: "p-4"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "stepForm",
          ref: stepForm,
          form: stepFormTemplateRef.value,
          "form-name": "workplacesFlowForm",
          key: formId.value,
          initialValues: tempSteps.value[currentIndex.value]
        }, null, 8, ["form", "initialValues"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})