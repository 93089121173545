<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="divisionForm"
          :initial-values="initialValuesRef"
          :is-view="action === 'view'"
        >
          <div class="my-3">
            <MapMaster
              :latitude="coordinates.latitude"
              :longitude="coordinates.longitude"
            />
          </div>
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { DivisionDto } from "@/store/divisions/dtos/input/Division.dto";
import { SimpleDivisionActionType } from "@/views/divisions/DivisionsView.vue";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import MapMaster from "../globals/inputs/MapMaster.vue";
import { CreateOrUpdateDivisionTemplate } from "./templates/forms/CreateOrUpdateDivision.template";

const { customFields } = useCustomFieldsServices();
const { createDivision, updateDivision } = useDivisionsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<DivisionDto>, required: false },
  action: {
    type: String as PropType<SimpleDivisionActionType>,
    required: true,
  },
});

const initialValuesRef: Ref<DivisionDto> = ref();
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = defineEmits(["handleSubmit"]);

const coordinates = computed(() => {
  const [latitude, longitude] = form?.value?.values?.division?.location?.split(
    ","
  ) ?? [19.432608, -99.133209];
  return {
    latitude: (!isNaN(latitude) && Number(latitude)) || 19.432608,
    longitude: (!isNaN(longitude) && Number(longitude)) || -99.133209,
  };
});

function callBackCreate(division: DivisionDto) {
  emit("handleSubmit", { division, isCreating: true });
}

function callBackEdit(division: DivisionDto) {
  emit("handleSubmit", {
    division,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted: division } = form.value;
  if (!initialValuesRef.value) {
    await createDivision(division, callBackCreate);
  } else {
    await updateDivision(division, callBackEdit);
  }
}

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateDivisionTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value
    );
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
</style>
