import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { MakeExportDto } from "@/store/import-export/dtos/MakeExport.dto";
import { MakeImportDto } from "@/store/import-export/dtos/MakeImport.dto";
import { ExportFile } from "@/store/import-export/services/ExportFile.service";
import { ImportFile } from "@/store/import-export/services/ImportFile.service";
import { useRoute } from "vue-router";
import { useToast } from "./useToastServices";

export function useImportExportServices() {
  const route = useRoute();
  const moduleName: string = route.meta.moduleName as string;

  const importFile = async (payload: MakeImportDto): Promise<void> => {
    await ImportFile(moduleName, payload)
      .then(async () => {
        useToast().successToast(t("importAndExport.success.imported"));
      })
      .catch(({ response }) => {
        showToastError(moduleName, response.messageCode);
      });
  };

  const exportFile = async (payload: MakeExportDto): Promise<void> => {
    await ExportFile(moduleName, payload)
      .then(async () => {
        useToast().successToast(t("importAndExport.success.exported"));
      })
      .catch(({ response }) => {
        showToastError(moduleName, response.messageCode);
      });
  };

  return { importFile, exportFile };
}
