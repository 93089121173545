import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { t } from "@/shared/locales/services/i18n.services";
import { DiscountTypes } from "@/store/orders/enums/DiscountTypes.enum";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { SimpleOrderActionType } from "@/views/orders/types/SimpleOrderAction.type";

export const CreateOrUpdateDetailTemplate = (
  action: () => void,
  actionType: SimpleOrderActionType
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "detail.product",
            inputName: "detailProductId",
            label: {
              value: "orderDetail.entity.product",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              dataSource: "/products/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              labelKeyMetadata: (item) => {
                const product = item as ProductDto;
                return ` $${product.price}`;
              },
              isMultiple: false,
              searchPath: "name",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
          {
            path: "detail.quantity",
            inputName: "detailQuantity",
            label: {
              value: "orderDetail.entity.quantity",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
          {
            path: "detail.discount",
            inputName: "detailDiscount",
            label: {
              value: "orderDetail.entity.discount",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
          {
            path: "detail.discountType",
            inputName: "detailDiscountType",
            label: {
              value: "orderDetail.entity.discountType.title",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "value",
              labelKey: "name",
              isMultiple: false,
              initialOptions: [
                {
                  name: t("orderDetail.entity.discountType.options.0"),
                  value: DiscountTypes.AMOUNT,
                },
                {
                  name: t("orderDetail.entity.discountType.options.1"),
                  value: DiscountTypes.PERCENTAGE,
                },
              ],
            },
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
          },
        ],
      },
    ],
    submit: {
      text: {
        value:
          actionType === "create"
            ? "orderDetail.form.add"
            : "orderDetail.form.update",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
