import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { ProductFamilyDto } from "@/store/product-families/dtos/input/ProductFamily.dto";
import { CreateProductFamilyDto } from "@/store/product-families/dtos/output/CreateProductFamily.dto";
import { DeleteProductFamilyDto } from "@/store/product-families/dtos/output/DeleteProductFamily.dto";
import { UpdateProductFamilyDto } from "@/store/product-families/dtos/output/UpdateProductFamily.dto";
import {
  CreateProductFamily,
  DeleteProductFamily,
  UpdateProductFamily,
} from "@/store/product-families/services/ProductFamily.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useProductFamiliesServices() {
  const { t } = useI18n();
  const alias = "productFamilies";

  const createProductFamily = async (
    payload: CreateProductFamilyDto,
    callBackCreate: (productFamily: ProductFamilyDto) => void
  ): Promise<void> => {
    await CreateProductFamily(payload)
      .then(async (productFamily: ProductFamilyDto) => {
        callBackCreate(productFamily);
        useToast().successToast(t("productFamilies.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateProductFamily = async (
    payload: UpdateProductFamilyDto,
    callBackUpdate: (productFamily: ProductFamilyDto) => void
  ): Promise<void> => {
    await UpdateProductFamily(payload)
      .then(async (productFamily: ProductFamilyDto) => {
        callBackUpdate(productFamily);
        useToast().successToast(t("productFamilies.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteProductFamily = async (
    payload: DeleteProductFamilyDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteProductFamily(payload)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("productFamilies.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createProductFamily,
    updateProductFamily,
    deleteProductFamily,
  };
}
