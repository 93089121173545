import { Fields } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { t } from "@/shared/locales/services/i18n.services";
import { CustomFieldForm } from "@/store/customFields/interfaces/CustomFieldForm.interface";
import { get } from "lodash";
import { CustomFieldDtoToCustomFieldForm } from "../../formatters/CustomFieldDtoToCustomFieldForm.formatter";
import { CustomFieldFormToCustomFieldDto } from "../../formatters/CustomFieldFormToCustomFieldDto.formatter";
import { ButtonTranslateTemplate } from "./CreateOrUpdateSelectSettings.template";

export const GetOptions = (
  options: string[],
  customFieldId: string,
  index: number
): Fields => {
  return {
    conditions: () => {
      return options.includes(`_${index}`);
    },
    fields: [
      {
        path: `customField.metadata.options._${index}`,
        inputName: `CustomFieldOption.${customFieldId}._${index}`,
        placeholder: {
          value: "customFields.option",
          needsTranslate: true,
        },
        type: "text",
        metadata: {
          tooltipPosition: "top",
        },
        methodsYup: () => {
          return [stringYup, requiredYup()];
        },
        widthDistribution: 12,
      },
    ],
    fieldOptions: {
      requireButtonDelete: true,
      requireButtonAdd: true,
      deleteDistribution: "end",
    },
  };
};

export const CreateOrUpdateCustomFieldTemplate = (
  customFieldType: "text" | "number" | "date" | "select" | "multiple-select",
  isCreating: boolean,
  action: () => Promise<void>,
  openSettings: () => void,
  openTranslate: (index?: number) => void,
  options: string[],
  customFieldId: string
): Form => {
  const maxOption = Math.max(
    ...options.map((item) => Number(item.replace("_", "")))
  );
  return {
    sections: [
      {
        fields: [
          {
            path: "customField.name",
            inputName: `customFieldName.${customFieldId}`,
            label: {
              value: "customFields.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
            buttons: [{ ...ButtonTranslateTemplate, action: openTranslate }],
          },
          {
            path: "customField.type",
            inputName: `customFieldType.${customFieldId}`,
            label: {
              value: "customFields.type",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "value",
              labelKey: "name",
              isMultiple: false,
              initialOptions: [
                {
                  name: t("customFields.types.text"),
                  value: "text",
                },
                {
                  name: t("customFields.types.number"),
                  value: "number",
                },
                {
                  name: t("customFields.types.select"),
                  value: "select",
                },
                {
                  name: t("customFields.types.multipleSelect"),
                  value: "multiple-select",
                },
                {
                  name: t("customFields.types.date"),
                  value: "date",
                },
              ],
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup()];
            },
            buttons: [
              {
                conditions(value) {
                  if (["text", "number", "multiple-select"].includes(value)) {
                    return true;
                  }
                  return false;
                },
                backgroundColor: "#7451c2",
                customClass: "ml-1",
                disableCustomClass: "disabled",
                graphic: {
                  icon: {
                    icon: "bi bi-gear",
                    color: "white",
                    backgroundColor: "#7451c2",
                  },
                },
                action: openSettings,
                metadata: {
                  isAppend: false,
                  positionInField: "end",
                },
              },
            ],
            disabled: isCreating,
          },
          {
            path: "customField.required",
            inputName: `isCustomFieldRequired.${customFieldId}`,
            label: {
              value: "customFields.required",
              needsTranslate: true,
            },
            type: "checkbox",
            metadata: {
              labelPosition: "right",
              tooltipPosition: "top",
            },
          },
        ],
      },
      {
        conditions: (values?: CustomFieldForm) => {
          return ["select", "multiple-select"].includes(
            get(values, "customField.type.id")
          );
        },
        name: { value: "customFields.optionsValues", needsTranslate: true },
        fields: Array(maxOption + 1)
          .fill(undefined)
          .map((_value, index) => {
            return GetOptions(options, customFieldId, index);
          }),
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "customFields.updateField"
          : "customFields.addField",
        needsTranslate: true,
      },
      action,
    },
    formatterDtoToForm: CustomFieldDtoToCustomFieldForm,
    formatterFormToDto: CustomFieldFormToCustomFieldDto,
  };
};
