import { useLanguageServices } from "@/composables/useLanguageServices";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { PermissionOption } from "@/store/permissions/interfaces/PermissionOption.interface";
import { SimplePermission } from "@/store/permissions/interfaces/SimplePermission.interface";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { ProfileFormInterface } from "@/store/profiles/interfaces/ProfileForm.interface";
import { get } from "lodash";

export const ProfileDtoToProfileForm = (
  profile: ProfileDto
): ProfileFormInterface => {
  const { lang } = useLanguageServices();
  const id = get(profile, "id", false);
  const name = profile.name;
  const description = profile.description;
  const permissions = profile.permissions.map((permission) => {
    return formatOption<SimplePermission, PermissionOption>(
      permission,
      ["id"],
      [`translates.name.${lang.value}`, "name"]
    );
  });
  return {
    profile: {
      ...(id === false ? {} : { id }),
      name,
      description,
      permissions,
    },
  };
};
