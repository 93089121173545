<template>
  <AuthCard>
    <template v-slot:content>
      <div class="row justify-content-center">
        <div class="col-12 d-flex align-items-center">
          <h2 class="textBlack">
            {{ $t("auth.carouselTabs.changePassword.primaryTitle") }}
          </h2>
        </div>
      </div>
      <div class="row justify-content-between mt-2 mb-1">
        <div class="col-auto">
          <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
          <span class="actionableLabel" @click="goToLogin">{{
            $t("auth.carouselTabs.forgotPassword.hrefs.signUp")
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <GeneralForm
            ref="formRef"
            :form="changePasswordForm"
            formName="forgotPassword"
          ></GeneralForm>
        </div>
      </div>
    </template>
  </AuthCard>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { AuthTypes } from "@/store/auth/types/Credentials.type";
import { get } from "lodash";
import {
  defineEmits,
  defineProps,
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { ChangePasswordTemplate as changePasswordTemplate } from "./templates/forms/ChangePassword.template";

const props = defineProps({
  temporalToken: { type: String },
  initialValues: {
    type: Object as PropType<AuthTypes>,
    required: true,
  },
});

const { changePassword } = useAuthServices();

const emit = defineEmits(["goToSlide", "update:modelValue"]);

const formRef = ref();
const initialValuesRef = toRef(props, "initialValues");

const changePasswordForm = ref(changePasswordTemplate(submitChangePassword));

function goToLogin(): void {
  formRef.value.resetForm({});
  const email = get(initialValuesRef, "value.email");
  emit("update:modelValue", { email });
  emit("goToSlide", 1);
}

async function submitChangePassword(): Promise<void> {
  const { values } = formRef.value;
  const { newPassword, oldPassword } = values;
  await changePassword(
    { newPassword, oldPassword },
    props.temporalToken,
    goToLogin
  );
}

onMounted(() => {
  watch(
    initialValuesRef,
    () => {
      const oldPassword = get(initialValuesRef, "value.oldPassword");
      formRef.value.setValues({ oldPassword });
    },
    { deep: true, immediate: true }
  );
});
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnForgotPassword {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
