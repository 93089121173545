import axiosInstance from "@/middlewares/Routing.middleware";
import { CustomFieldDto } from "../dtos/input/CustomField.dto";
import { CreateCustomFieldDto } from "../dtos/output/CreateCustomField.dto";
import { UpdateCustomFieldDto } from "../dtos/output/UpdateCustomField.dto";

export async function CreateCustomField(
  moduleName: string,
  payload: CreateCustomFieldDto
): Promise<CustomFieldDto> {
  const { data } = await axiosInstance.post(`custom-fields`, {
    ...payload,
    module: { name: moduleName },
  });
  return data;
}

export async function UpdateCustomField(
  moduleName: string,
  payload: UpdateCustomFieldDto
): Promise<CustomFieldDto> {
  const { data } = await axiosInstance.put(`custom-fields/${payload.id}`, {
    ...payload,
    module: { name: moduleName },
  });
  return data;
}

export async function DeleteCustomField(id: string) {
  return await axiosInstance.delete(`custom-fields/${id}`);
}
