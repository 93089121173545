import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { ProductInventoryDto } from "@/store/inventories/dtos/input/ProductInventory.dto";
import { RawMaterialInventoryDto } from "@/store/inventories/dtos/input/RawMaterialInventory.dto";
import { SimpleInventoryActionType } from "@/views/inventories/types/SimpleInventoryAction.type";

export const InventoriesHeader = (
  runAction: (
    actionType: SimpleInventoryActionType,
    inventory: ProductInventoryDto | RawMaterialInventoryDto,
    index: number
  ) => void,
  customFields: CustomFieldDto[],
  isProductModule: boolean
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const { lang } = useLanguageServices();
  return [
    {
      sortable: true,
      value: { value: "inventories.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    isProductModule
      ? {
          sortable: true,
          value: {
            value: "inventories.entity.product",
            needsTranslate: true,
          },
          key: "product",
          mainKey: "product",
          mappedKey: [`metadata.language.name.${lang.value}`, "name"],
          columnType: "tag",
          width: "150px",
          filterType: "select",
          selectOptions: {
            dataSource: "/products/",
            idKey: "id",
            labelKey: [`metadata.language.name.${lang.value}`, "name"],
            isMultiple: false,
            searchPath: "name",
          },
        }
      : {
          sortable: true,
          value: {
            value: "inventories.entity.rawMaterial",
            needsTranslate: true,
          },
          key: "rawMaterial",
          mainKey: "rawMaterial",
          mappedKey: [
            `metadata.language.description.${lang.value}`,
            "description",
          ],
          columnType: "tag",
          width: "150px",
          filterType: "select",
          selectOptions: {
            dataSource: "/raw-materials/",
            idKey: "id",
            labelKey: [
              `metadata.language.description.${lang.value}`,
              "description",
            ],
            isMultiple: false,
            searchPath: "description",
          },
        },
    {
      sortable: true,
      value: { value: "inventories.entity.store", needsTranslate: true },
      key: "store",
      mainKey: "store",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "150px",
      filterType: "select",
      selectOptions: {
        dataSource: "/stores/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    {
      sortable: true,
      value: { value: "inventories.entity.lotCode", needsTranslate: true },
      key: "lotCode",
      mappedKey: "lotCode",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "inventories.entity.quantity", needsTranslate: true },
      key: "quantity",
      mappedKey: "quantity",
      columnType: "number",
      width: "100px",
      filterType: "number",
    },
    {
      sortable: true,
      value: {
        value: "inventories.entity.expirationDate",
        needsTranslate: true,
      },
      key: "expirationDate",
      mappedKey: "expirationDate",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    ...customFieldsHeaders,
    {
      sortable: false,
      value: { value: "inventories.tables.actions", needsTranslate: true },
      columnType: "action",
      options: (
        inventory: ProductInventoryDto | RawMaterialInventoryDto,
        index: number
      ): Menu => {
        const options = [
          {
            label: {
              value: "inventories.tables.actionList.dispatch",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("dispatch", inventory, index),
          },
          {
            label: {
              value: "inventories.tables.actionList.modify",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("modify", inventory, index),
          },
          {
            label: {
              value: "inventories.tables.actionList.view",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("view", inventory, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
