import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "offcanvas-header" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "row justify-content-between" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { class: "offcanvas-body" }
const _hoisted_10 = { class: "offcanvas-child" }

import { Offcanvas } from "bootstrap";
import {
  computed,
  onMounted,
  ref,
} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralOffcanvas',
  props: {
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
  childProps: { type: Object, required: false },
  initialWidth: { type: Number, required: false },
},
  emits: ["closed"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const offCanvasRef = ref();
const isOpen = ref(false);
const childrenRef = ref();

const width = computed(() => props.initialWidth ?? 30);

function setRef(el) {
  childrenRef.value = el;
}

function closeOffcanvas() {
  emit("closed");
  isOpen.value = false;
}

function openOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).show();
    isOpen.value = true;
  }
}

function closeOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).hide();
    isOpen.value = false;
  }
}

const offset = computed(() => {
  if (childIsOpen.value) {
    return childOffset.value + childWidth.value - width.value / 2;
  }
  return 0;
});

const childWidth = computed(() => {
  return childrenRef.value?.width ?? 0;
});

const childOffset = computed(() => {
  return childrenRef.value?.offset ?? 0;
});

const childIsOpen = computed(() => {
  return childrenRef.value?.isOpen ?? false;
});

onMounted(() => {
  offCanvasRef.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});

__expose({ isOpen, width, openOffCanvas, closeOffCanvas });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "offcanvas offcanvas-end",
        style: _normalizeStyle(`width: ${width.value}em !important; transform: translateX(-${offset.value}em);`),
        tabindex: "-1",
        id: __props.canvasId,
        "aria-labelledby": `${__props.canvasId}Label`,
        "data-bs-backdrop": true,
        ref_key: "offCanvasRef",
        ref: offCanvasRef
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h5", {
                  class: "offcanvas-title text-start",
                  id: `${__props.canvasId}Label`
                }, _toDisplayString(__props.canvasName), 9, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "headerComplement"),
                  _createElementVNode("div", { class: "col-auto d-flex align-items-center pl-0" }, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn-close",
                      "data-bs-dismiss": "offcanvas",
                      "aria-label": "Close",
                      onClick: closeOffcanvas
                    })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _renderSlot(_ctx.$slots, "content"),
          _createElementVNode("div", {
            class: _normalizeClass(`${
            isOpen.value && childIsOpen.value ? 'offcanvas-backdrop fade show' : ''
          }`)
          }, null, 2)
        ])
      ], 12, _hoisted_1),
      _createElementVNode("div", _hoisted_10, [
        _renderSlot(_ctx.$slots, "offcanvasChild", { setRef: setRef })
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`${isOpen.value && childIsOpen.value ? 'offcanvas-backdrop fade show' : ''}`)
    }, null, 2)
  ], 64))
}
}

})