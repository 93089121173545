import { CreateProfileDto } from "@/store/profiles/dtos/output/CreateProfile.dto";
import { UpdateProfileDto } from "@/store/profiles/dtos/output/UpdateProfile.dto";
import { ProfileFormInterface } from "@/store/profiles/interfaces/ProfileForm.interface";
import { get } from "lodash";

export const ProfileFormToProfileDto = (
  profile: ProfileFormInterface
): CreateProfileDto | UpdateProfileDto => {
  const id = get(profile, "profile.id", false);
  const name = get(profile, "profile.name");
  const description = get(profile, "profile.description");
  const permissions = get(profile, "profile.permissions", []).map(
    (permission) => {
      return {
        id: permission.id,
      };
    }
  );
  return { ...(id === false ? {} : { id }), name, description, permissions };
};
