import { Module } from "vuex";
import { PositionDto } from "./dtos/input/Position.dto";

interface State {
  positions?: Partial<PositionDto>[];
}

export const positionsStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    positions: [],
  },
  getters: {},
  mutations: {
    setPositions(state, positions: Partial<PositionDto>[]) {
      state.positions = positions;
    },
    addPosition(state, position: Partial<PositionDto>) {
      state.positions = [...state.positions, position];
    },
    updatePosition(state, position: Partial<PositionDto>) {
      state.positions = state.positions.filter((p) => p.id !== position.id);
      state.positions = [...state.positions, position];
    },
    deletePosition(state, position: Partial<PositionDto>) {
      state.positions = state.positions.filter((p) => p.id !== position.id);
    },
  },
  actions: {},
  modules: {},
};
