<template>
  <div v-if="debug">value: {{ value }}</div>
  <div :key="field.path">
    <div class="row g-1">
      <div class="col-12 text-start">
        <label
          v-if="mergedMetadata?.labelPosition === 'top' && props.field.label"
          :for="props.field.inputName"
          class="fw-medium fs-6"
        >
          <ProcessText :value="props.field.label"></ProcessText>
        </label>
      </div>
      <div class="col-12">
        <div v-if="isView" class="row g-0 flex-nowrap">
          <div
            v-if="mergedMetadata?.labelPosition === 'left' && props.field.label"
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pr-1"
              :id="`${props.field.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.field.label"></ProcessText>
            </span>
          </div>
          <div
            class="col-auto"
            v-for="(startButton, startButtonIndex) in formButtons.startButtons"
            :key="`startButton${props.field.inputName}-${startButtonIndex}`"
          >
            <ButtonField
              v-if="
                startButton.conditions ? startButton.conditions(value) : true
              "
              :button-field="startButton"
            ></ButtonField>
          </div>
          <div
            :class="`${
              props.field.type == 'checkbox' ? 'col-auto my-auto' : 'col'
            }`"
          >
            <div :id="`${props.field.inputName}InputDropdown`">
              <div
                class="d-flex justify-content-center align-items-center w-100 input-group"
              >
                <div
                  v-for="(
                    startButton, startButtonIndex
                  ) in formButtons.startAppendButtons"
                  :key="`startAppendButton-${props.field.inputName}-${startButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      startButton.conditions
                        ? startButton.conditions(value)
                        : true
                    "
                    :button-field="startButton"
                  ></ButtonField>
                </div>
                <FilesMaster
                  v-if="
                    ['file'].includes(props.field.type) &&
                    props.field.fileOptions
                  "
                  v-model="value"
                  :file-options="props.field.fileOptions"
                  :initialFiles="value"
                  class="w-100"
                >
                  <template #file-preview="{ fileIndex }">
                    <div v-if="debug">
                      {{
                        `file-preview-${sectionIndex}-${fieldIndex}-${fileIndex}`
                      }}
                    </div>
                    <slot
                      name="file-preview"
                      :sectionIndex="sectionIndex"
                      :fieldIndex="fieldIndex"
                      :fileIndex="fileIndex"
                    >
                    </slot>
                  </template>
                </FilesMaster>
                <SelectMaster
                  v-else-if="
                    ['select', 'multiple-select'].includes(props.field.type) &&
                    props.field.selectOptions
                  "
                  :field="props.field"
                  :initialValue="value"
                  :isValidClass="isValidClassComputed"
                  v-model="value"
                ></SelectMaster>
                <b v-else-if="props.field.type === 'date'" class="w-100">
                  {{ formatDate(value) }}
                </b>
                <b v-else class="w-100">{{ value ?? "-" }}</b>
                <div
                  v-for="(
                    endButton, endButtonIndex
                  ) in formButtons.endAppendButtons"
                  :key="`endAppendButton-${props.field.inputName}-${endButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      endButton.conditions ? endButton.conditions(value) : true
                    "
                    :button-field="endButton"
                  ></ButtonField>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-auto"
            v-for="(endButton, endButtonIndex) in formButtons.endButtons"
            :key="`endButton${props.field.inputName}-${endButtonIndex}`"
          >
            <ButtonField
              v-if="endButton.conditions ? endButton.conditions(value) : true"
              :button-field="endButton"
            ></ButtonField>
          </div>
          <div
            v-if="
              mergedMetadata?.labelPosition === 'right' && props.field.label
            "
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pl-1"
              :id="`${props.field.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.field.label"></ProcessText>
            </span>
          </div>
        </div>
        <div v-else class="row g-0 flex-nowrap">
          <div
            v-if="mergedMetadata?.labelPosition === 'left' && props.field.label"
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pr-1"
              :id="`${props.field.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.field.label"></ProcessText>
            </span>
          </div>
          <div
            class="col-auto"
            v-for="(startButton, startButtonIndex) in formButtons.startButtons"
            :key="`startButton${props.field.inputName}-${startButtonIndex}`"
          >
            <ButtonField
              v-if="
                startButton.conditions ? startButton.conditions(value) : true
              "
              :button-field="startButton"
            ></ButtonField>
          </div>
          <div
            :class="`${
              props.field.type == 'checkbox' ? 'col-auto my-auto' : 'col'
            }`"
          >
            <div :id="`${props.field.inputName}InputDropdown`">
              <div
                class="d-flex justify-content-center align-items-center w-100 input-group"
                @mouseover="showDropdown"
                @mouseleave="hideDropdown"
              >
                <div
                  v-for="(
                    startButton, startButtonIndex
                  ) in formButtons.startAppendButtons"
                  :key="`startAppendButton-${props.field.inputName}-${startButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      startButton.conditions
                        ? startButton.conditions(value)
                        : true
                    "
                    :button-field="startButton"
                  ></ButtonField>
                </div>
                <FilesMaster
                  v-if="
                    ['file'].includes(props.field.type) &&
                    props.field.fileOptions
                  "
                  v-model="value"
                  :file-options="props.field.fileOptions"
                  :initialFiles="value"
                  class="w-100"
                >
                  <template #file-preview="{ fileIndex }">
                    <div v-if="debug">
                      {{
                        `file-preview-${sectionIndex}-${fieldIndex}-${fileIndex}`
                      }}
                    </div>
                    <slot
                      name="file-preview"
                      :sectionIndex="sectionIndex"
                      :fieldIndex="fieldIndex"
                      :fileIndex="fileIndex"
                    >
                    </slot>
                  </template>
                </FilesMaster>
                <SelectMaster
                  v-else-if="
                    ['select', 'multiple-select'].includes(props.field.type) &&
                    props.field.selectOptions
                  "
                  :field="props.field"
                  :initialValue="value"
                  :isValidClass="isValidClassComputed"
                  v-model="value"
                ></SelectMaster>
                <textarea
                  v-else-if="props.field.type == 'textarea'"
                  :name="props.field.inputName"
                  :class="`${getInputClass} ${isValidClassComputed} m-0`"
                  :id="props.field.inputName"
                  :placeholder="getPlaceholder()"
                  :aria-describedby="`${props.field.inputName}GroupPrepend ${props.field.inputName}Feedback`"
                  v-model="value"
                  :disabled="field.disabled"
                  @keyup.enter="() => emit('enter')"
                ></textarea>
                <input
                  v-else
                  :type="inputType"
                  :name="props.field.inputName"
                  :class="`${getInputClass} ${isValidClassComputed} m-0`"
                  :id="props.field.inputName"
                  :placeholder="getPlaceholder()"
                  :aria-describedby="`${props.field.inputName}GroupPrepend ${props.field.inputName}Feedback`"
                  v-model="value"
                  :disabled="field.disabled"
                  :step="`${props.field.type === 'decimal' ? '.01' : ''}`"
                  @keyup.enter="() => emit('enter')"
                  @blur="props?.field?.onBlur"
                />
                <ButtonField
                  v-if="field.type === 'password'"
                  :button-field="eyeButtonTemplate"
                ></ButtonField>
                <div
                  v-for="(
                    endButton, endButtonIndex
                  ) in formButtons.endAppendButtons"
                  :key="`endAppendButton-${props.field.inputName}-${endButtonIndex}`"
                >
                  <ButtonField
                    v-if="
                      endButton.conditions ? endButton.conditions(value) : true
                    "
                    :button-field="endButton"
                  ></ButtonField>
                </div>
              </div>
            </div>
            <ul
              :ref="props.field.inputName"
              class="dropdown-menu"
              :id="`${props.field.inputName}DropdownMenu`"
            >
              <div v-if="errorBag && errorBag[field.path]">
                <li
                  v-for="(error, index) in errorBag[field.path]"
                  :key="`${error}+${index}+${props.field.label}`"
                  :class="`mx-3 ${
                    error.valid ? 'text-success' : 'text-danger'
                  } text-nowrap`"
                >
                  <span v-html="error.error"></span>
                </li>
              </div>
            </ul>
          </div>
          <div class="col-auto">
            <div v-if="false">
              {{
                `input-master-${sectionIndex}-field-${fieldIndex}-subField-${subFieldIndex}`
              }}
            </div>
            <slot
              name="input-master"
              :section-index="sectionIndex"
              :field-index="fieldIndex"
              :subfield-index="subFieldIndex"
            ></slot>
          </div>
          <div
            class="col-auto"
            v-for="(endButton, endButtonIndex) in formButtons.endButtons"
            :key="`endButton${props.field.inputName}-${endButtonIndex}`"
          >
            <ButtonField
              v-if="endButton.conditions ? endButton.conditions(value) : true"
              :button-field="endButton"
            ></ButtonField>
          </div>

          <div
            v-if="
              mergedMetadata?.labelPosition === 'right' && props.field.label
            "
            class="col-auto my-auto text-start"
          >
            <span
              class="fw-medium fs-6 pl-1"
              :id="`${props.field.inputName}GroupPrepend`"
            >
              <ProcessText :value="props.field.label"></ProcessText>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { Dropdown } from "bootstrap";
import { merge } from "lodash";
import { useField } from "vee-validate";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  inject,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ProcessText from "../UiTools/ProcessText.vue";
import ButtonField from "../buttons/ButtonField.vue";
import { eyeClosedButton } from "../buttons/templates/EyeClosedButton.template";
import { eyeOpenedButton } from "../buttons/templates/EyeOpenedButton.template";
import FilesMaster from "./FilesMaster.vue";
import SelectMaster from "./SelectMaster.vue";

const { t } = useI18n();

const props = defineProps({
  field: {
    type: Object as PropType<Field>,
    required: true,
  },
  errorBag: {
    type: Object,
    required: false,
  },
  disabledDefaultOption: {
    type: Boolean,
    required: false,
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: false,
  },
  fieldIndex: {
    type: Number,
    required: false,
  },
  subFieldIndex: {
    type: Number,
    required: false,
  },
});

const emit = defineEmits(["update:modelValue", "enter"]);
const isView = inject("isView");

const dropdown: Ref<Dropdown> = ref(undefined);
const passwordType = ref("password");

const { value, meta, errors, setValue, ...propsField } = useField<any>(
  props.field.path
);
const eyeButtonTemplate = computed(() => {
  if (passwordType.value === "password") {
    return eyeOpenedButton(() => {
      passwordType.value = "text";
    });
  }
  return eyeClosedButton(() => {
    passwordType.value = "password";
  });
});

const inputType = computed(() => {
  if (props.field.type === "decimal") {
    return "number";
  }
  if (props.field.type === "password") {
    return passwordType.value;
  }
  return props.field.type;
});

function showDropdown(): void {
  if (
    props.errorBag &&
    props.errorBag[props.field.path] &&
    props.errorBag[props.field.path].length > 0
  ) {
    dropdown.value?.show?.();
  }
}

function hideDropdown(): void {
  if (props.errorBag && props.errorBag[props.field.path]) {
    dropdown.value?.hide?.();
  }
}

const getInputClass = computed(() => {
  if (props.field.type == "checkbox") {
    return "form-check-input";
  }
  return "form-control";
});
const getPlaceholder = (): string => {
  if (!props.field.placeholder) return "";
  if (props.field.placeholder.needsTranslate) {
    return t(props.field.placeholder.value, props.field.placeholder.params);
  }
  return props.field.placeholder.value;
};

const mergedMetadata = computed(() => {
  const defaultMetadata = {
    tooltipPosition: "right",
    iconPosition: "end",
    labelPosition: "top",
    fieldSettings: {
      color: "blue",
    },
  };
  return merge({}, defaultMetadata, props.field.metadata);
});

const formButtons = computed(() => {
  return {
    startAppendButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && button.metadata.isAppend
    ),
    endAppendButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && button.metadata.isAppend
    ),
    startButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && !button.metadata.isAppend
    ),
    endButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && !button.metadata.isAppend
    ),
  };
});

const isValidClassComputed = computed(() => {
  if (
    props.errorBag &&
    ((errors.value.length > 0 && meta.validated) || value.value)
  ) {
    const isValid = props.errorBag[props.field.path]
      ? props.errorBag[props.field.path].every((e) => e.valid)
      : true;
    if (isValid) {
      return "is-valid";
    }
    return "is-invalid";
  }
  return "";
});

const windowWidth = ref();
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

watch(value, () => {
  emit("update:modelValue", value.value);
});

onMounted(() => {
  watch(
    windowWidth,
    () => {
      let tooltipPosition = mergedMetadata.value.tooltipPosition;
      if (
        windowWidth.value < 992 &&
        (tooltipPosition === "left" || tooltipPosition === "right")
      ) {
        tooltipPosition = "bottom";
      }
      try {
        dropdown.value = new Dropdown(
          `#${props.field.inputName}InputDropdown`,
          {
            display: "dynamic",
            popperConfig: function (defaultBsPopperConfig) {
              defaultBsPopperConfig.placement = tooltipPosition;
              return defaultBsPopperConfig;
            },
          }
        );
      } catch (e) {
        console.error(e);
      }
    },
    { immediate: true }
  );
});

defineExpose({ value, meta, errors, setValue, ...propsField });
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.iconInsideInput {
  position: relative;
  left: -1.5em;
  top: 0.6em;
}

button:active {
  transform: scale(0.95);
}
</style>
