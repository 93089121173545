import axiosInstance from "@/middlewares/Routing.middleware";
import { ProfileDto } from "../dtos/input/Profile.dto";
import { CreateProfileDto } from "../dtos/output/CreateProfile.dto";
import { DeleteProfileDto } from "../dtos/output/DeleteProfile.dto";
import { UpdateProfileDto } from "../dtos/output/UpdateProfile.dto";

export async function GetProfiles(): Promise<ProfileDto[]> {
  return (await axiosInstance.get("/profiles")).data;
}

export async function CreateProfile(
  payload: CreateProfileDto
): Promise<ProfileDto> {
  return (await axiosInstance.post(`profiles`, payload)).data;
}

export async function UpdateProfile(
  payload: UpdateProfileDto
): Promise<ProfileDto> {
  return (await axiosInstance.put(`profiles`, payload)).data;
}

export async function DeleteProfile(payload: DeleteProfileDto): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`profiles?newId=${newId}&oldId=${oldId}`);
}
