import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = {
  key: 1,
  class: "w-100 d-flex flex-wrap"
}

import { useTablesServices } from "@/composables/useTablesServices";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { StatusInterface } from "@/shared/globals/helpers/Catalogs.helper";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { get } from "lodash";
import {
  computed,
  inject,
  nextTick,
  onMounted,
  PropType,
  ref,
  Ref,
  toRef,
  toRefs,
  watch,
} from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import StatusBadge from "../UiTools/StatusBadge.vue";

type OptionType = { id: string; label: string; item?: any; items?: any[] };

const offset = 6;

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectMaster',
  props: {
  field: {
    type: Object as PropType<Field>,
    required: true,
  },
  initialValue: {
    type: Object as PropType<any>,
    required: true,
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
  isValidClass: {
    type: Object as PropType<"" | "is-valid" | "is-invalid">,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const isView = inject("isView");

const isSelectable = (option: OptionType): boolean => {
  if (!option) {
    return false;
  }
  const index =
    field.value.selectOptions.excludeOptions?.findIndex((opt) => {
      return opt.id === option.id;
    }) ?? -1;
  if (index > -1) {
    return false;
  }
  if (Array.isArray(value.value)) {
    return !value.value.find((opt) => opt.id === option.id);
  }
  return value.value?.id !== option.id;
};

const emits = __emit;

const props = __props;

const field: Ref<Field> = toRef(props, "field");
const isValidClassRef = toRef(props, "isValidClass");
const initialValueRef: Ref<any> = toRef(props, "initialValue");
const observer = ref();
const load = ref();
const items = ref([]);
const tableProps = ref();
const orderBy = ref("id");
const orderType: Ref<"ASC" | "DESC"> = ref("DESC");
const value: Ref<OptionType | Array<OptionType>> = ref();
const selector = ref();
const isFocused = ref(false);

const values = computed(() => {
  return (Array.isArray(value.value) ? value.value : [value.value]).filter(
    (option) => option
  );
});

const classList = computed(() => {
  return `${isFocused.value ? "selector" : ""} ${isValidClassRef.value} ${
    isFocused.value && isValidClassRef.value === "is-invalid"
      ? "is-invalid-focused"
      : ""
  }
  ${isValidClassRef.value} ${
    isFocused.value && isValidClassRef.value === "is-valid"
      ? "is-valid-focused"
      : ""
  }
  `;
});

const dataSourceRef = computed(() => {
  return field.value.selectOptions.dataSource;
});

const idKey = computed(() => {
  return field.value.selectOptions.idKey;
});

const labelKey: Ref<string | string[]> = computed(() => {
  return field.value.selectOptions.labelKey;
});

const isMultiple: Ref<boolean> = computed(() => {
  return field.value.selectOptions.isMultiple;
});

const initialOptionsRef: Ref<any> = computed(() => {
  return field.value?.selectOptions?.initialOptions ?? [];
});

const disabled: Ref<boolean> = computed(() => {
  return field.value.disabled;
});

const headers: Ref<TableHeader[]> = ref([
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "id",
    mappedKey: idKey.value,
    columnType: "number",
    width: "0px",
    filterType: "text",
  },
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "label",
    mappedKey: labelKey.value,
    columnType: "text",
    width: "0px",
    filterType: "text",
  },
]);

const options: Ref<OptionType[]> = computed(() => {
  return items.value.map((item) => {
    return formatElement(item);
  });
});

async function onClose() {
  observer.value?.disconnect();
  isFocused.value = false;
}

async function onSearch(query: string, loading) {
  loading(true);
  if (dataSourceRef.value) {
    const { currentPage, filters, applyFilters, currentData } = toRefs(
      tableProps.value
    );
    currentPage.value = 1;
    filters.value = {};
    if (query.length > 2) {
      applyFilters.value({
        value: `${query}`,
        path: field.value.selectOptions.searchPath,
      });
      await getElementList();
      items.value = currentData.value;
    } else if (query.length === 0) {
      await getElementList();
      items.value = currentData.value;
    }
  } else {
    if (query.length > 0) {
      items.value = items.value.filter((item) => {
        const itemFormatted = formatElement(item);
        return itemFormatted.label
          .toLowerCase()
          ?.startsWith(query.toLowerCase());
      });
    } else {
      items.value = initialOptionsRef.value;
    }
  }
  loading(false);
}

function hasNextPage() {
  const { currentPage, totalPages } = toRefs(tableProps.value);
  return currentPage.value <= totalPages.value;
}

async function infiniteScroll(scrollProps) {
  const [{ isIntersecting, target }] = scrollProps;
  if (isIntersecting) {
    const ul = target.offsetParent;
    const scrollTop = target.offsetParent.scrollTop;
    await getElementList();
    await nextTick();
    ul.scrollTop = scrollTop;
  }
}

async function getElementList(forceLoad = false) {
  const { currentData, getTableData, currentPage } = toRefs(tableProps.value);
  if (hasNextPage() || forceLoad) {
    await getTableData.value();
    if (currentPage.value === 1) {
      items.value = [];
    }
    items.value = [...items.value, ...currentData.value];
    currentPage.value++;
  }
}

function formatElement(item: any): OptionType {
  if (isNotValidItemOrItems(item)) {
    let itemFormatted;
    if (item) {
      if (Array.isArray(labelKey.value)) {
        for (const key of labelKey.value) {
          itemFormatted = {
            id: get(item, idKey.value, false),
            label: get(item, key, false),
          };
          if (itemFormatted.id && itemFormatted.label) {
            break;
          }
        }
      } else {
        itemFormatted = {
          id: get(item, idKey.value, false),
          label: get(item, labelKey.value, false),
        };
      }
      itemFormatted = { ...itemFormatted, item, items: items.value };
      itemFormatted.label =
        itemFormatted.label +
        (field.value.selectOptions.labelKeyMetadata?.(item) ?? "");
      return itemFormatted ?? { id: "", label: "" };
    }
    return { id: "", label: "" };
  }
  return item;
}

const isNotValidItemOrItems = (item: any | any[]) => {
  if (item) {
    if (Array.isArray(item)) {
      return item.some((item) => {
        return Object.keys(item) && !(item && "label" in item && "id" in item);
      });
    }
    return Object.keys(item) && !(item && "label" in item && "id" in item);
  }
  return false;
};

const onOpen = () => {
  isFocused.value = true;
};

watch(
  initialValueRef,
  () => {
    if (isNotValidItemOrItems(initialValueRef.value)) {
      if (Array.isArray(initialValueRef.value)) {
        value.value = initialValueRef.value.map((item) => {
          return formatElement(item);
        });
      } else {
        value.value = formatElement(initialValueRef.value);
      }
    } else if (
      JSON.stringify(value.value) !== JSON.stringify(initialValueRef.value)
    ) {
      value.value = initialValueRef.value;
    }
  },
  { deep: true }
);

watch(
  [load],
  async () => {
    if (load.value && observer.value) {
      await nextTick();
      observer.value?.observe(load.value);
    }
  },
  { immediate: true, deep: true }
);

watch(value, () => {
  if (value.value === null) {
    emits("update:modelValue", "");
  } else {
    emits("update:modelValue", value.value);
  }
});

onMounted(async () => {
  items.value = initialOptionsRef.value;
  value.value = props.initialValue;
  if (dataSourceRef.value) {
    tableProps.value = useTablesServices(
      dataSourceRef.value,
      headers,
      orderBy,
      orderType,
      offset
    );
    await getElementList(true);
    observer.value = new IntersectionObserver(infiniteScroll);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "value: " + _toDisplayString(value.value), 1))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Options: " + _toDisplayString(options.value), 1))
            : _createCommentVNode("", true),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "initialOptions: " + _toDisplayString(initialOptionsRef.value), 1))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "initialValue: " + _toDisplayString(initialValueRef.value), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(isView))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values.value, (option, index) => {
            return (_openBlock(), _createBlock(StatusBadge, {
              key: index,
              status: {value:_unref(get)(option,'label','-'), color:'white', bgColor:'#7451c2'} as StatusInterface,
              class: "mr-2 mb-2"
            }, null, 8, ["status"]))
          }), 128)),
          _createElementVNode("b", null, _toDisplayString(values.value.length === 0 ? "-" : ""), 1)
        ]))
      : (_openBlock(), _createBlock(_unref(vSelect), {
          key: 2,
          options: options.value,
          filterable: false,
          multiple: isMultiple.value,
          onClose: onClose,
          onSearch: onSearch,
          class: _normalizeClass([`p-0 ${classList.value} w-100`, ""]),
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          selectable: isSelectable,
          disabled: disabled.value,
          closeOnSelect: !isMultiple.value,
          ref_key: "selector",
          ref: selector,
          onOpen: onOpen
        }, {
          "list-footer": _withCtx(() => [
            _withDirectives(_createElementVNode("li", {
              ref_key: "load",
              ref: load,
              class: "loader"
            }, null, 512), [
              [_vShow, hasNextPage]
            ])
          ]),
          "no-options": _withCtx(() => [
            _createElementVNode("span", { ref: "noOptions" }, _toDisplayString(_ctx.$t("global.inputs.noOptions")), 513)
          ]),
          _: 1
        }, 8, ["options", "multiple", "class", "modelValue", "disabled", "closeOnSelect"]))
  ], 64))
}
}

})