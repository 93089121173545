import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { CreateCustomFieldDto } from "@/store/customFields/dtos/output/CreateCustomField.dto";
import { UpdateCustomFieldDto } from "@/store/customFields/dtos/output/UpdateCustomField.dto";
import {
  CreateCustomField,
  DeleteCustomField,
  UpdateCustomField,
} from "@/store/customFields/services/CustomField.service";
import { GetCustomFields } from "@/store/modules/services/Module.service";
import { computed, Ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useCustomFieldsServices(initialModuleName?: string) {
  const store = useStore();
  const route = useRoute();
  const moduleName: string =
    initialModuleName ?? (route.meta.moduleName as string);
  const alias = "customFields";

  const customFields: Ref<CustomFieldDto[]> = computed(() => {
    return store?.state?.modules?.[moduleName]?.customFields ?? [];
  });

  const createCustomField = async (
    payload: CreateCustomFieldDto
  ): Promise<void> => {
    await CreateCustomField(moduleName, payload)
      .then((customField) => {
        store.commit("modules/addCustomField", {
          moduleName,
          customField: { ...payload, ...customField },
        });
        useToast().successToast(t("customFields.success.customFieldCreated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateCustomField = async (
    payload: UpdateCustomFieldDto
  ): Promise<void> => {
    await UpdateCustomField(moduleName, payload)
      .then((customField) => {
        store.commit("modules/updateCustomField", {
          moduleName,
          customField,
        });
        useToast().successToast(t("customFields.success.customFieldUpdated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteCustomField = async (id: string): Promise<void> => {
    await DeleteCustomField(id)
      .then(() => {
        store.commit("modules/deleteCustomField", { id, moduleName });
        useToast().successToast(t("customFields.success.customFieldDeleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const getModuleCustomFields = async (): Promise<void> => {
    const moduleCustomFields = await GetCustomFields(moduleName);
    store.commit("modules/setCustomFields", moduleCustomFields);
  };

  return {
    createCustomField,
    updateCustomField,
    deleteCustomField,
    getModuleCustomFields,
    customFields,
  };
}
