<template>
  <div v-if="debug">{{ values }}</div>
  <div class="container text-start" :key="String(isViewRef)">
    <form @submit="onSubmit" class="row" :key="formName">
      <div
        class="col-12"
        v-for="(section, sectionIndex) in form?.sections"
        :key="`${formName}Section-${sectionIndex}`"
      >
        <div
          v-if="section.conditions ? section.conditions(values) : true"
          class="row"
        >
          <SectionName v-if="section.name" :name="section.name" />
          <div
            v-for="(field, fieldIndex) in section?.fields"
            :key="`${formName}Section-${sectionIndex}-Field-${fieldIndex}`"
            :class="`col-lg-${field.widthDistribution ?? 12} mt-2`"
            :style="`display: ${
              field.conditions?.(values) ?? true ? 'block' : 'none'
            }`"
          >
            <div class="row" v-if="field.conditions?.(values) ?? true">
              <div :class="`col-12 mt-2`">
                <MultipleInputMaster
                  v-if="'fields' in field"
                  :form="formRef"
                  :errorBag="errorBag"
                  :sectionIndex="sectionIndex"
                  :fieldIndex="fieldIndex"
                >
                  <template
                    #dynamic-slot="{ sectionIndex, fieldIndex, subfieldIndex }"
                  >
                    <slot
                      :name="`section-${sectionIndex}-field-${fieldIndex}-subField-${subfieldIndex}`"
                    >
                    </slot>
                  </template>
                  <template
                    #input-master="{ sectionIndex, fieldIndex, subfieldIndex }"
                  >
                    <slot
                      :name="`input-master-section-${sectionIndex}-field-${fieldIndex}-subField-${subfieldIndex}`"
                    >
                    </slot>
                  </template>
                </MultipleInputMaster>
                <InputMaster
                  v-else
                  :field="field as Field"
                  :errorBag="errorBag"
                  :disabled-default-option="true"
                  :sectionIndex="sectionIndex"
                  :fieldIndex="fieldIndex"
                >
                  <template
                    #file-preview="{ sectionIndex, fieldIndex, fileIndex }"
                  >
                    <slot
                      :name="`file-preview-${sectionIndex}-${fieldIndex}-${fileIndex}`"
                    >
                    </slot>
                  </template>
                </InputMaster>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div v-if="debug">
                  {{ `section-${sectionIndex}-field-${fieldIndex}` }}
                </div>
                <slot
                  :name="`section-${sectionIndex}-field-${fieldIndex}`"
                ></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <slot></slot>
        </div>
      </div>
      <div class="col-12" v-if="!isViewRef">
        <div class="row">
          <div class="col-12">
            <div class="col-12 p-0 mt-2">
              <ButtonMaster
                v-if="form?.submit?.text"
                :text="form?.submit?.text"
                :isLoading="isSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form as FormType } from "@/shared/globals/forms/interfaces/Form.interface";
import { YupValidator } from "@/shared/globals/helpers/YupValidator";
import i18n from "@/shared/locales/i18n";
import { useForm } from "vee-validate";
import {
  computed,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  provide,
  ref,
  toRef,
  watch,
} from "vue";
import ButtonMaster from "../buttons/ButtonMaster.vue";
import MultipleInputMaster from "../inputs/MultipleInputMaster.vue";
import SectionName from "./SectionName.vue";

const props = defineProps({
  form: { type: Object as PropType<FormType>, required: true },
  formName: { type: String, required: true },
  initialValues: { type: Object as PropType<any>, required: false },
  isView: { type: Boolean, required: false, default: false },
  debug: { type: Boolean, required: false, default: false },
});

const formRef = toRef(props, "form");
const isViewRef = ref(props.isView);
const initialValuesRef = toRef(props.initialValues);
const errorBag = ref();
const valuesFormatted = ref();

const formProps = useForm();
const {
  values,
  handleSubmit,
  isSubmitting,
  setErrors,
  resetForm,
  setFieldValue,
  setValues,
} = formProps;
const yupValidator = ref(createYupValidator());

const initialValuesFormatted = computed(() => {
  if (initialValuesRef.value && formRef.value) {
    return (
      formRef.value.formatterDtoToForm?.(initialValuesRef.value) ??
      initialValuesRef.value
    );
  }
  return {};
});

const formatValues = () => {
  valuesFormatted.value = formRef.value.formatterFormToDto?.(values);
};

const onSubmit = handleSubmit(async () => {
  const isValid = await validateForm();
  if (isValid) {
    formatValues();
    await props.form.submit.action();
  }
});

async function validateForm(): Promise<boolean> {
  const validations = await yupValidator.value.getValidations(values);
  const isValid = Object.values(validations).every((validation) => {
    const value = validation as { error: string; valid: boolean }[];
    return value.every((v) => v.valid);
  });
  if (!isValid) {
    setErrors(validations);
  }
  return isValid;
}

function createYupValidator() {
  const yupValidator = new YupValidator();
  const schema = props.form
    ? props.form.sections.reduce((acc, section) => {
        if (!section.conditions || section.conditions(values)) {
          section.fields.reduce((acc, field) => {
            if (!field.conditions || field.conditions(values)) {
              if (
                !Array.isArray(field) &&
                "path" in field &&
                field.methodsYup
              ) {
                const keys = field.path.split(".");
                keys.reduce((acc, key, index, arr) => {
                  if (index === arr.length - 1) {
                    acc[key] = field.methodsYup();
                  }
                  return acc[key] || (acc[key] = {});
                }, acc);
              } else if ("fields" in field) {
                for (const oneField of field.fields) {
                  const keys = oneField.path.split(".");
                  keys.reduce((acc, key, index, arr) => {
                    if (index === arr.length - 1) {
                      acc[key] = oneField.methodsYup?.();
                    }
                    return acc[key] || (acc[key] = {});
                  }, acc);
                }
              }
            }
            return acc;
          }, acc);
        }

        return acc;
      }, {})
    : {};
  yupValidator.addSchema(schema, []);
  return yupValidator;
}

function resetAllFields() {
  resetForm();
  props.form?.sections?.forEach((section) => {
    section.fields?.forEach((field) => {
      if ("fields" in field) {
        for (const oneField of field.fields) {
          if ("type" in oneField && oneField.type === "select") {
            setFieldValue(oneField.path, "");
          }
        }
      } else if ("type" in field && field.type === "select") {
        setFieldValue(field.path, "");
      }
    });
  });
}

watch(
  [initialValuesFormatted],
  async () => {
    await nextTick();
    resetAllFields();
    setValues(initialValuesFormatted.value);
  },
  { deep: true, immediate: true }
);

watch(
  [values, formRef, i18n.global.locale],
  async () => {
    yupValidator.value = createYupValidator();
    const error = await yupValidator.value?.getValidations(values);
    errorBag.value = error;
  },
  { deep: true, immediate: true }
);

provide("isView", isViewRef);

defineExpose({ ...formProps, validateForm, resetAllFields, valuesFormatted });
</script>

<style lang="scss"></style>
