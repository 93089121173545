import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { PositionDto } from "@/store/positions/dtos/input/Position.dto";
import { CreatePositionDto } from "@/store/positions/dtos/output/CreatePosition.dto";
import { UpdatePositionDto } from "@/store/positions/dtos/output/UpdatePosition.dto";
import {
  CreatePosition,
  DeletePosition,
  UpdatePosition,
} from "@/store/positions/services/Position.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function usePositionsServices() {
  const { t } = useI18n();
  const alias = "positions";

  const createPosition = async (
    payload: CreatePositionDto,
    callBackCreate: (position: PositionDto) => void
  ): Promise<void> => {
    await CreatePosition(payload)
      .then((position: PositionDto) => {
        callBackCreate(position);
        useToast().successToast(t("positions.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updatePosition = async (
    payload: UpdatePositionDto,
    callBackUpdate: (position: PositionDto) => void
  ): Promise<void> => {
    await UpdatePosition(payload)
      .then((position: PositionDto) => {
        callBackUpdate(position);
        useToast().successToast(t("positions.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deletePosition = async (
    payload: PositionDto,
    callBackDelete: (position: PositionDto) => void
  ): Promise<void> => {
    await DeletePosition(payload.id)
      .then(() => {
        callBackDelete(payload);
        useToast().successToast(t("positions.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createPosition,
    updatePosition,
    deletePosition,
  };
}
