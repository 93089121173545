import { MeasureDto } from "@/store/measures/dtos/input/Measure.dto";
import { MeasureForm } from "@/store/measures/interfaces/MeasureForm.interface";
import { get } from "lodash";

export const MeasureDtoToMeasureForm = (measure: MeasureDto): MeasureForm => {
  const id = get(measure, "id", false);
  const name = get(measure, "name");
  const description = get(measure, "description");
  const customFields = get(measure, "customFields");
  const metadata = get(measure, "metadata");
  return {
    measure: {
      ...(id === false ? {} : { id }),
      name,
      description,
      customFields,
      metadata,
    },
  };
};
