import { createStore } from "vuex";
import { authStore } from "./auth/auth";
import { customersStore } from "./customers/customers";
import { modulesStore } from "./modules/modules";
import { permissionsStore } from "./permissions/permissions";
import { positionsStore } from "./positions/rawMaterials";
import { profilesStore } from "./profiles/profiles";
import { suppliersStore } from "./suppliers/supplier";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authStore,
    modules: modulesStore,
    profiles: profilesStore,
    permissions: permissionsStore,
    suppliers: suppliersStore,
    customers: customersStore,
    positions: positionsStore,
  },
});
