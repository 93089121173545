import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 d-flex align-items-center justify-content-between p-2" }
const _hoisted_2 = { class: "fs-5" }

import CreateOrUpdateCustomFields from "@/components/customFields/CreateOrUpdateCustomFields.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { nextTick, onMounted, Ref, ref } from "vue";
import GeneralAcordion from "../globals/acordions/GeneralAcordion.vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import ConfirmModal from "../globals/modals/ConfirmModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFields',
  props: {
  initialModuleName: { type: String, required: false },
},
  setup(__props) {

const props = __props;

const { getModuleCustomFields, customFields, deleteCustomField } =
  useCustomFieldsServices(props.initialModuleName);

const createForm = ref();
const modal = ref();
const formsId = ref([]);
const currentCustomFieldIndex: Ref<number> = ref(-1);
const messageDeletion = (): LabelType => {
  const customField = get(
    customFields,
    `value.${currentCustomFieldIndex.value}`
  );
  return {
    value: "customFields.questionDelete",
    params: {
      name: customField.name,
    },
    needsTranslate: true,
  };
};
const acordionId = ref();

const onAcordionClosed = (index: number) => {
  formsId.value[index] = uuidv4();
};

async function openDeleteCustomFieldModal(customFieldIndex: number) {
  currentCustomFieldIndex.value = customFieldIndex;
  await nextTick();
  modal.value.openConfirmationModal();
}

async function handleDeleteCustomField() {
  const customField = customFields.value[currentCustomFieldIndex.value];
  await deleteCustomField(customField.id);
  acordionId.value = uuidv4();
}

function handleCustomFieldSubmit() {
  acordionId.value = uuidv4();
}

onMounted(async () => {
  await getModuleCustomFields();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(GeneralAcordion, {
      "unique-expanded": true,
      onClosed: onAcordionClosed,
      key: acordionId.value
    }, _createSlots({
      "header-button-0": _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("customFields.create")), 1)
      ]),
      "body-0": _withCtx(() => [
        _createVNode(CreateOrUpdateCustomFields, {
          initialModuleName: __props.initialModuleName,
          ref_key: "createForm",
          ref: createForm,
          isCreating: true,
          onHandleSubmit: handleCustomFieldSubmit
        }, null, 8, ["initialModuleName"])
      ]),
      _: 2
    }, [
      _renderList(_unref(customFields), (customField, index) => {
        return {
          name: `header-${index+1}`,
          fn: _withCtx(({ toggleAcordion }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(customField.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createVNode(ButtonField, {
                  "button-field": 
              _unref(editButton)(() => {
                toggleAcordion();
              })
            
                }, null, 8, ["button-field"]),
                _createVNode(ButtonField, {
                  "button-field": 
              _unref(deleteButton)(() => openDeleteCustomFieldModal(index))
            
                }, null, 8, ["button-field"])
              ])
            ])
          ])
        }
      }),
      _renderList(_unref(customFields), (customField, index) => {
        return {
          name: `body-${index+1}`,
          fn: _withCtx(() => [
            _createElementVNode("div", null, [
              (_openBlock(), _createBlock(CreateOrUpdateCustomFields, {
                initialModuleName: __props.initialModuleName,
                "initial-values": customField,
                isCreating: false,
                key: formsId.value[index],
                onHandleSubmit: handleCustomFieldSubmit
              }, null, 8, ["initialModuleName", "initial-values"]))
            ])
          ])
        }
      })
    ]), 1024)),
    (_unref(get)(_unref(customFields), `${currentCustomFieldIndex.value}`, false))
      ? (_openBlock(), _createBlock(ConfirmModal, {
          key: 0,
          title: _ctx.$t('global.warnings.delete'),
          ref_key: "modal",
          ref: modal,
          message: messageDeletion(),
          confirmAction: 
      async () => {
        await handleDeleteCustomField();
      }
    
        }, null, 8, ["title", "message", "confirmAction"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})