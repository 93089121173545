<template>
  <div class="container-fluid">
    <div class="row" v-if="formTemplateRef">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(technicalSheet)"
          form-name="technicalSheetForm"
          :initialValues="technicalSheet"
          :is-view="false"
        >
          <template #section-0-field-0>
            <div class="row">
              <div class="col-12">
                <CreateOrUpdateRawMaterialQuantity
                  :technicalSheet="technicalSheet"
                  v-model="rawMaterials"
                />
              </div>
            </div>
          </template>
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsServices";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { get, merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateRawMaterialQuantity from "./CreateOrUpdateRawMaterialQuantity.vue";
import { CreateOrUpdateTechnicalSheetTemplate } from "./templates/forms/CreateOrUpdateTechnicalSheet.template";

const props = defineProps({
  productId: { type: Number, required: true },
});

const { technicalSheet, getTechnicalSheet, updateTechnicalSheet } =
  useTechnicalSheetsServices(props.productId);

const manufactureSpecificationDetailsCounter = computed(() => {
  const counter = get(
    technicalSheet,
    "value.product.manufactureSpecification.details",
    []
  ).length;
  return counter > 0 ? counter : 1;
});

const qualityCriteriaCounter = computed(() => {
  const counter = get(
    technicalSheet,
    "value.product.qualityCriteria.qualityCriteria",
    []
  ).length;
  return counter > 0 ? counter : 1;
});

const { catalogs, getAllCatalogs } = useCatalogsServices();
const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("technical_sheets");

const form = ref();
const formTemplateRef = ref();
const rawMaterials: Ref<Array<{ id: number; quantity: number }>> = ref([]);

const emit = defineEmits(["handleSubmit"]);

function callBackEdit(product: ProductDto) {
  emit("handleSubmit", {
    product,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const technicalSheet = merge({}, valuesFormatted, {
    rawMaterials: rawMaterials.value,
  });
  await updateTechnicalSheet(technicalSheet, callBackEdit);
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await Promise.all([
    getAllCatalogs(),
    getModuleCustomFields(),
    getTechnicalSheet(),
  ]);
  watch(
    [
      technicalSheet,
      catalogs,
      customFields,
      manufactureSpecificationDetailsCounter,
      qualityCriteriaCounter,
    ],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateTechnicalSheetTemplate(
          customFields.value,
          handleSubmit,
          !technicalSheet.value,
          manufactureSpecificationDetailsCounter.value,
          qualityCriteriaCounter.value
        );
      }
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
