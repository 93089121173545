import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsServices";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { get, merge } from "lodash";
import {
  computed,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateRawMaterialQuantity from "./CreateOrUpdateRawMaterialQuantity.vue";
import { CreateOrUpdateTechnicalSheetTemplate } from "./templates/forms/CreateOrUpdateTechnicalSheet.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateTechnicalSheet',
  props: {
  productId: { type: Number, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const { technicalSheet, getTechnicalSheet, updateTechnicalSheet } =
  useTechnicalSheetsServices(props.productId);

const manufactureSpecificationDetailsCounter = computed(() => {
  const counter = get(
    technicalSheet,
    "value.product.manufactureSpecification.details",
    []
  ).length;
  return counter > 0 ? counter : 1;
});

const qualityCriteriaCounter = computed(() => {
  const counter = get(
    technicalSheet,
    "value.product.qualityCriteria.qualityCriteria",
    []
  ).length;
  return counter > 0 ? counter : 1;
});

const { catalogs, getAllCatalogs } = useCatalogsServices();
const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("technical_sheets");

const form = ref();
const formTemplateRef = ref();
const rawMaterials: Ref<Array<{ id: number; quantity: number }>> = ref([]);

const emit = __emit;

function callBackEdit(product: ProductDto) {
  emit("handleSubmit", {
    product,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const technicalSheet = merge({}, valuesFormatted, {
    rawMaterials: rawMaterials.value,
  });
  await updateTechnicalSheet(technicalSheet, callBackEdit);
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await Promise.all([
    getAllCatalogs(),
    getModuleCustomFields(),
    getTechnicalSheet(),
  ]);
  watch(
    [
      technicalSheet,
      catalogs,
      customFields,
      manufactureSpecificationDetailsCounter,
      qualityCriteriaCounter,
    ],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateTechnicalSheetTemplate(
          customFields.value,
          handleSubmit,
          !technicalSheet.value,
          manufactureSpecificationDetailsCounter.value,
          qualityCriteriaCounter.value
        );
      }
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formTemplateRef.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "form",
              ref: form,
              form: formTemplateRef.value,
              key: JSON.stringify(_unref(technicalSheet)),
              "form-name": "technicalSheetForm",
              initialValues: _unref(technicalSheet),
              "is-view": false
            }, {
              "section-0-field-0": _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(CreateOrUpdateRawMaterialQuantity, {
                      technicalSheet: _unref(technicalSheet),
                      modelValue: rawMaterials.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((rawMaterials).value = $event))
                    }, null, 8, ["technicalSheet", "modelValue"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["form", "initialValues"]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})