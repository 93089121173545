import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  arrayYup,
  requiredYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { ImportFormToImportDto } from "../../formatters/ImportFormToImportDto.formatter";

export const UploadImportTemplate = (action: () => Promise<void>): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "files",
            inputName: "excelFile",
            label: {
              value: "excel.entity.file",
              needsTranslate: true,
            },
            type: "file",
            metadata: {
              tooltipPosition: "top",
            },
            fileOptions: {
              isMultiple: false,
              type: "excel",
            },
            methodsYup: () => {
              return [arrayYup, requiredYup()];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "excel.form.upload",
        needsTranslate: true,
      },
      action,
    },
    formatterFormToDto: ImportFormToImportDto,
  };
};
