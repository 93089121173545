import { DiscountTypes } from "@/store/orders/enums/DiscountTypes.enum";

export function getSubtotal(
  quantity: number,
  price: number,
  discountType: DiscountTypes,
  discount: number
) {
  let subtotal = quantity * Number(price);
  if (discountType === DiscountTypes.AMOUNT) {
    subtotal = subtotal - discount;
  } else if (discountType === DiscountTypes.PERCENTAGE) {
    subtotal = (subtotal * (100 - discount)) / 100;
  }
  return subtotal;
}
