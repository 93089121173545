import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { MeasureOption } from "@/store/measures/interfaces/MeasureOption.interface";
import { SimpleMeasure } from "@/store/measures/interfaces/SimpleMeasure.interface";
import { RawMaterialDto } from "@/store/raw-materials/dtos/input/RawMaterial.dto";
import { RawMaterialForm } from "@/store/raw-materials/interfaces/RawMaterialForm.interface";
import { SimpleSupplier } from "@/store/suppliers/interfaces/SimpleSupplier.interface";
import { SupplierOption } from "@/store/suppliers/interfaces/SupplierOption.interface";
import { get } from "lodash";

export function RawMaterialDtoToRawMaterialForm(
  rawMaterial: RawMaterialDto
): RawMaterialForm {
  const id = get(rawMaterial, "id", false);
  const code = get(rawMaterial, "code");
  const description = get(rawMaterial, "description");
  const materialType = get(rawMaterial, "materialType");
  const unitCost = Number(get(rawMaterial, "unitCost"));
  const thickness = Number(get(rawMaterial, "thickness"));
  const width = Number(get(rawMaterial, "width"));
  const length = Number(get(rawMaterial, "length"));
  const warehouseLocation = get(rawMaterial, "warehouseLocation");
  const measure = formatOption<SimpleMeasure, MeasureOption>(
    get(rawMaterial, "measure"),
    ["id"],
    ["name"]
  );
  const supplier = formatOption<SimpleSupplier, SupplierOption>(
    get(rawMaterial, "supplier"),
    ["id"],
    ["name"]
  );
  const customFields = get(rawMaterial, "customFields");
  return {
    rawMaterial: {
      ...(id === false ? {} : { id }),
      code,
      description,
      materialType,
      unitCost,
      thickness,
      width,
      length,
      warehouseLocation,
      measure,
      supplier,
      customFields,
    },
  };
}
