<template>
  <a
    href="#"
    role="button"
    class="d-flex justify-content-start text-nowrap m-0"
  >
    <slot name="name"></slot>
  </a>
  <slot name="footer"></slot>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
  color: #8e9db8;
  font-size: 14px;
  font-weight: 400;
  margin: auto 10px;
  border-radius: 4px;
  padding: 2px 10px;
}

a:hover {
  background-color: #ebedf2;
  color: #4375ef;
}
</style>
