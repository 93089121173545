import axiosInstance from "@/middlewares/Routing.middleware";
import { DivisionDto } from "../dtos/input/Division.dto";
import { CreateDivisionDto } from "../dtos/output/CreateDivision.dto";
import { DeleteDivisionDto } from "../dtos/output/DeleteDivision.dto";
import { UpdateDivisionDto } from "../dtos/output/UpdateDivision.dto";

export async function CreateDivision(
  payload: CreateDivisionDto
): Promise<DivisionDto> {
  return (await axiosInstance.post(`divisions`, payload)).data;
}

export async function UpdateDivision(
  payload: UpdateDivisionDto
): Promise<DivisionDto> {
  const { id, ...divisionProps } = payload;
  return (await axiosInstance.put(`divisions/${id}`, divisionProps)).data;
}

export async function DeleteDivision(
  payload: DeleteDivisionDto
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`divisions?newId=${newId}&oldId=${oldId}`);
}
