import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { SimpleUserActionType } from "@/views/users/UsersView.vue";
import { cloneDeep } from "lodash";
import {
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateUserTemplate } from "./templates/forms/CreateOrUpdateUser.template";

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateOrViewUser',
  props: {
  initialValues: {
    type: Object as PropType<UserDto>,
    required: false,
  },
  openPositionOffcanvas: {
    type: Object as PropType<() => void>,
    required: true,
  },
  action: {
    type: String as PropType<SimpleUserActionType>,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = __props;

const initialValuesRef: Ref<UserDto> = ref();
const form = ref();
const formTemplateRef = ref();

const emit = __emit;

function callBackCreate(user: UserDto) {
  emit("handleSubmit", { user, isCreating: true });
}

function callBackEdit(user: UserDto) {
  emit("handleSubmit", {
    user,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { user } = form.value.values;
  const userFormatted = cloneDeep(user);
  userFormatted.status = { id: user.status.id };
  userFormatted.profile = { id: user.profile.id };
  userFormatted.positions = user?.positions?.map((position) => {
    return { id: position.id };
  });
  userFormatted.mainPosition = { id: user?.mainPosition?.id };
  if (!initialValuesRef.value) {
    await createUser(userFormatted, callBackCreate);
  } else {
    await updateUser(userFormatted, callBackEdit);
  }
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await getAllCatalogs();
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
  watch(
    [initialValuesRef],
    async () => {
      formTemplateRef.value = CreateOrUpdateUserTemplate(
        customFields.value,
        catalogs.value,
        props.action,
        handleSubmit,
        props.openPositionOffcanvas
      );
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          key: JSON.stringify(initialValuesRef.value),
          form: formTemplateRef.value,
          "form-name": "usersForm",
          initialValues: initialValuesRef.value,
          "is-view": __props.action === 'view'
        }, null, 8, ["form", "initialValues", "is-view"]))
      ])
    ])
  ]))
}
}

})