import { t } from "@/shared/locales/services/i18n.services";
import ExcelJS, { Workbook, Worksheet } from "exceljs";
import { get, set } from "lodash";
import { Field } from "../../forms/interfaces/Field.interface";
import { Form } from "../../forms/interfaces/Form.interface";
import { isField } from "../../helpers/Template.helper";
import { GetAll } from "../services/GetAll.services";

const getFieldByPath = (formTemplate: Form, path: string): Field | false => {
  for (const section of formTemplate.sections) {
    const field = section.fields.find(
      (field): field is Field => isField(field) && field.path === path
    );
    if (field) return field;
  }
  return false;
};

const createOptionsSheet = (
  worksheet: Worksheet,
  name: string,
  options: unknown[]
) => {
  const optionWorkSheet = worksheet.workbook.addWorksheet(name);
  optionWorkSheet.state = "hidden";
  optionWorkSheet.columns = [
    {
      header: "value",
      key: "value",
      width: 20,
    },
  ];
  options.forEach((option) => {
    const value = get(option, "label", get(option, "name"));
    optionWorkSheet.addRow({ value });
  });
  return `${name}!$A$2:$A$${options.length + 1}`;
};

const buildValidations = async (worksheet: Worksheet, formTemplate: Form) => {
  await Promise.all(
    worksheet.columns.map(async (column, colIndex) => {
      const cell = worksheet.getRow(2).getCell(colIndex + 1);
      const field = getFieldByPath(formTemplate, column.key);
      if (field && ["select", "multiple-select"].includes(field.type)) {
        let options = get(field, "selectOptions.initialOptions", []);
        if (options.length === 0 && "dataSource" in field.selectOptions) {
          const { data } = await GetAll(field.selectOptions.dataSource);
          options = data;
        }
        const optionString = createOptionsSheet(
          worksheet,
          `option${colIndex}`,
          options
        );
        cell.dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [optionString],
          showErrorMessage: true,
          errorTitle: t("excel.errors.title"),
          error: t("excel.errors.select"),
        };
      } else if (field && field.type === "date") {
        cell.dataValidation = {
          type: "date",
          operator: "greaterThan",
          formulae: ["1900-01-01"],
          showErrorMessage: true,
          errorTitle: t("excel.errors.title"),
          error: t("excel.errors.date"),
        };
      } else if (field && field.type === "number") {
        cell.dataValidation = {
          type: "whole",
          operator: "greaterThan",
          formulae: ["-1e10"],
          showErrorMessage: true,
          errorTitle: t("excel.errors.title"),
          error: t("excel.errors.number"),
        };
      }
    })
  );
};

const addNotes = (worksheet: Worksheet) => {
  worksheet.getRow(1).eachCell((cell) => {
    cell.note = {
      texts: [{ text: `Nota para la celda ${cell.address}` }],
    };
  });
};

const generateColumns = (
  formTemplate: Form
): Array<{ header: string; key: string; width: number }> => {
  const columns = [];
  formTemplate.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (isField(field)) {
        columns.push({
          header: t(field.label.value),
          key: field.path,
          width: 20,
        });
      }
    });
  });
  return columns;
};

const applyHeaderStyles = (worksheet: Worksheet) => {
  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFF" } }; // Texto en negrita y color blanco
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4e328e" }, // Fondo azul
    };
    cell.alignment = { horizontal: "center", vertical: "middle" }; // Alinear texto al centro
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
};

const applyHeaderNotes = (worksheet: Worksheet) => {
  const headerRow = worksheet.getRow(1);
  worksheet.columns.forEach((col, colIndex) => {
    const cell = headerRow.getCell(colIndex + 1);
    if (col.key) {
      cell.note = {
        texts: [{ text: col.key }],
        margins: {
          insetmode: "custom",
          inset: [0, 0, 0, 0],
        },
        protection: {
          locked: "True",
          lockText: "True",
        },
        editAs: "twoCells",
      };
    }
  });
};

export const generateAndDownloadExcel = async (
  formTemplate: Form,
  moduleName: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("main");

  worksheet.addRow({});
  worksheet.columns = generateColumns(formTemplate);
  //addNotes(worksheet);
  applyHeaderStyles(worksheet);
  applyHeaderNotes(worksheet);
  await buildValidations(worksheet, formTemplate);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${moduleName}.xlsx`;
  link.click();
};

export const loadExcel = async (file: File): Promise<Workbook> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const buffer = e.target.result as ArrayBuffer;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      resolve(workbook);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getHeaderAndRows = (workbook: Workbook) => {
  const worksheet = workbook.getWorksheet("main");
  const rowsData = [];
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      const rowData = {};
      row.eachCell((cell, colNumber) => {
        const cellHeader = worksheet.getRow(1).getCell(colNumber);
        const note = cellHeader.note;
        let columnKey;
        if (typeof note === "string") {
          columnKey = note;
        } else {
          columnKey = get(note, "texts.0.text", "");
        }
        set(rowData, columnKey, get(cell, "value.text", get(cell, "value")));
      });
      rowsData.push(rowData);
    }
  });
  return rowsData;
};
