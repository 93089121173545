import { CreateProductDto } from "@/store/products/dtos/output/CreateProduct.dto";
import { UpdateProductDto } from "@/store/products/dtos/output/UpdateProduct.dto";
import { ProductForm } from "@/store/products/interfaces/ProductForm.interface";
import { get } from "lodash";

export const productFormToProductDto = (
  product: ProductForm
): CreateProductDto | UpdateProductDto => {
  const id = get(product, "product.id", false);
  const name = get(product, "product.name");
  const description = get(product, "product.description");
  const price = Number(get(product, "product.price"));
  const measure = { id: get(product, "product.measure.id") };
  const productFamily = { id: get(product, "product.productFamily.id") };
  const customFields = get(product, "product.customFields");
  return {
    ...(id === false ? {} : { id }),
    name,
    description,
    price,
    measure,
    productFamily,
    customFields,
  };
};
