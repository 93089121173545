import router from "@/router";
import { useStorage } from "@vueuse/core";
import { Module } from "vuex";
import { Account } from "./models/AccountAuth.model";
import { UserAuth } from "./models/UserAuth.model";

interface State {
  account?: Account;
  user?: UserAuth;
  token?: string;
  refreshToken: string;
  launchExpiredSessionToast: boolean;
}

export const authStore: Module<State, unknown> = {
  namespaced: true,
  state: {
    account: undefined,
    user: undefined,
    token: undefined,
    refreshToken: undefined,
    launchExpiredSessionToast: false,
  },
  getters: {},
  mutations: {
    setAccount(state, account: Account) {
      state.account = account;
    },
    setUser(state, user: UserAuth) {
      state.user = user;
    },
    setToken(
      state,
      { token, refreshToken }: { token: string; refreshToken: string }
    ) {
      state.token = token;
      state.refreshToken = refreshToken;
    },
    setLaunchExpiredSessionToast(state, value: boolean) {
      state.launchExpiredSessionToast = value;
    },
  },
  actions: {
    launchExpiredSession(context) {
      const auth = useStorage<State>("user", {
        account: null,
        user: null,
        token: null,
        refreshToken: null,
        launchExpiredSessionToast: false,
      });
      auth.value = {
        account: null,
        user: null,
        token: null,
        refreshToken: null,
        launchExpiredSessionToast: false,
      };
      context.commit("setLaunchExpiredSessionToast", true);
      router.push({ name: "Auth" });
    },
  },
  modules: {},
};
