import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { CustomerDto } from "@/store/customers/dtos/input/Customer.dto";
import { CreateCustomerDto } from "@/store/customers/dtos/output/CreateCustomer.dto";
import { UpdateCustomerDto } from "@/store/customers/dtos/output/UpdateCustomer.dto";
import {
  CreateCustomer,
  DeleteCustomer,
  UpdateCustomer,
} from "@/store/customers/services/Customer.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useCustomersServices() {
  const { t } = useI18n();
  const alias = "customers";

  const createCustomer = async (
    payload: CreateCustomerDto,
    callBackCreate: (customer: CustomerDto) => void
  ): Promise<void> => {
    await CreateCustomer(payload)
      .then((customer: CustomerDto) => {
        callBackCreate(customer);
        useToast().successToast(t("customers.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateCustomer = async (
    payload: UpdateCustomerDto,
    callBackUpdate: (customer: CustomerDto) => void
  ): Promise<void> => {
    await UpdateCustomer(payload)
      .then((customer: CustomerDto) => {
        callBackUpdate(customer);
        useToast().successToast(t("customers.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteCustomer = async (
    payload: CustomerDto,
    callBackDelete: (customer: CustomerDto) => void
  ): Promise<void> => {
    await DeleteCustomer(payload.id)
      .then(() => {
        callBackDelete(payload);
        useToast().successToast(t("customers.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createCustomer,
    updateCustomer,
    deleteCustomer,
  };
}
