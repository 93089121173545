import { get, merge, mergeWith } from "lodash";
import * as yup from "yup";
import { isNotRequired } from "../yup/services/YupRules.services";
import { MethodYup } from "../yup/types/MethodYup.type";
import { RecursiveErrorsMessage } from "../yup/types/RecursiveErrorsMessage.type";
import { RecursiveMethodYupCollection } from "../yup/types/RecursiveMethodYupCollection.type";
import { RecursiveYupCollection } from "../yup/types/RecursiveYupCollection.type";

const joinArrays = (objValue, srcValue) => {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return objValue.concat(srcValue);
  } else if (Array.isArray(objValue)) {
    return objValue;
  } else if (Array.isArray(srcValue)) {
    return srcValue;
  } else if (
    typeof objValue === "object" &&
    objValue !== null &&
    typeof srcValue === "object" &&
    srcValue !== null
  ) {
    return mergeWith(objValue, srcValue, joinArrays);
  }
  return srcValue;
};

export class YupValidator {
  yupCollection: RecursiveYupCollection;
  errors: RecursiveErrorsMessage;

  constructor() {
    this.yupCollection = {};
    this.errors = {};
  }

  addSchema(
    keysValues: RecursiveMethodYupCollection<MethodYup[]>,
    keys: string[]
  ) {
    Object.keys(keysValues).forEach((key) => {
      const newKeysValues = keysValues[key];
      if (Array.isArray(newKeysValues)) {
        let yupObject = yup;
        newKeysValues.forEach((method) => {
          yupObject = yupObject?.[method.name]?.(...(method?.params ?? []));
          const errorMessage = get(method, "params.1", false)
            ? [method.params[1]]
            : [];
          const error = {};
          error[[...keys, key].join(".")] = errorMessage;
          this.errors = mergeWith(this.errors, error, joinArrays);
        });

        const collection = this.createValueFromKeys([...keys, key], yupObject);
        this.yupCollection = merge(this.yupCollection, collection);
      } else {
        this.addSchema(newKeysValues, [...keys, key]);
      }
    });
  }

  createValueFromKeys(keys: string[], value: any) {
    const acc = {};
    keys.reduce((acc, key, index, arr) => {
      if (index === arr.length - 1) {
        acc[key] = value;
      }
      return acc[key] || (acc[key] = {});
    }, acc);
    return acc;
  }

  getSchema(yupCollection = this.yupCollection) {
    if (yupCollection.__isYupSchema__) {
      return yupCollection;
    }
    const partialYupObject = {};
    Object.keys(yupCollection).forEach((key) => {
      partialYupObject[key] = this.getSchema(yupCollection[key]);
    });
    return yup.object(partialYupObject);
  }

  async getValidations(obj) {
    const schema = this.getSchema();
    const results = await schema
      .validate(obj, {
        abortEarly: false,
      })
      .catch((err) => {
        return err;
      });
    const currentErrors = results?.inner?.reduce(
      (accumulator, currentValue) => {
        const [errorMessage] = currentValue.errors;
        const errorType = currentValue.type;
        const key = currentValue.path;
        if (!accumulator[key]) {
          accumulator[key] = {};
          accumulator[key].errors = [{ errorMessage, errorType }];
          accumulator[key].isRequired = false;
          accumulator[key].isEmpty = false;
        } else {
          accumulator[key].errors = [
            ...accumulator[key].errors,
            ...[{ errorMessage, errorType }],
          ];
        }
        accumulator[key].isRequired =
          accumulator[key].isRequired || currentValue.type === "is-required";
        accumulator[key].isEmpty =
          accumulator[key].isEmpty || currentValue.type === "is-not-required";
        accumulator[key].type = currentValue.type;
        return accumulator;
      },
      {}
    );
    const errorsMessages = Object.keys(this.errors).reduce((acc, key) => {
      if (!currentErrors?.[key]?.isRequired && currentErrors?.[key]?.isEmpty) {
        const emptyError = currentErrors[key].errors.find((e) => {
          return e.errorType === "is-not-required";
        });
        acc[key] = [{ error: emptyError.errorMessage, valid: true }];
        return acc;
      }
      acc[key] = (this.errors[key] as string[]).map((error) => {
        return {
          error,
          valid: currentErrors?.[key]?.errors
            ? !currentErrors[key].errors.find((e) => e.errorMessage === error)
            : true,
        };
      });
      return acc;
    }, {});
    return errorsMessages;
  }
}
