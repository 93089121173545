import { useAuthServices } from "@/composables/useAuthServices";
import AuthView from "@/views/auth/AuthView.vue";
import CustomersView from "@/views/customers/CustomersView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import DivisionsView from "@/views/divisions/DivisionsView.vue";
import HomeView from "@/views/HomeView.vue";
import InventoriesView from "@/views/inventories/InventoriesView.vue";
import MainView from "@/views/main/MainView.vue";
import MeasuresView from "@/views/measures/MeasuresView.vue";
import NotFoundView from "@/views/notFound/NotFoundView.vue";
import OperationsView from "@/views/operations/OperationsView.vue";
import OrdersView from "@/views/orders/OrdersView.vue";
import PositionsView from "@/views/positions/PositionsView.vue";
import ProductFamilyView from "@/views/product-families/ProductFamilyView.vue";
import ProductsView from "@/views/products/ProductsView.vue";
import ProfilesView from "@/views/profiles/ProfilesView.vue";
import RawMaterialsView from "@/views/raw-materials/RawMaterialsView.vue";
import ReportsView from "@/views/reports/ReportsView.vue";
import SettingView from "@/views/setting/SettingView.vue";
import StoresView from "@/views/stores/StoresView.vue";
import SuppliersView from "@/views/suppliers/SuppliersView.vue";
import UsersView from "@/views/users/UsersView.vue";
import WorkplacesView from "@/views/workplaces/WorkplacesView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    children: [
      {
        path: "/main",
        name: "Main",
        component: MainView,
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
      },
      {
        path: "/users",
        name: "Users",
        component: UsersView,
        meta: { moduleName: "users" },
      },
      {
        path: "/profiles",
        name: "Profiles",
        component: ProfilesView,
        meta: { moduleName: "profiles" },
      },
      {
        path: "/positions",
        name: "Positions",
        component: PositionsView,
        meta: { moduleName: "positions" },
      },
      {
        path: "/customers",
        name: "Customers",
        component: CustomersView,
        meta: { moduleName: "customers" },
      },
      {
        path: "/suppliers",
        name: "Suppliers",
        component: SuppliersView,
        meta: { moduleName: "suppliers" },
      },
      {
        path: "/products",
        name: "Products",
        component: ProductsView,
        meta: { moduleName: "products" },
      },
      {
        path: "/raw-materials",
        name: "RawMaterials",
        component: RawMaterialsView,
        meta: { moduleName: "raw_materials" },
      },
      {
        path: "/products-families",
        name: "ProductFamily",
        component: ProductFamilyView,
        meta: { moduleName: "products_families" },
      },
      {
        path: "/measures",
        name: "Measures",
        component: MeasuresView,
        meta: { moduleName: "measures" },
      },
      {
        path: "/orders",
        name: "Orders",
        component: OrdersView,
        meta: { moduleName: "orders" },
      },
      {
        path: "/shipping",
        name: "Shipping",
        component: OperationsView,
        meta: { moduleName: "shipping" },
      },
      {
        path: "/workplaces",
        name: "Workplace",
        component: WorkplacesView,
        meta: { moduleName: "workplaces" },
      },
      {
        path: "/reports",
        name: "Reports",
        component: ReportsView,
        meta: { moduleName: "reports" },
      },
      {
        path: "/settings",
        name: "Setting",
        component: SettingView,
        meta: { moduleName: "settings" },
      },
      {
        path: "/not-found",
        name: "NotFound",
        component: NotFoundView,
        meta: { moduleName: "not_found" },
      },
      {
        path: "/divisions",
        name: "Divisions",
        component: DivisionsView,
        meta: { moduleName: "divisions" },
      },
      {
        path: "/stores",
        name: "Stores",
        component: StoresView,
        meta: { moduleName: "stores" },
      },
      {
        path: "/products-inventories",
        name: "InventoryProducts",
        component: InventoriesView,
        meta: { moduleName: "products_inventories" },
      },
      {
        path: "/raw-materials-inventories",
        name: "InventoryRawMaterials",
        component: InventoriesView,
        meta: { moduleName: "raw_materials_inventories" },
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const excludedAuthRoutes = ["Auth"];

function existsRoute(
  routeName: string,
  auxRoutes: Array<RouteRecordRaw> | RouteRecordRaw
) {
  if (Array.isArray(auxRoutes)) {
    return auxRoutes.some((route) => {
      if (route.children) {
        return route.children.some((childrenRoute) => {
          return existsRoute(routeName, childrenRoute);
        });
      }
    });
  } else if (routeName === auxRoutes.name) {
    return true;
  }
}

function hasPermissions(to): boolean {
  const {
    auth: {
      value: {
        user: { profile },
      },
    },
  } = useAuthServices();
  if (profile.id === 0) {
    return true;
  }
  const permissionExists = profile.permissions.find(
    (permission) => permission.name === to
  );
  if (permissionExists) {
    return true;
  }
  return false;
}

router.beforeEach(async (to, from, next) => {
  const {
    auth: { value },
  } = useAuthServices();
  const token = value.token;
  if (!token) {
    if ((to.name as string) !== "Auth") {
      next("/auth");
    } else {
      next();
    }
  } else {
    if (["NotFound", "Main"].includes(to.name as string)) {
      next();
    }
    if (
      !hasPermissions(to.name as string) ||
      !existsRoute(to.name as string, routes)
    ) {
      next("/not_found");
    } else if (excludedAuthRoutes.includes(to.name as string)) {
      next("/main");
    } else {
      next();
    }
  }
});

export default router;
