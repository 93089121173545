import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { FlowDtoToFlowForm } from "../../formatters/FlowDtoToFlowForm.formatter";
import { FlowFormToFlowDto } from "../../formatters/FlowFormToFlowDto.formatter";

export const CreateOrUpdateWorkplaceFlows = (
  customFields: CustomFieldDto[],
  action
): Form => {
  const form: Form = {
    sections: [
      {
        fields: [],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "workplacesFlows.form.update",
        needsTranslate: true,
      },
      action,
    },
    formatterDtoToForm: FlowDtoToFlowForm,
    formatterFormToDto: FlowFormToFlowDto,
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(
        customFields,
        "workplaceFlow",
        "workplaceFlow"
      ),
    });
  }

  return form;
};
