import axiosInstance from "@/middlewares/Routing.middleware";
import { jsonToFormData } from "@/shared/globals/helpers/Form.helper";
import { ProductDto } from "@/store/products/dtos/input/Product.dto";
import { TechnicalSheetDto } from "../dtos/input/TechnicalSheet.dto";
import { UpdateTechnicalSheetDto } from "../dtos/output/UpdateTechnicalSheet.dto";

export async function GetTechnicalSheet(
  productId: number
): Promise<TechnicalSheetDto> {
  return (await axiosInstance.get(`technical-sheets/${productId}`)).data;
}

export async function UpdateTechnicalSheet(
  payload: UpdateTechnicalSheetDto
): Promise<ProductDto> {
  const { productId, ...rest } = payload;
  const formData = jsonToFormData(rest);
  return (await axiosInstance.put(`technical-sheets/${productId}`, formData))
    .data;
}
