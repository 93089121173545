import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useStoresServices } from "@/composables/useStoresServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { StoreDto } from "@/store/stores/dtos/input/Store.dto";
import { StoreTranslateFormInterface } from "@/store/stores/interfaces/StoreTranslateForm.interface";
import { SimpleStoreActionType } from "@/views/stores/StoresView.vue";
import { get, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateStoreTemplate } from "./templates/forms/CreateOrUpdateStore.template";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateStore',
  props: {
  initialValues: { type: Object as PropType<StoreDto>, required: false },
  action: { type: String as PropType<SimpleStoreActionType>, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createStore, updateStore } = useStoresServices();
const { t } = useI18n();

const props = __props;

const initialValuesRef: Ref<StoreDto> = ref();
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates: Ref<StoreTranslateFormInterface> = ref({});
const translateFormId = ref();

const emit = __emit;

async function handleTranslateSubmit() {
  const { values }: { values: StoreTranslateFormInterface } =
    translateForm.value;
  translates.value = merge({}, translates.value, values);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  translateFormId.value = uuidv4();
  modal.value.openModal();
}

function callBackCreate(store: StoreDto) {
  emit("handleSubmit", { store, isCreating: true });
}

function callBackEdit(store: StoreDto) {
  emit("handleSubmit", {
    store,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  const store = merge({}, valuesFormatted, translates.value);
  if ("id" in store) {
    await updateStore(store, callBackEdit);
  } else {
    await createStore(store, callBackCreate);
  }
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  [initialValuesRef],
  async () => {
    const metadata = get(initialValuesRef.value, "metadata");
    translates.value = metadata ? { metadata } : {};
  },
  { deep: true, immediate: true }
);

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateStoreTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value,
      handleTranslateButton
    );
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "storeForm",
          "initial-values": initialValuesRef.value,
          "is-view": __props.action === 'view'
        }, null, 8, ["form", "initial-values", "is-view"]))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          ref_key: "modal",
          ref: modal,
          modalId: "translateModal",
          modalName: modalTitle.value,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "translateForm",
              ref: translateForm,
              form: formTranslateRef.value,
              "form-name": "translateForm",
              key: modalTitle.value,
              "initial-values": translates.value,
              "is-view": __props.action === 'view'
            }, null, 8, ["form", "initial-values", "is-view"]))
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})