import { UpdateCustomFieldsDto } from "@/store/workplaces-flow/dtos/output/UpdateWorkplaceFlow.dto";
import { WorkplaceFlowForm } from "@/store/workplaces-flow/interfaces/WorkplaceFlowForm.interface";
import { get } from "lodash";

export function FlowFormToFlowDto({
  workplaceFlow,
}: WorkplaceFlowForm): UpdateCustomFieldsDto {
  const customFields = get(workplaceFlow, "customFields", {});
  return { customFields };
}
