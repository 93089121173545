import { UpdateStepDto } from "@/store/workplaces-flow/dtos/output/UpdateWorkplaceFlow.dto";
import { WorkplaceStepForm } from "@/store/workplaces-flow/interfaces/WorkplaceStepForm.interface";
import { get } from "lodash";

export function StepsFormToStepsDto(
  steps: WorkplaceStepForm[]
): UpdateStepDto[] {
  return steps.map(({ workplace, workTime, step }) => {
    return {
      workplace: { id: get(workplace, "id") },
      workTime,
      step,
    };
  });
}
