import { t } from "@/shared/locales/services/i18n.services";
import { CustomFieldDto } from "@/store/customFields/dtos/input/CustomField.dto";
import { CustomFieldForm } from "@/store/customFields/interfaces/CustomFieldForm.interface";
import { get } from "lodash";

const getLabel = (customField) => {
  const customFieldType = get(customField, "type");
  if (customFieldType === "multiple-select") {
    return t(`customFields.types.multipleSelect`);
  }
  return t(`customFields.types.${customFieldType}`);
};

export const CustomFieldDtoToCustomFieldForm = (
  customField: CustomFieldDto
): CustomFieldForm => {
  const id = get(customField, "id", false);
  const name = get(customField, "name");
  const type = {
    id: get(customField, "type"),
    label: getLabel(customField),
    item: get(customField, "type"),
    items: [get(customField, "type")],
  };
  const required = get(customField, "required");
  const options = get(customField, "metadata.options");
  return {
    customField: {
      ...(id ? { id } : {}),
      name,
      type,
      required,
      metadata: { options },
    },
  };
};
