import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-12 d-flex align-items-center" }
const _hoisted_3 = { class: "textBlack" }
const _hoisted_4 = { class: "row justify-content-between mt-2 mb-1" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row" }

import { useAuthServices } from "@/composables/useAuthServices";
import { AuthTypes } from "@/store/auth/types/Credentials.type";
import { get } from "lodash";
import {
  computed,
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { ForgotPasswordStep1Template } from "./templates/forms/ForgotPasswordStep1.template";
import { ForgotPasswordStep2Template } from "./templates/forms/ForgotPasswordStep2.template";

export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  props: {
  initialValues: {
    type: Object as PropType<AuthTypes>,
    required: true,
  },
},
  emits: ["goToSlide", "update:modelValue"],
  setup(__props, { emit: __emit }) {

const { forgotPassword, confirmForgotPassword } = useAuthServices();

const emit = __emit;
const props = __props;

const initialValuesRef = toRef(props, "initialValues");
const isFirstStep = ref<boolean>(true);
const formRef = ref();
const formTemplate = ref();
const card = ref();
const cardIsObserved = ref(false);

function changeFormTemplate(): void {
  isFirstStep.value = !isFirstStep.value;
}

function goToLogin(): void {
  isFirstStep.value = true;
  emit("goToSlide", 1);
}

async function submitForgotPassword(): Promise<void> {
  const { values } = formRef.value;
  const { email, newPassword, confirmationCode } = values;
  if (isFirstStep.value) {
    await forgotPassword({ email }, changeFormTemplate);
  } else {
    confirmForgotPassword({ email, newPassword, confirmationCode }, goToLogin);
  }
}

const email = computed(() => {
  const initialEmail = get(initialValuesRef, "value.email", "");
  const email = get(formRef, "value.values.email", "");
  if (initialEmail !== email) {
    emit("update:modelValue", { email });
  }
  return email;
});

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      cardIsObserved.value = true;
    } else {
      cardIsObserved.value = false;
    }
  });
});

watch(
  isFirstStep,
  () => {
    formTemplate.value = isFirstStep.value
      ? ForgotPasswordStep1Template(submitForgotPassword)
      : ForgotPasswordStep2Template(submitForgotPassword);
  },
  { deep: true, immediate: true }
);

watch(
  cardIsObserved,
  () => {
    if (cardIsObserved.value) {
      isFirstStep.value = true;
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  observer.observe(card.value.$el);

  watch(
    [initialValuesRef, isFirstStep, formTemplate],
    () => {
      formRef.value.setValues(props.initialValues);
    },
    { deep: true, immediate: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthCard, {
    ref_key: "card",
    ref: card
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("auth.carouselTabs.forgotPassword.primaryTitle")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
          _createElementVNode("span", {
            class: "actionableLabel",
            onClick: goToLogin
          }, _toDisplayString(_ctx.$t("auth.carouselTabs.forgotPassword.hrefs.signUp")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (isFirstStep.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "actionableLabel",
                onClick: changeFormTemplate
              }, _toDisplayString(_ctx.$t("auth.carouselTabs.forgotPassword.hrefs.haveACodeYet")), 1))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "actionableLabel",
                onClick: changeFormTemplate
              }, _toDisplayString(_ctx.$t("auth.carouselTabs.forgotPassword.hrefs.notHaveACodeYet")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(GeneralForm, {
          ref_key: "formRef",
          ref: formRef,
          form: formTemplate.value,
          formName: "forgotPassword"
        }, null, 8, ["form"])
      ])
    ]),
    _: 1
  }, 512))
}
}

})