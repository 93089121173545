import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { DivisionOption } from "@/store/divisions/interfaces/DivisionOption.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";
import { SupplierDto } from "@/store/suppliers/dtos/input/Supplier.dto";
import { SupplierForm } from "@/store/suppliers/interfaces/SupplierForm.interface";
import { get } from "lodash";

export const SupplierDtoToSupplierForm = (
  supplier: SupplierDto
): SupplierForm => {
  const id = get(supplier, "id", false);
  const name = get(supplier, "name");
  const code = get(supplier, "code");
  const supplierType = get(supplier, "supplierType");
  const businessType = get(supplier, "businessType");
  const state = get(supplier, "state");
  const city = get(supplier, "city");
  const email = get(supplier, "email");
  const phoneNumber = get(supplier, "phoneNumber");
  const customFields = get(supplier, "customFields", {});
  const division = formatOption<SimpleDivision, DivisionOption>(
    get(supplier, "division"),
    ["id"],
    ["name"]
  );
  return {
    supplier: {
      ...(id === false ? {} : { id }),
      name,
      code,
      supplierType,
      businessType,
      state,
      city,
      email,
      phoneNumber,
      customFields,
      division,
    },
  };
};
