import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { CustomerDto } from "@/store/customers/dtos/input/Customer.dto";
import { CustomerFormInterface } from "@/store/customers/interfaces/CustomerForm.interface";
import { DivisionOption } from "@/store/divisions/interfaces/DivisionOption.interface";
import { SimpleDivision } from "@/store/divisions/interfaces/SimpleDivision.interface";
import { get } from "lodash";

export function CustomerDtoToCustomerForm(
  customer: CustomerDto
): CustomerFormInterface {
  const id = get(customer, "id", false);
  const name = get(customer, "user.name", "");
  const email = get(customer, "user.email", "");
  const customerType = get(customer, "customerType", "");
  const businessType = get(customer, "businessType", "");
  const state = get(customer, "state", "");
  const city = get(customer, "city", "");
  const customFields = get(customer, "customFields", {});
  const metadata = get(customer, "metadata", {});
  const division = formatOption<SimpleDivision, DivisionOption>(
    get(customer, "division"),
    ["id"],
    ["name"]
  );
  return {
    customer: {
      ...(id === false ? {} : { id }),
      name,
      email,
      customerType,
      businessType,
      state,
      city,
      customFields,
      metadata,
      division,
    },
  };
}
