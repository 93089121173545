import { useLanguageServices } from "@/composables/useLanguageServices";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { CatalogOption } from "@/store/catalogs/interfaces/CatalogOption.interface";
import { SimpleCatalog } from "@/store/catalogs/interfaces/SimpleCatalog.interface";
import { CustomerOption } from "@/store/customers/interfaces/CustomerOption.interface";
import { SimpleCustomer } from "@/store/customers/interfaces/SimpleCustomer.interface";
import { OrderDto } from "@/store/orders/dtos/input/Order.dto";
import { OrderForm } from "@/store/orders/interfaces/OrderForm.interface";
import { get } from "lodash";

export const OrderDtoToOrderForm = (order: OrderDto): OrderForm => {
  const { lang } = useLanguageServices();
  const id = get(order, "id", false);
  const orderDate = DateHelper.of().format(
    get(order, "orderDate"),
    "yyyy-MM-dd"
  );
  const customer = formatOption<SimpleCustomer, CustomerOption>(
    get(order, "customer"),
    ["id"],
    ["user.name"]
  );
  const status = formatOption<SimpleCatalog, CatalogOption>(
    get(order, "status"),
    ["id"],
    [`catalogMetadata.name.language.${lang.value}`, "name"]
  );
  const customFields = get(order, "customFields", {});
  return {
    order: {
      ...(id === false
        ? { orderDate }
        : {
            id,
            orderDate,
            customer,
            status,
            customFields,
          }),
    },
  };
};
