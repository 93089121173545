import { useLanguageServices } from "@/composables/useLanguageServices";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { CatalogOption } from "@/store/catalogs/interfaces/CatalogOption.interface";
import { SimpleCatalog } from "@/store/catalogs/interfaces/SimpleCatalog.interface";
import { PositionOption } from "@/store/positions/interfaces/PositionOption.interface";
import { SimplePosition } from "@/store/positions/interfaces/SimplePosition.interface";
import { ProfileOption } from "@/store/profiles/interfaces/ProfileOption.interface";
import { SimpleProfile } from "@/store/profiles/interfaces/SimpleProfile.interface";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { UserFormInterface } from "@/store/users/interfaces/UserForm.interface";
import { get } from "lodash";

export const UserDtoToUserForm = (user: UserDto): UserFormInterface => {
  const { lang } = useLanguageServices();
  const id = get(user, "id", false);
  const name = get(user, "name", "-");
  const email = get(user, "email", "-");
  const profile = formatOption<SimpleProfile, ProfileOption>(
    get(user, "profile"),
    ["id"],
    ["name"]
  );
  const status = formatOption<SimpleCatalog, CatalogOption>(
    get(user, "status"),
    ["id"],
    [`catalogMetadata.language.[${lang.value}]`, "name"]
  );
  const mainPosition = formatOption<SimplePosition, PositionOption>(
    get(user, "mainPosition"),
    ["id"],
    ["name"]
  );
  const positions = get(user, "positions", []).map((position) => {
    return formatOption<SimplePosition, PositionOption>(
      position,
      ["id"],
      ["name"]
    );
  });
  const customFields = get(user, "customFields", {});
  return {
    user: {
      ...(id === false ? {} : { id }),
      name,
      email,
      profile,
      status,
      mainPosition,
      positions,
      customFields,
    },
  };
};
