import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-nowrap"
}
const _hoisted_4 = {
  key: 1,
  class: "text-nowrap"
}
const _hoisted_5 = { class: "d-flex justify-content-center" }

import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { formatDate } from "@/shared/globals/helpers/Date.helper";
import { isField } from "@/shared/globals/helpers/Template.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { BPagination, BTable } from "bootstrap-vue-3";
import { get } from "lodash";
import { computed, PropType, ref, Ref, toRef } from "vue";
import { boolean } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'TablePreview',
  props: {
  items: {
    type: Array,
    required: true,
  },
  template: { type: Object as PropType<Form>, required: true },
  debug: { type: boolean, required: false, default: false },
},
  setup(__props) {

const props = __props;

const items = toRef(props, "items");
const perPage = ref(5);
const currentPage = ref(1);
const totalRows = computed(() => {
  return items.value.length;
});

const fields: Ref<
  Array<{
    key: string;
    sortable: boolean;
    label: string;
    field: Field;
  }>
> = computed(() => {
  const fields = [];
  props.template.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (isField(field)) {
        fields.push({
          key: field.path,
          sortable: false,
          label: t(field.label.value),
          field: field,
        });
      }
    });
  });
  return fields;
});

const totalPages = computed(() => {
  return Math.round(totalRows.value / perPage.value);
});

const startIndex = computed(() => {
  return (currentPage.value - 1) * perPage.value;
});

const endIndex = computed(() => {
  return currentPage.value * perPage.value;
});

const itemsPaginated = computed(() => {
  return items.value.filter((item, index) => {
    return startIndex.value <= index && index < endIndex.value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(" currentPage: " + _toDisplayString(currentPage.value) + " ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" totalRows: " + _toDisplayString(totalRows.value) + " ", 1),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" perPage: " + _toDisplayString(perPage.value) + " ", 1),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" totalPages: " + _toDisplayString(totalPages.value) + " ", 1),
          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" startIndex: " + _toDisplayString(startIndex.value) + " ", 1),
          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" endIndex: " + _toDisplayString(endIndex.value), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(BTable), _normalizeProps(_guardReactiveProps({ items:itemsPaginated.value, fields: fields.value, showEmpty: true } as any)), _createSlots({
      empty: _withCtx(() => [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t("technicalSheets.tables.rawMaterials.isEmpty")), 1)
      ]),
      _: 2
    }, [
      _renderList(fields.value, (field) => {
        return {
          name: `cell(${field.key})`,
          fn: _withCtx(({ item }) => [
            (field.field.type === 'date')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(formatDate)(_unref(get)(item, field.key) as Date) !== "Invalid Date"
            ? _unref(formatDate)(_unref(get)(item, field.key) as Date)
            : "-"), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(get)(item, field.key)), 1))
          ])
        }
      })
    ]), 1040),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(BPagination), {
        modelValue: currentPage.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
        "total-rows": totalRows.value,
        "per-page": perPage.value,
        "first-number": ""
      }, null, 8, ["modelValue", "total-rows", "per-page"])
    ])
  ], 64))
}
}

})