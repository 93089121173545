<template>
  <div v-if="debug" class="text-wrap">
    <p>
      initialFilesRef:
      {{
        initialFilesRef.map(({ isUrl, file }) => {
          return { isUrl, file };
        })
      }}
    </p>
    <p>srcs: {{ srcs.length }}</p>
    <p>files: {{ files.length }}</p>
    <p v-for="(file, index) of files" :key="`size${index}`">
      file {{ index }} size: {{ file.size }}
    </p>
  </div>

  <div
    :class="`card
    py-2
    w-100
    ${isEntering ? 'entering' : ''}`"
    ref="inputCard"
    @dragover.prevent="onDragOver"
    @dragleave="onDragLeave"
    @drop="handleDrop"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex flex-start">
          <div style="margin-left: 8px">
            <button
              type="button"
              @click="() => fileInput.click()"
              class="btn btn-primary"
            >
              Cargar
            </button>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              class="form-control"
              @change="handleFileChange"
              :multiple="fileOptions.isMultiple"
              :accept="accept"
            />
          </div>
        </div>
        <div class="col-12" v-if="Object.keys(srcs).length > 0">
          <div class="d-flex flex-row justify-content-start flex-wrap">
            <div
              v-for="(srcKey, fileIndex) of Object.keys(srcs)"
              :key="
                files[srcKey].name + files[srcKey].type + files[srcKey].size
              "
              :class="`${fileOptions.type === 'excel' ? 'w-100' : ''}`"
            >
              <div
                :class="`card
              p-2
              m-2
              d-flex
              flex-column
              justify-content-center
              align-items-center
              preview
              `"
              >
                <img
                  v-if="
                    srcs[srcKey] &&
                    fileOptions?.imageOptions?.showPreview &&
                    fileOptions.type === 'image'
                  "
                  :src="srcs[srcKey] as string"
                  :width="fileOptions.imageOptions.width"
                  :height="fileOptions.imageOptions.height"
                />
                <div
                  v-if="srcs[srcKey] && fileOptions.type === 'excel'"
                  class="w-100"
                >
                  <slot name="file-preview" :fileIndex="fileIndex"></slot>
                </div>
                <i
                  @click="() => onRemoveFile(srcKey)"
                  class="bi bi-x-circle icon-wrapper text-danger"
                ></i>
                {{ files[srcKey].name }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-12" style="min-height: 100px">
          <div class="d-flex align-items-center justify-content-center h-100">
            <i class="fw-bold">{{ $t("global.inputs.dragAndDrop") }}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from "@/composables/useToastServices";
import { FileOptions } from "@/shared/globals/forms/interfaces/FileOptions.interface";
import { compressImage } from "@/shared/globals/helpers/Files.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { get } from "lodash";
import {
  computed,
  defineEmits,
  defineProps,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";

type FileType = {
  url: string;
  file?: File;
  isUrl: boolean;
};

enum FileTypes {
  image = "image/",
  excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

const props = defineProps({
  modelValue: { type: Array, required: true },
  fileOptions: {
    type: Object as PropType<FileOptions>,
    required: false,
  },
  initialFiles: {
    type: Array<FileType>,
    required: false,
    default: () => [],
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const initialFilesRef: Ref<FileType[]> = toRef(props, "initialFiles");
const fileInput = ref();
const srcs: Ref<{ [key in string]: string }> = ref({});
const files: Ref<{
  [key in string]: File;
}> = ref({});
const isEntering: Ref<boolean> = ref(false);

const accept = computed(() => {
  if (props.fileOptions.type === "image") {
    return "image/*";
  } else if (props.fileOptions.type === "pdf") {
    return "application/pdf";
  } else if (props.fileOptions.type === "excel") {
    return ".xlsx";
  }
  return "/*";
});

function handleDrop(event: DragEvent) {
  event.preventDefault();
  const files = get(event, "dataTransfer.files", []);
  if (!props.fileOptions.isMultiple && files.length > 1) {
    useToast().errorToast(t("global.errors.tooFiles"));
  } else {
    const tempFiles: File[] = Array.from(files);
    processFiles(tempFiles);
  }
  isEntering.value = false;
}

function onDragOver(event: DragEvent) {
  event.preventDefault();
  isEntering.value = true;
}

function onDragLeave(event: DragEvent) {
  event.preventDefault();
  isEntering.value = false;
}

function filesAreValid(tempFiles: File[]): boolean {
  for (let tempFile of tempFiles) {
    if (!tempFile.type.includes(FileTypes[props.fileOptions.type])) {
      return false;
    }
  }
  return true;
}

async function processFiles(tempFiles: File[]) {
  if (!filesAreValid(tempFiles)) {
    useToast().errorToast(t("global.errors.typesWrong"));
    return;
  }
  if (!props.fileOptions.isMultiple) {
    srcs.value = {};
    files.value = {};
  }
  await Promise.all(
    tempFiles.map(async (file) => {
      const url = await getUrlFromFile(file);
      if (props.fileOptions.type === "image") {
        try {
          files.value[file.name] = await compressImage(file);
        } catch {
          useToast().errorToast(t("global.errors.imageTooBig"));
        }
      } else {
        files.value[file.name] = file;
      }
      srcs.value[file.name] = url;
      return { url, file };
    })
  );
  emit("update:modelValue", formatValue());
}

async function handleFileChange(event) {
  const tempFiles: File[] = Array.from(event.target.files);
  processFiles(tempFiles);
  event.target.value = null;
}

function formatValue(): FileType[] {
  return Object.keys(files.value).map((key) => {
    const url = srcs.value[key];
    const file = files.value[key];
    const isUrl = !url.startsWith("data:");
    return { url, file, isUrl };
  });
}

function onRemoveFile(srcKey: string) {
  const { [`${srcKey}`]: removedSrc, ...newSrcs } = srcs.value;
  const { [`${srcKey}`]: removedFile, ...newFiles } = files.value;
  srcs.value = newSrcs;
  files.value = newFiles;
  emit("update:modelValue", formatValue());
}

function filesNotExists(): boolean {
  return initialFilesRef.value.some((item) => {
    return !item?.file;
  });
}

watch(
  initialFilesRef,
  async () => {
    if (filesNotExists()) {
      const newInitialFiles = await Promise.all(
        initialFilesRef.value.map(async ({ url, file, isUrl }) => {
          if (!file) {
            const response = await fetch(url, {
              mode: "no-cors",
            });
            const data = await response.blob();
            const fileName = url.match(/[^./]+\.[a-zA-Z0-9]+$/)?.[0];
            const tempFile = new File([data], fileName, {
              type: data.type,
            });
            return { url, file: tempFile, isUrl: true };
          }
          return { url, file, isUrl };
        })
      );
      emit("update:modelValue", newInitialFiles);
    } else {
      srcs.value = Object.fromEntries(
        initialFilesRef.value.map(({ url, file }) => {
          return [file.name, url];
        })
      );
      files.value = Object.fromEntries(
        initialFilesRef.value.map(({ file }) => {
          return [file.name, file];
        })
      );
    }
  },
  { immediate: true }
);

function isString(value): value is string {
  if (typeof value === "string") {
    return true;
  }
  return false;
}

async function getUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const url = e.target?.result;
      if (isString(url)) {
        resolve(url);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  cursor: pointer;
}

.icon-wrapper:hover {
  background-color: rgba(255, 0, 0, 0.2);
  transform: scale(1.1);
}

.icon-wrapper:hover i {
  color: white;
}

.preview {
  overflow-x: auto;
}

.entering {
  background-color: #e2cef6;
}
</style>
