import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCustomersServices } from "@/composables/useCustomersServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { CustomerDto } from "@/store/customers/dtos/input/Customer.dto";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { SimplePositionActionType } from "@/views/positions/PositionsView.vue";
import {
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateCustomerTemplate } from "./templates/forms/CreateOrUpdateCustomer.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateCustomer',
  props: {
  initialValues: { type: Object as PropType<CustomerDto>, required: false },
  action: {
    type: String as PropType<SimplePositionActionType>,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createCustomer, updateCustomer } = useCustomersServices();

const props = __props;

const initialValuesRef: Ref<CustomerDto> = ref();
const form = ref();
const formTemplateRef = ref();

const emit = __emit;

function callBackCreate(customer: CustomerDto) {
  emit("handleSubmit", { customer, isCreating: true });
}

function callBackEdit(customer: CustomerDto) {
  emit("handleSubmit", {
    customer,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { valuesFormatted: customer } = form.value;
  if ("id" in customer) {
    await updateCustomer(customer, callBackEdit);
  } else {
    await createCustomer(customer, callBackCreate);
  }
}

watch(
  initialValuesRef,
  async () => {
    formTemplateRef.value = CreateOrUpdateCustomerTemplate(
      customFields.value,
      handleSubmit,
      !initialValuesRef.value
    );
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "customerForm",
          "initial-values": initialValuesRef.value,
          "is-view": __props.action === 'view'
        }, null, 8, ["form", "initial-values", "is-view"]))
      ])
    ])
  ]))
}
}

})