import { UpdateTechnicalSheetDto } from "@/store/technical-sheets/dtos/output/UpdateTechnicalSheet.dto";
import { TechnicalSheetForm } from "@/store/technical-sheets/interfaces/TechnicalSheetForm.interface";
import { get } from "lodash";

export function TechnicalSheetFormToTechnicalSheetDto(
  technicalSheet: TechnicalSheetForm
): UpdateTechnicalSheetDto {
  const productId = Number(get(technicalSheet, "technicalSheet.productId"));
  const rawMaterials = get(technicalSheet, "technicalSheet.rawMaterials");
  const customFields = get(technicalSheet, "technicalSheet.customFields");
  const materialType = get(technicalSheet, "technicalSheet.materialType.id");

  const manufactureSpecification = {
    images: get(
      technicalSheet,
      "technicalSheet.manufactureSpecification.images",
      []
    ).map((values) => {
      const { url, file, isUrl } = values;
      return isUrl ? url : file;
    }),
    details: Object.values(
      get(
        technicalSheet,
        "technicalSheet.manufactureSpecification.details",
        {}
      ) as Record<string, string> // Indicamos que es un objeto cuyas propiedades son strings
    ).map((detail) => detail),
  };
  const descriptiveImages = get(
    technicalSheet,
    "technicalSheet.descriptiveImages",
    []
  ).map((values) => {
    const { url, file, isUrl } = values;
    return isUrl ? url : file;
  });
  const instructions = {
    images: get(technicalSheet, "technicalSheet.instructions.images", []).map(
      (values) => {
        const { url, file, isUrl } = values;
        return isUrl ? url : file;
      }
    ),
    description: get(technicalSheet, "technicalSheet.instructions.description"),
  };
  const qualityCriteria = {
    qualityCriteria: Object.values(
      get(technicalSheet, "technicalSheet.qualityCriteria.orders", {})
    ).map((value, index) => ({
      order: value as string,
      description: Object.values(
        get(technicalSheet, "technicalSheet.qualityCriteria.descriptions", {})
      )[index] as string,
    })),
    observations: get(
      technicalSheet,
      "technicalSheet.qualityCriteria.observations"
    ),
  };
  return {
    productId,
    rawMaterials,
    customFields,
    materialType,
    manufactureSpecification,
    descriptiveImages,
    instructions,
    qualityCriteria,
  };
}
