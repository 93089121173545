import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { formatOption } from "@/shared/globals/helpers/Formatter.helper";
import { ProductInventoryDto } from "@/store/inventories/dtos/input/ProductInventory.dto";
import { RawMaterialInventoryDto } from "@/store/inventories/dtos/input/RawMaterialInventory.dto";
import { InventoryForm } from "@/store/inventories/interfaces/InventoryForm.interface";
import { ProductOption } from "@/store/products/interfaces/ProductOption.interface";
import { SimpleProduct } from "@/store/products/interfaces/SimpleProduct.interface";
import { RawMaterialOption } from "@/store/raw-materials/interfaces/RawMaterialOption.interface";
import { SimpleRawMaterial } from "@/store/raw-materials/interfaces/SimpleRawMaterial.interface";
import { SimpleStore } from "@/store/stores/interfaces/SimpleStore.interface";
import { StoreOption } from "@/store/stores/interfaces/StoreOption.interface";
import { get } from "lodash";

export const InventoryDtoToInventoryForm = (
  inventory: RawMaterialInventoryDto | ProductInventoryDto
): InventoryForm => {
  const id = get(inventory, "id", false);
  const lotCode = get(inventory, "lotCode", "");
  const expirationDate = get(inventory, "expirationDate", false)
    ? DateHelper.of().format(get(inventory, "expirationDate"), "yyyy-MM-dd")
    : "";
  const quantity = Number(get(inventory, "quantity", ""));
  let productOrRawMaterialOption: ProductOption | RawMaterialOption;
  if ("product" in inventory) {
    productOrRawMaterialOption = formatOption<SimpleProduct, ProductOption>(
      get(inventory, "product"),
      ["id"],
      ["name"]
    );
  } else {
    productOrRawMaterialOption = formatOption<
      SimpleRawMaterial,
      RawMaterialOption
    >(get(inventory, "rawMaterial"), ["id"], ["description"]);
  }
  const store = formatOption<SimpleStore, StoreOption>(
    get(inventory, "store"),
    ["id"],
    ["name"]
  );
  const customFields = get(inventory, "customFields", {});
  return {
    inventory: {
      ...(id === false
        ? {}
        : {
            id,
            store,
            elements: {
              element0: {
                id: productOrRawMaterialOption,
                lotCode,
                quantity,
                expirationDate,
                customFields,
              },
            },
          }),
    },
  };
};
