import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "text-center" }

import { Menu } from "@/shared/globals/menu/types/Menu.type";
import {
  TechnicalSheetDto,
  TechnicalSheetRawMaterial,
} from "@/store/technical-sheets/dtos/input/TechnicalSheet.dto";
import { RawMaterialQuantityForm } from "@/store/technical-sheets/interfaces/RawMaterialQuantityForm.interface";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { cloneDeep, get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  computed,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import DropdownMenu from "../globals/UiTools/DropdownMenu.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateRawMaterialQuantity',
  props: {
  technicalSheet: {
    type: Object as PropType<TechnicalSheetDto>,
    required: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const props = __props;

const technicalSheetRef: Ref<TechnicalSheetDto> = toRef(
  props,
  "technicalSheet"
);

let currentRawMaterialIndex = ref(-1);
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();
const formId = ref();
const allRawMaterials: Ref<
  Array<TechnicalSheetRawMaterial | RawMaterialQuantityForm>
> = ref(cloneDeep(get(technicalSheetRef, "value.rawMaterials", [])));

const currentRawMaterial = computed(() => {
  return cloneDeep(
    get(allRawMaterials, `value.${currentRawMaterialIndex.value}`)
  );
});

const emit = __emit;

async function handleAddRawMaterial() {
  currentRawMaterialIndex.value = allRawMaterials.value.length;
  await handleAddOrEditRawMaterial(currentRawMaterialIndex.value);
}

const excludeOptions = computed(() => {
  return allRawMaterials.value.map(({ rawMaterial }) => {
    return rawMaterial;
  });
});

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  allRawMaterials.value[currentRawMaterialIndex.value] = cloneDeep(values);
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = allRawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  rawMaterialFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  formId.value = uuidv4();
  modal.value.openModal();
  currentRawMaterialIndex.value = index;
}

function resetForms() {
  allRawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

function getOptions(index: number): Menu {
  const options = [
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.edit",
        needsTranslate: true,
      },
      id: 1,
      action: () => handleAddOrEditRawMaterial(index),
    },
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.delete",
        needsTranslate: true,
      },
      id: 2,
      action: () => allRawMaterials.value.splice(index, 1),
    },
  ];
  return {
    options,
  };
}

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (
      value,
      key,
      { rawMaterial }: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("label" in rawMaterial) {
        return rawMaterial.label;
      }
      return rawMaterial.description;
    },
  },
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.quantity;
      }
      return item.quantity;
    },
  },

  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.thickness;
      } else if ("thickness" in item.rawMaterial.item) {
        return item.rawMaterial.item.thickness;
      }
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.width;
      } else if ("width" in item.rawMaterial.item) {
        return item.rawMaterial.item.width;
      }
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.length;
      } else if ("length" in item.rawMaterial.item) {
        return item.rawMaterial.item.length;
      }
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (
      value,
      key,
      item: TechnicalSheetRawMaterial | RawMaterialQuantityForm
    ) => {
      if ("id" in item) {
        return item.rawMaterial.volume;
      } else if ("volume" in item.rawMaterial.item) {
        return item.rawMaterial.item.volume;
      }
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      let volume;
      if ("id" in item) {
        volume = item.rawMaterial.volume;
      } else {
        volume = item.rawMaterial.item.volume;
      }
      return volume * item.quantity;
    },
  },
  {
    key: "actions",
    sortable: false,
    label: t("users.table.actions"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

watch(
  allRawMaterials,
  () => {
    const rawMaterials = allRawMaterials.value.map(
      ({ rawMaterial, quantity }) => {
        return {
          id: rawMaterial.id,
          quantity: quantity,
        };
      }
    );
    emit("update:modelValue", rawMaterials);
  },
  { deep: true, immediate: true }
);

__expose({ resetForms });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
        _createElementVNode("hr")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)("technicalSheets.entity.rawMaterial")), 1)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
        _createElementVNode("hr")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn ml-1 btn-gear",
          type: "button",
          onClick: handleAddRawMaterial
        }, _toDisplayString(_ctx.$t("technicalSheets.form.rawMaterial.add")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(BTable), _normalizeProps(_guardReactiveProps({ items: allRawMaterials.value, fields: rawMaterialsFields, showEmpty: true } as any)), {
          "cell(actions)": _withCtx((row) => [
            _createVNode(DropdownMenu, {
              menuData: getOptions(row.index),
              name: `menu-${row.index}-${row.key}`
            }, null, 8, ["menuData", "name"])
          ]),
          empty: _withCtx(() => [
            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("technicalSheets.tables.rawMaterials.isEmpty")), 1)
          ]),
          _: 1
        }, 16)
      ])
    ]),
    _createVNode(GeneralModal, {
      ref_key: "modal",
      ref: modal,
      modalId: "translateModal",
      modalName: modalTitle.value,
      "data-bs-backdrop": "false",
      class: "p-4"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "rawMaterialForm",
          ref: rawMaterialForm,
          form: rawMaterialFormTemplateRef.value,
          "form-name": "translateForm",
          key: formId.value,
          initialValues: currentRawMaterial.value
        }, null, 8, ["form", "initialValues"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})