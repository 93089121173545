import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "dropdown-menu p-2",
  "aria-labelledby": "dropdownMenuButton"
}

import { Dropdown } from "bootstrap";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralDropdown',
  setup(__props, { expose: __expose }) {

const dropdown = ref();
const isOpen = ref(false);
let dropdownInstance;

function openDropdown() {
  dropdownInstance.show();
  isOpen.value = true;
}

function closeDropdown() {
  dropdownInstance.hide();
  isOpen.value = false;
}

function toggleDropdown() {
  if (isOpen.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

onMounted(() => {
  dropdownInstance = Dropdown.getOrCreateInstance(dropdown.value);
});

__expose({ openDropdown, closeDropdown, toggleDropdown });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown",
    ref_key: "dropdown",
    ref: dropdown
  }, [
    _createElementVNode("div", { onClick: toggleDropdown }, [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "body")
    ])
  ], 512))
}
}

})