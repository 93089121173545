import { useLanguageServices } from "@/composables/useLanguageServices";
import { get } from "lodash";

interface Metadata {
  language: {
    [key: string]: any;
  };
}

interface GenericObject {
  metadata?: Metadata;
  [key: string]: any;
}

export function translateFromKey(obj: GenericObject, key: string): string {
  const { lang } = useLanguageServices();
  return get(
    obj,
    `metadata.language.${key}.${lang.value}`,
    get(obj, `${key}`, false)
  );
}
