<template>
  <div class="container-fluid p-0 header">
    <nav class="navbar navbar-expand-lg p-0">
      <div class="container-fluid p-0">
        <div class="w-100">
          <div class="row">
            <div class="col d-flex justify-content-between px-4 py-2">
              <div class="d-flex justify-content-start align-items-center">
                <button
                  class="navbar-toggler mr-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <LogoPicture name="QUICK PALLET"></LogoPicture>
              </div>
              <div class="col d-flex justify-content-end align-items-center">
                <div class="d-flex">
                  <NotificationsDropdownMenu
                    class="pr-2"
                  ></NotificationsDropdownMenu>
                  <UserDropdownMenu
                    :userInfo="userDropDown.userInfo"
                    :links="userDropDown.links"
                  ></UserDropdownMenu>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col p-0 navigation">
              <div
                class="collapse navbar-collapse"
                id="navbarSupportedContent"
                ref="navbarRef"
              >
                <div
                  class="optionFullScreen w-100 h-100"
                  style="position: relative"
                >
                  <div
                    class="d-flex"
                    style="overflow-x: auto; overflow-y: visible"
                    ref="scrollableContainer"
                  >
                    <header-option
                      ref="headerOptions"
                      v-for="(header, index) of headers"
                      :key="header.name"
                      :header="header"
                      @click="() => closeAllDropdowns(index)"
                      @navigating="() => closeAllDropdowns(-1)"
                      :index-option="index"
                    ></header-option>
                  </div>
                </div>
                <div class="optionSmallScreen" id="accordionMenu">
                  <div
                    class="row accordion"
                    v-for="header of headers"
                    :key="header.name"
                  >
                    <div class="col">
                      <HeaderOptionSmall
                        :header="header"
                        :closeNavbar="closeNavbar"
                      ></HeaderOptionSmall>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { SidebarElements } from "@/shared/dashboard/sidebar/const/ElementsMenu";
import { DropDownElementSubmenu } from "@/shared/globals/tooltips/types/DropDownElementsSubmenu";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { Collapse } from "bootstrap";
import { onBeforeMount, ref, useTemplateRef, watch } from "vue";
import { useRouter } from "vue-router";
import HeaderOption from "./HeaderOption.vue";
import HeaderOptionSmall from "./HeaderOptionSmall.vue";
import LogoPicture from "./LogoPicture.vue";
import NotificationsDropdownMenu from "./NotificationsDropdownMenu.vue";
import UserDropdownMenu from "./UserDropdownMenu.vue";

const {
  auth: {
    value: { user },
  },
  logout,
} = useAuthServices();
const router = useRouter();
const navbarRef = useTemplateRef<Element>("navbarRef" as never);
const headers = ref([]);
const headerOptions = ref([]);

onBeforeMount(() => {
  watch(
    user,
    () => {
      headers.value = SidebarElements(user);
    },
    { deep: true, immediate: true }
  );
});

const closeNavbar = () => {
  const navbarTemp = new Collapse(navbarRef.value);
  navbarTemp.hide();
};

const callBack = () => {
  router.push({ name: "Main" });
};

const closeAllDropdowns = (index: number) => {
  headerOptions.value.forEach((header, index2) => {
    if (header.isOpenDropdown && index2 !== index) {
      header.closeDropdown();
    }
  });
};

const userDropDown: {
  userInfo: {
    fullName: string;
    profile: string;
    image?: string;
    width?: string;
  };
  links: DropDownElementSubmenu[];
} = {
  userInfo: {
    fullName: user.name,
    profile: translateFromKey(user.profile, "name"),
    image: null,
    width: "2.5rem",
  },
  links: [
    {
      icon: { icon: "bi bi-person" },
      name: "Profile",
      callBack,
    },
    {
      icon: { icon: "bi bi-inbox" },
      name: "Inbox",
      callBack,
    },
    {
      icon: { icon: "bi bi-lock" },
      name: "Lock Screen",
      callBack,
    },
    {
      icon: { icon: "bi bi-box-arrow-right" },
      name: "Log Out",
      callBack: logout,
    },
  ],
};
</script>

<style lang="scss" scoped>
.navigation {
  background-color: #191e3b;
}

.header {
  background-color: #f1f2f3;
}

@media screen and (max-width: 991px) {
  .navigation div ul {
    flex-direction: column;
  }

  .optionFullScreen {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .optionSmallScreen {
    display: none;
  }
}
</style>
