import { CreateSupplierDto } from "@/store/suppliers/dtos/output/CreateSupplier.dto";
import { UpdateSupplierDto } from "@/store/suppliers/dtos/output/UpdateSupplier.dto";
import { SupplierForm } from "@/store/suppliers/interfaces/SupplierForm.interface";
import { get } from "lodash";

export const SupplierFormToSupplierDto = (
  supplier: SupplierForm
): CreateSupplierDto | UpdateSupplierDto => {
  const id = get(supplier, "supplier.id", false);
  const name = get(supplier, "supplier.name");
  const code = get(supplier, "supplier.code");
  const supplierType = get(supplier, "supplier.supplierType");
  const businessType = get(supplier, "supplier.businessType");
  const state = get(supplier, "supplier.state");
  const city = get(supplier, "supplier.city");
  const email = get(supplier, "supplier.email");
  const phoneNumber = get(supplier, "supplier.phoneNumber");
  const customFields = get(supplier, "supplier.customFields", {});
  const division = { id: get(supplier, "supplier.division.id") };
  return {
    ...(id === false ? {} : { id }),
    name,
    code,
    supplierType,
    businessType,
    state,
    city,
    email,
    phoneNumber,
    customFields,
    division,
  };
};
