import { CreateStoreDto } from "@/store/stores/dtos/output/CreateStore.dto";
import { UpdateStoreDto } from "@/store/stores/dtos/output/UpdateStore.dto";
import { StoreFormInterface } from "@/store/stores/interfaces/StoreForm.interface";
import { get } from "lodash";

export const StoreFormToStoreDto = (
  store: StoreFormInterface
): CreateStoreDto | UpdateStoreDto => {
  const id = get(store, "store.id", false);
  const name = get(store, "store.name");
  const division = { id: Number(get(store, "store.division.id")) };
  const customFields = get(store, "store.customFields");
  return {
    ...(id === false ? {} : { id }),

    name,
    division,
    customFields,
  };
};
