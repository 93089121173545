<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues) + actionTypeRef"
          form-name="inventoryForm"
          :initialValues="initialValuesRef"
          :is-view="actionType === 'view'"
        >
          <template #section-1-field-0-subField-3>
            <SectionName :name="customFieldLabel" />
          </template>
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProductsInventoryServices } from "@/composables/useProductsInventoryServices";
import { useRawMaterialsInventoryServices } from "@/composables/useRawMaterialsInventoryServices";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { clearCustomFields } from "@/store/customFields/helpers/FilterCustomField.helper";
import { ProductInventoryDto } from "@/store/inventories/dtos/input/ProductInventory.dto";
import { RawMaterialInventoryDto } from "@/store/inventories/dtos/input/RawMaterialInventory.dto";
import { SimpleInventoryActionType } from "@/views/inventories/types/SimpleInventoryAction.type";
import { get, merge } from "lodash";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import SectionName from "../globals/forms/SectionName.vue";
import { InventoryTransactionFormTemplate } from "./templates/forms/InventoryTransactionForm.template";

const { customFields } = useCustomFieldsServices();
const {
  dispatchProductInventory,
  insertProductInventory,
  modifyProductInventory,
  checkProductInventory,
} = useProductsInventoryServices();
const {
  dispatchRawMaterialInventory,
  insertRawMaterialInventory,
  modifyRawMaterialInventory,
  checkRawMaterialInventory,
} = useRawMaterialsInventoryServices();
const route = useRoute();

const props = defineProps({
  initialValues: {
    type: Object as PropType<ProductInventoryDto | RawMaterialInventoryDto>,
    required: false,
  },
  actionType: {
    type: Object as PropType<SimpleInventoryActionType>,
    required: true,
  },
});
const moduleName: string = route.meta.moduleName as string;

const isProductModule = computed(() => {
  return moduleName !== "raw_materials_inventories";
});
const customFieldLabel: LabelType = {
  value: "customFields.title",
  needsTranslate: true,
};

const initialValuesRef: Ref<ProductInventoryDto | RawMaterialInventoryDto> =
  ref();
const form = ref();
const formTemplateRef = ref();
const actionTypeRef: Ref<SimpleInventoryActionType> = toRef(
  props,
  "actionType"
);
const existLotCode = ref(false);

const emit = defineEmits(["handleSubmit"]);

function callBackCreateOrUpdate(
  inventories: ProductInventoryDto[] | RawMaterialInventoryDto[]
) {
  emit("handleSubmit", { inventories });
}

async function handleSubmit(typeTransaction?: SimpleInventoryActionType) {
  const { valuesFormatted: inventory } = form.value;
  if (["dispatch", "insert"].includes(typeTransaction)) {
    const { id, ...inventoryProps } = inventory;
    if (isProductModule.value) {
      if (typeTransaction === "dispatch") {
        dispatchProductInventory(inventoryProps, callBackCreateOrUpdate);
      } else {
        insertProductInventory(inventoryProps, callBackCreateOrUpdate);
      }
    } else {
      if (typeTransaction === "dispatch") {
        dispatchRawMaterialInventory(inventoryProps, callBackCreateOrUpdate);
      } else {
        insertRawMaterialInventory(inventoryProps, callBackCreateOrUpdate);
      }
    }
  } else {
    if (isProductModule.value) {
      modifyProductInventory(inventory, callBackCreateOrUpdate);
    } else {
      modifyRawMaterialInventory(inventory, callBackCreateOrUpdate);
    }
  }
}

function resetForms() {
  form.value?.resetForm();
}

async function onBlurLotCodeInput(typeTransaction: SimpleInventoryActionType) {
  if (["insert"].includes(typeTransaction)) {
    const values = get(form, "value.values");
    const storeId = get(values, "inventory.store.id");
    const productId = get(values, "inventory.elements.element0.id.id");
    const lotCode = get(values, "inventory.elements.element0.lotCode");
    if (storeId && productId && lotCode) {
      const payload = {
        store: { id: storeId },
        element: { id: productId, lotCode },
      };
      const result = await (async () => {
        return isProductModule.value
          ? checkProductInventory(payload)
          : checkRawMaterialInventory(payload);
      })();
      const { exists, expirationDate, metadata, customFields } = result;
      existLotCode.value = exists;
      if (exists) {
        const newValues = merge({}, values, {
          inventory: {
            elements: {
              element0: {
                expirationDate: DateHelper.of().format(
                  expirationDate,
                  "yyyy-MM-dd"
                ),
                metadata,
                customFields,
              },
            },
          },
        });
        form.value?.setValues(newValues);
      } else {
        const values = get(form, "value.values");
        const store = get(values, "inventory.store");
        const id = get(values, "inventory.elements.element0.id");
        const lotCode = get(values, "inventory.elements.element0.lotCode");
        setValuesNotRecursive({
          inventory: {
            store,
            elements: { element0: { id, lotCode } },
          },
        });
      }
    }
  }
}

const formValues = computed(() => {
  return form.value?.values;
});

function disableFields(fieldsName?: string[]) {
  get(formTemplateRef, "value.sections.0.fields.0.fields", []).map((field) => {
    if (!fieldsName) {
      field.disabled = true;
    } else if (fieldsName.includes(field.inputName)) {
      field.disabled = true;
    }
  });
}

function enableFields(fieldsName?: string[]) {
  get(formTemplateRef, "value.sections.0.fields.0.fields", []).map((field) => {
    if (!fieldsName) {
      field.disabled = false;
    } else if (fieldsName.includes(field.inputName)) {
      field.disabled = false;
    }
  });
}

function setValuesNotRecursive(values) {
  const oldValues = get(form, "value.values");
  if (JSON.stringify(values) !== JSON.stringify(oldValues)) {
    form?.value?.setValues(values);
  }
}

watch(
  [formValues, existLotCode],
  () => {
    if (["insert"].includes(actionTypeRef.value)) {
      const values = get(form, "value.values");
      const store = get(values, "inventory.store");
      const id = get(values, "inventory.elements.element0.id");
      const lotCode = get(values, "inventory.elements.element0.lotCode");
      disableFields();
      enableFields(["inventoryStore"]);
      if (get(formValues, "value.inventory.store", false)) {
        enableFields(["productInventory", "rawMaterialInventory"]);
        if (get(formValues, "value.inventory.elements.element0.id", false)) {
          enableFields(["inventoryLotCode"]);
          if (
            get(formValues, "value.inventory.elements.element0.lotCode", false)
          ) {
            enableFields(["inventoryQuantity"]);
            if (
              !existLotCode.value &&
              get(
                formValues,
                "value.inventory.elements.element0.quantity",
                false
              )
            ) {
              enableFields();
            }
            if (
              !existLotCode.value &&
              !get(
                formValues,
                "value.inventory.elements.element0.quantity",
                false
              )
            ) {
              setValuesNotRecursive({
                inventory: {
                  store,
                  elements: { element0: { id, lotCode } },
                },
              });
            }
          } else {
            setValuesNotRecursive({
              inventory: {
                store,
                elements: { element0: { id } },
              },
            });
          }
        } else {
          setValuesNotRecursive({
            inventory: {
              store,
            },
          });
        }
      } else {
        setValuesNotRecursive({
          inventory: {},
        });
      }
    }
  },
  { deep: true }
);

watch(
  [initialValuesRef, actionTypeRef],
  async () => {
    formTemplateRef.value = InventoryTransactionFormTemplate(
      customFields.value,
      handleSubmit,
      actionTypeRef.value,
      isProductModule.value,
      onBlurLotCodeInput
    );
    if (["insert"].includes(actionTypeRef.value)) {
      enableFields(["inventoryStore"]);
    } else if (["dispatch"].includes(actionTypeRef.value)) {
      enableFields(["inventoryQuantity"]);
    } else if (["modify"].includes(actionTypeRef.value)) {
      enableFields();
      disableFields([
        "inventoryStore",
        "productInventory",
        "rawMaterialInventory",
        "inventoryLotCode",
      ]);
    }
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  initialValuesRef.value = clearCustomFields(
    props.initialValues,
    customFields.value
  );
});

defineExpose({
  resetForms,
});
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
