<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("profiles.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("profiles.form.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      action === 'create'
        ? $t('profiles.form.createTitle')
        : action === 'edit'
        ? $t('profiles.form.editTitle')
        : $t('profiles.form.viewTitle')
    "
  >
    <template v-slot:headerComplement>
      <ButtonField
        v-if="action === 'view'"
        :button-field="editButton(() => optionEdit(profileTemp, lastRowIndex))"
      ></ButtonField></template
    ><template v-slot:content>
      <CreateOrUpdateProfile
        ref="form"
        :initial-values="profileTemp"
        @handle-submit="handleProfileSubmit"
        :key="currentFormId"
        :action="action as SimpleProfileActionType"
      ></CreateOrUpdateProfile>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal :title="confirmModalDeleteProfile" ref="confirmationModalRef">
    <DeleteProfile
      v-if="profileTemp?.id"
      :key="profileTemp.id"
      :handle-submit="handleConfirm"
      :current-row="profileTemp"
    ></DeleteProfile>
  </ConfirmModal>
  <GeneralTable
    v-if="profilesHeaderTable"
    dataSource="/profiles/"
    :filters="tableFilters"
    :headers="profilesHeaderTable"
    name="usersTable"
    ref="profileTable"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import ButtonField from "@/components/globals/buttons/ButtonField.vue";
import { editButton } from "@/components/globals/buttons/templates/EditButton.template";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateProfile from "@/components/profiles/CreateOrUpdateProfile.vue";
import DeleteProfile from "@/components/profiles/DeleteProfile.vue";
import { ProfileHeader } from "@/components/profiles/templates/headers/Profile.headers";
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { ProfileDto } from "@/store/profiles/dtos/input/Profile.dto";
import { UserDto } from "@/store/users/dto/input/user.dto";
import { v4 as uuidv4 } from "uuid";
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const { permissions, getPermissions } = usePermissionsServices();
const { deleteProfile } = useProfilesServices();
const { t } = useI18n();

export type SimpleProfileActionType = "create" | "edit" | "view";

type ProfileActionType = SimpleProfileActionType | "delete";

const form = ref();
const profileTable = ref();
const offCanvasRef = ref();
const profilesHeaderTable = ref();
const profileTemp: Ref<ProfileDto | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<ProfileActionType> = ref();
const confirmModalDeleteProfile: Ref<string> = ref("");
const currentFormId = ref();

const tableFilters = ref([]);

function handleProfileSubmit(data) {
  const { profile, isCreating } = data;
  if (isCreating) {
    profileTable.value.currentData = [
      profile,
      ...profileTable.value.currentData,
    ];
  } else {
    profileTable.value.currentData[lastRowIndex.value] = {
      ...profileTable.value.currentData[lastRowIndex.value],
      ...profile,
    };
  }
  offCanvasRef.value.closeOffCanvas();
}

function openOffcanvasForm(newAction: ProfileActionType) {
  profileTemp.value = null;
  action.value = newAction;
  form.value?.resetForms?.();
  offCanvasRef.value.openOffCanvas();
}

function optionEdit(profile: ProfileDto, index: number) {
  openOffcanvasForm("edit");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

function optionDelete(profile: ProfileDto, index: number) {
  action.value = "delete";
  confirmModalDeleteProfile.value = t("global.warnings.delete");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

function optionView(profile: ProfileDto, index: number) {
  openOffcanvasForm("view");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  currentFormId.value = uuidv4();
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = profileTemp.value;

    await deleteProfile({ oldId, newId }, () => {
      profileTable.value.currentData = profileTable.value.currentData.filter(
        (profile: UserDto, index) => {
          return index !== lastRowIndex.value;
        }
      );
    });
  }
  confirmationModalRef.value?.closeConfirmationModal();
}

onMounted(async () => {
  await getPermissions();
  profilesHeaderTable.value = ProfileHeader(
    optionEdit,
    optionDelete,
    optionView,
    permissions.value
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
