import { useYupRules } from "@/composables/useYupRules";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { TooltipPosition } from "@/shared/globals/inputs/types/TooltipPosition.type";
import {
  arrayYup,
  dateYup,
  isNotRequired,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { getLang } from "@/shared/locales/services/i18n.services";
import { set } from "lodash";
import { CustomFieldDto } from "../dtos/input/CustomField.dto";

export function customFieldsToField(
  customFields: CustomFieldDto[],
  pathPrefix: string,
  inputNamePrefix: string,
  tooltipPosition?: TooltipPosition
): Field[] {
  const yupRules = useYupRules();
  const lang = getLang();

  const customFieldsArray: Field[] = customFields.map((customField): Field => {
    const translations = customField?.metadata?.language;

    const complement: Partial<Field> = {};

    if (["select", "multiple-select"].includes(customField.type)) {
      const options = customField.metadata.options;
      set(complement, "selectOptions.idKey", "id");
      set(complement, "selectOptions.labelKey", ["label"]);
      set(
        complement,
        "selectOptions.isMultiple",
        ["multiple-select"].includes(customField.type)
      );
      complement.selectOptions.initialOptions = Object.keys(options).map(
        (optionKey) => {
          const optionsTranslated = translations?.options
            ? translations?.options[lang]
            : null;
          const translation = optionsTranslated
            ? optionsTranslated[optionKey]
            : null;
          return {
            id: optionKey,
            label: translation ?? options[optionKey],
          };
        }
      );
    }

    if (translations?.name && translations?.name[lang]) {
      complement["label"] = {
        value: translations.name[lang],
        needsTranslate: false,
      };
    }

    return {
      path: `${pathPrefix}.customFields.${customField.id}`,
      inputName: `${inputNamePrefix}.${customField.id}`,
      label: {
        value: customField.name,
        needsTranslate: false,
      },
      type: customField.type,
      metadata: {
        tooltipPosition: tooltipPosition ?? "left",
      },
      methodsYup: () => {
        let validations = [];

        if (["text", "select", "number"].includes(customField.type)) {
          validations = [stringYup];
        }
        if (customField.type === "multiple-select") {
          validations = [arrayYup];
        }
        if (customField.type === "date") {
          validations = [dateYup];
        }
        if (customField?.metadata?.fieldSettings?.validations) {
          (
            customField.metadata.fieldSettings.validations as Array<object>
          ).forEach((validation) => {
            const validationRule = validation as {
              value: string;
            };
            if (
              customField?.metadata?.fieldSettings?.params?.[
                validationRule.value
              ]
            ) {
              validations = [
                ...validations,
                yupRules[validationRule.value](
                  Number(
                    customField?.metadata?.fieldSettings?.params?.[
                      validationRule.value
                    ]
                  )
                ),
              ];
            } else {
              validations = [...validations, yupRules[validationRule.value]()];
            }
          });
        }
        if (
          customField.required &&
          ["multiple-select", "text", "select", "number", "date"].includes(
            customField.type
          )
        ) {
          validations = [...validations, requiredYup()];
        } else {
          validations = [...validations, isNotRequired()];
        }
        return validations;
      },
      ...complement,
    };
  });
  return customFieldsArray;
}
