import { MakeImportDto } from "@/store/import-export/dtos/MakeImport.dto";
import { ImportForm } from "@/store/import-export/interfaces/ImportForm.interface";
import { get } from "lodash";

export const ImportFormToImportDto = (
  importValues: ImportForm
): MakeImportDto => {
  const files = [get(importValues, "files.0.file")];
  return {
    files,
  };
};
