import { CreateStoreDto } from "@/store/stores/dtos/output/CreateStore.dto";
import { DeleteStoreDto } from "@/store/stores/dtos/output/DeleteStore.dto";
import { UpdateStoreDto } from "@/store/stores/dtos/output/UpdateStore.dto";
import {
  CreateStore,
  DeleteStore,
  UpdateStore,
} from "@/store/stores/services/Store.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";
import { StoreDto } from "@/store/stores/dtos/input/Store.dto";

export function useStoresServices() {
  const { t } = useI18n();

  const createStore = async (
    payload: CreateStoreDto,
    callBackCreate: (store: StoreDto) => void
  ): Promise<void> => {
    await CreateStore(payload)
      .then(async (store: StoreDto) => {
        callBackCreate(store);
        useToast().successToast(t("stores.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const updateStore = async (
    payload: UpdateStoreDto,
    callBackUpdate: (store: StoreDto) => void
  ): Promise<void> => {
    await UpdateStore(payload)
      .then(async (store: StoreDto) => {
        callBackUpdate(store);
        useToast().successToast(t("stores.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const deleteStore = async (
    payload: DeleteStoreDto,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteStore(payload)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("stores.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    createStore,
    updateStore,
    deleteStore,
  };
}
