import { CreateCustomFieldDto } from "@/store/customFields/dtos/output/CreateCustomField.dto";
import { UpdateCustomFieldDto } from "@/store/customFields/dtos/output/UpdateCustomField.dto";
import { CustomFieldForm } from "@/store/customFields/interfaces/CustomFieldForm.interface";
import { get } from "lodash";

export const CustomFieldFormToCustomFieldDto = (
  customField: CustomFieldForm
): CreateCustomFieldDto | UpdateCustomFieldDto => {
  const id = get(customField, "customField.id", false);
  const name = get(customField, "customField.name");
  const type = get(customField, "customField.type.id");
  const required = get(customField, "customField.required", false);
  const options = get(customField, "customField.metadata.options");
  return {
    ...(id ? { id } : {}),
    name,
    type,
    required,
    metadata: { options },
  };
};
