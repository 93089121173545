import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useImportExportServices } from "@/composables/useImportExportServices";
import {
  generateAndDownloadExcel,
  getHeaderAndRows,
  loadExcel,
} from "@/shared/globals/excel/helpers/excel.helper";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { isField } from "@/shared/globals/helpers/Template.helper";
import { cloneDeep } from "lodash";
import {
  computed,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import TablePreview from "./TablePreview.vue";
import { UploadImportTemplate } from "./templates/forms/UploadImport.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralImport',
  props: {
  template: { type: Object as PropType<Form>, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { emit: __emit }) {

const { importFile } = useImportExportServices();

const props = __props;

const templateRef = toRef(props, "template");
const route = useRoute();
const moduleName: string = route.meta.moduleName as string;
const formTemplateRef = ref();
const form = ref();
const items = ref([]);
const formattedTemplateRef = computed(() => {
  const formattedTemplate = cloneDeep(templateRef.value);
  formattedTemplate?.sections?.forEach((sections) => {
    sections.fields.forEach((field) => {
      if (isField(field)) {
        const [_, ...path] = field.path.split(".");
        field.path = path.join(".");
      }
    });
  });
  return formattedTemplate;
});

const emits = __emit;

async function downloadTemplate() {
  await generateAndDownloadExcel(formattedTemplateRef.value, moduleName);
}

async function handleSubmit() {
  const { valuesFormatted } = form.value;
  await importFile(valuesFormatted);
  emits("handleSubmit");
}

const excelFile: Ref<File> = computed(() => {
  return form.value?.values?.files?.[0]?.file;
});

watch(excelFile, async () => {
  if (excelFile.value) {
    const workbook = await loadExcel(excelFile.value);
    items.value = getHeaderAndRows(workbook);
  } else {
    items.value = [];
  }
});

onMounted(() => {
  formTemplateRef.value = UploadImportTemplate(handleSubmit);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-primary",
      onClick: downloadTemplate
    }, _toDisplayString(_ctx.$t("importAndExport.downloadTemplate")), 1),
    _createVNode(GeneralForm, {
      ref_key: "form",
      ref: form,
      form: formTemplateRef.value,
      formName: "UploadExcel",
      "is-view": false
    }, {
      "file-preview-0-0-0": _withCtx(() => [
        _createVNode(TablePreview, {
          items: items.value,
          template: formattedTemplateRef.value
        }, null, 8, ["items", "template"])
      ]),
      _: 1
    }, 8, ["form"])
  ], 64))
}
}

})