import axiosInstance from "@/middlewares/Routing.middleware";
import { RawMaterialDto } from "../dtos/input/RawMaterial.dto";
import { CreateRawMaterialDto } from "../dtos/output/CreateRawMaterial.dto";
import { UpdateRawMaterialDto } from "../dtos/output/UpdateRawMaterial.dto";

export async function CreateRawMaterial(
  payload: CreateRawMaterialDto
): Promise<RawMaterialDto> {
  return (await axiosInstance.post(`raw-materials`, payload)).data;
}

export async function UpdateRawMaterial(
  payload: UpdateRawMaterialDto
): Promise<RawMaterialDto> {
  const { id, ...rawMaterialProps } = payload;
  return (await axiosInstance.put(`raw-materials/${id}`, rawMaterialProps))
    .data;
}

export async function DeleteRawMaterial(id: number): Promise<void> {
  await axiosInstance.delete(`raw-materials/${id}`);
}
