<template>
  <div class="row" ref="elementToPrint">
    <div class="col-12">
      <p class="text-start">
        <b>{{ $t("technicalSheets.entity.name") }}</b>
        <span
          >{{ technicalSheet?.product?.name }}
          {{ technicalSheet?.product?.measure?.name }}</span
        >
      </p>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b> {{ $t("technicalSheets.entity.images") }}: </b>
      </p>
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <img
          v-for="(image, index) of technicalSheet?.product?.descriptiveImages ??
          []"
          :key="`descriptiveImages${index}`"
          style="object-fit: cover; height: 200px"
          :src="image"
          crossOrigin=""
        />
      </div>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>{{ $t("technicalSheets.entity.materialType") }} </b
        >{{ $t(materialType) }}
      </p>
    </div>
    <div class="col-12" v-if="technicalSheet?.rawMaterials">
      <p class="text-start">
        <b> {{ $t("technicalSheets.entity.rawMaterial") }}:</b>
      </p>

      <BTable
        :items="technicalSheet?.rawMaterials"
        :fields="rawMaterialsFields"
      >
      </BTable>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>
          {{ $t("technicalSheets.entity.manufactureSpecification.title") }}:
        </b>
      </p>
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <img
          v-for="(image, index) of technicalSheet?.product
            ?.manufactureSpecification.images ?? []"
          :key="`descriptiveImages${index}`"
          style="object-fit: cover; width: 100%"
          :src="image"
          crossOrigin=""
        />
      </div>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>
          {{ $t("technicalSheets.entity.manufactureSpecification.subtitle") }}:
        </b>
      </p>
      <div>
        <ul class="text-start">
          <li
            v-for="(detail, index) of technicalSheet?.product
              ?.manufactureSpecification?.details ?? []"
            :key="`detail.${index}`"
          >
            {{ detail }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b> {{ $t("technicalSheets.entity.instructions.title") }} </b>
      </p>
      <div class="d-flex flex-row justify-content-center flex-wrap mb-3">
        <img
          v-for="(image, index) of technicalSheet?.product?.instructions
            .images ?? []"
          :key="`instructions${index}`"
          style="object-fit: cover; height: 200px"
          :src="image"
          crossOrigin=""
        />
      </div>
      <b>{{ technicalSheet?.product?.instructions.description }}</b>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b> {{ $t("technicalSheets.entity.qualityCriteria.title") }} </b>
      </p>
      <BTable
        :items="technicalSheet?.product?.qualityCriteria?.qualityCriteria ?? []"
        :fields="criteriasFields"
      >
      </BTable>
      <p>
        <b>{{ technicalSheet?.product?.instructions.description }}</b>
      </p>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>{{ $t("technicalSheets.entity.customfields") }}</b>
      </p>
      <BTable
        :items="technicalSheetCustomFields ?? []"
        :fields="technicalSheetCustomFieldsFields"
      >
      </BTable>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>{{ $t("workplacesFlows.form.operativeSequency") }}</b>
      </p>
      <BTable :items="workplaceFlow?.steps ?? []" :fields="workplaceFlowFields">
      </BTable>
    </div>
    <div class="col-12">
      <p class="text-start">
        <b>{{ $t("workplacesFlows.entity.customfields") }}</b>
      </p>
      <BTable
        :items="workplaceFlowsCustomFields ?? []"
        :fields="workplaceFlowsCustomFieldsFields"
      >
      </BTable>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ButtonField :button-field="printButton" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { convertElementToPDF } from "@/shared/globals/helpers/ComponentConverter.helper";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { get } from "lodash";
import { computed, defineProps, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { createPrintButton } from "../globals/buttons/templates/PrintButton.template";

const props = defineProps({
  productId: { type: Number, required: true },
});

const elementToPrint: Ref<HTMLElement> = ref();

const printButton = createPrintButton(() => {
  convertElementToPDF(elementToPrint.value);
});

const { t } = useI18n();

const { technicalSheet, getTechnicalSheet } = useTechnicalSheetsServices(
  props.productId
);
const { workplaceFlow, getWorkplacesFlow } = useWorkplacesFlowsServices(
  props.productId
);
const {
  customFields: technicalSheetCustomFields,
  getModuleCustomFields: getTechnicalSheetCustomFields,
} = useCustomFieldsServices("technical_sheets");
const {
  customFields: workplaceFlowsCustomFields,
  getModuleCustomFields: getWorkplaceFlowsCustomFields,
} = useCustomFieldsServices("workplace_flows");

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (value, key, item) => {
      return item.quantity;
    },
  },
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (value, key, item) => {
      return item.rawMaterial.description;
    },
  },
  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (value, key, item) => {
      return item.rawMaterial.thickness;
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (value, key, item) => {
      return item.rawMaterial.width;
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (value, key, item) => {
      return item.rawMaterial.length;
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.volume;
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.volume * item.quantity;
    },
  },
];

const criteriasFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "order",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.criteriaOrder"),
    formatter: (value, key, item) => {
      return item.order;
    },
  },
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.description"),
    formatter: (value, key, item) => {
      return item.description;
    },
  },
  {
    key: "isValid",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.isValid"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

const technicalSheetCustomFieldsFields: Exclude<TableFieldRaw<any>, string>[] =
  [
    {
      key: "fields",
      sortable: false,
      label: t("customFields.singularTitle"),
      formatter: (value, key, item) => {
        return translateFromKey(item, "name");
      },
    },
    {
      key: "values",
      sortable: false,
      label: t("customFields.value"),
      formatter: (value, key, item) => {
        const customFieldValue = get(
          technicalSheet.value?.product?.technicalSheetCustomFields,
          item.id,
          ""
        );
        if (Array.isArray(customFieldValue)) {
          return customFieldValue
            .map((value) => get(value, "label", ""))
            .join(", ");
        } else if (typeof customFieldValue === "object") {
          return get(customFieldValue, "label", "");
        }
        return customFieldValue;
      },
    },
  ];

const workplaceFlowFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "step",
    sortable: false,
    label: t("workplacesFlows.entity.step"),
    formatter: (value, key, item) => {
      return item.step;
    },
  },
  {
    key: "workplace",
    sortable: false,
    label: t("workplacesFlows.entity.workplace"),
    formatter: (value, key, item) => {
      return translateFromKey(item.workplace, "name");
    },
  },
  {
    key: "duration",
    sortable: false,
    label: t("workplacesFlows.entity.workTime"),
    formatter: (value, key, item) => {
      return item.workTime;
    },
  },
];

const workplaceFlowsCustomFieldsFields: Exclude<TableFieldRaw<any>, string>[] =
  [
    {
      key: "fields",
      sortable: false,
      label: t("customFields.singularTitle"),
      formatter: (value, key, item) => {
        return translateFromKey(item, "name");
      },
    },
    {
      key: "values",
      sortable: false,
      label: t("customFields.value"),
      formatter: (value, key, item) => {
        const customFieldValue = get(
          technicalSheet.value?.product?.workplaceFlowCustomFields,
          item.id,
          ""
        );
        if (Array.isArray(customFieldValue)) {
          return customFieldValue
            .map((value) => get(value, "label", ""))
            .join(", ");
        } else if (typeof customFieldValue === "object") {
          return get(customFieldValue, "label", "");
        }
        return customFieldValue;
      },
    },
  ];

const materialType = computed(() => {
  return `technicalSheets.type.${technicalSheet.value?.product?.materialType}`;
});

onMounted(async () => {
  await Promise.all([
    getTechnicalSheet(),
    getWorkplacesFlow(),
    getTechnicalSheetCustomFields(),
    getWorkplaceFlowsCustomFields(),
  ]);
});
</script>

<style lang="scss"></style>
